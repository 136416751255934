import React, { useContext, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout';
import InputWrapper from '@components/obsolete/Form/InputWrapper/InputWrapper';
import Label from '@components/obsolete/Form/Label/Label';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout';
import { Button } from 'primereact/button';
import { AddressBookTemplateOutDTO, CargoTemplateWithoutIdDTO, EditDataLocationAndOrderOutDTO, OpeningHourDefinitionsDTO, WeightModeEnum } from '@api/logsteo-api.v2';
import { OrderIdSelector } from '@components/obsolete/common/OrderIdSelector';
import { useImmer } from 'use-immer';
import { isNullOrUndefined } from '@utils/utils';
import * as yup from 'yup';
import useValidation from '@hooks/validation-hook/useValidation';
import ValidationDiv, { findValidationMessage } from '@utils/validation';
import { ApiContext } from '@api/api';
import { WritableDraft } from 'immer/dist/types/types-external';
import { useTranslation } from 'react-i18next';
import LocationAutocomplete from '@components/obsolete/common/LocationAutocomplete.tsx';
import InputCountry from '@components/obsolete/Form/InputCountry/InputCountry.tsx';
import OpeningHourDefinitionForm from '@components/obsolete/OpeningHoursDefinition/OpeningHourDefinitionForm.tsx';
import { SkuSelector } from '@components/obsolete/Form/SkuSelector';
import { QuantitySelector } from '@components/obsolete/Form/QuantitySelector';
import { DimensionsSelector } from '@components/obsolete/Form/DimensionsSelector';
import { WeightSelector } from '@components/obsolete/Form/WeightSelector';
import { StackableWrapper } from '@components/obsolete/Stackable/StackableWrapper.tsx';
import { Stackable } from '@components/obsolete/Stackable/Stackable.tsx';
import { NewNote } from '@components/obsolete/NewNote/NewNote.tsx';

interface ComponentProps {
  visible: boolean;
  onHide: () => void;
  onComplete: (data: EditDataLocationAndOrderOutDTO) => void;
  templateExpeditionId: string;
  locationId: string;
}

export interface FromTo {
  sinceTimeUtc: string;
  tillTimeUtc: string;
}

export interface IntervalDefinitionView {
  dayOfWeek: string;
  intervals: FromTo[];
}

const EditRouteSidebar: React.FC<ComponentProps> = ({ visible, onHide, onComplete, templateExpeditionId, locationId }) => {
  const { t } = useTranslation('common');
  const [state, setState] = useImmer<EditDataLocationAndOrderOutDTO>(undefined);

  const { cuGetEditTemplateLocationAndOrder } = useContext(ApiContext);

  useEffect(() => {
    if (visible)
      cuGetEditTemplateLocationAndOrder(templateExpeditionId, locationId, data => {
        clear();
        setState(draft => {
          return data;
        });
      });
  }, [visible]);

  const changeOrderItemProperty = (orderIndex: number, orderItemIndex: number, propertyName: string, value: any) => {
    setState(draft => {
      // @ts-ignore
      draft.orders[orderIndex].items[orderItemIndex][propertyName] = value;
    });
  };

  const deleteLoadingUnit = (orderIndex: number, orderItemIndex: number) => {
    setState(draft => {
      draft.orders[orderIndex].items = draft.orders[orderIndex].items.filter((orderItem, index) => index !== orderItemIndex);
    });
  };

  const addLoadingUnit = (orderIndex: number) => {
    setState(draft => {
      draft.orders[orderIndex].items.push({
        quantityUnit: null,
        cargoTemplate: null,
        height: null,
        length: null,
        weight: null,
        width: null,
        amount: null,
        weightMode: null,
        name: null,
        stackable: null,
      });
    });
  };

  /*const createView = (timeslots: IntervalDefinitionOutDTO[]): IntervalDefinitionView[] => {
    const allDays = daysInWeekSorted.map((dayOfWeek) => {
      return {
        dayOfWeek,
        enabled: false,
        intervals: [],
      } as IntervalDefinitionView;
    });
    const mapped = timeslots.reduce((array, value) => {
      const day4Update = array.find((a) => a.dayOfWeek === value.dayOfWeek);
      day4Update.intervals = [...day4Update.intervals, { sinceTimeUtc: value.since, tillTimeUtc: value.till }];
      return array;
    }, allDays);

    return mapped;
  };*/

  /*const addInterval = (dayOfWeek: string) => {
    setState((draft) => {
      draft.timeslots.push({ dayOfWeek: DayOfWeekEnum[dayOfWeek], since: null, till: null });
    });
  };

  const toggleEnable = (dayOfWeek: string) => {
    setState((draft) => {
      if (draft.timeslots.filter((t) => t.dayOfWeek === dayOfWeek).length > 0)
        draft.timeslots = draft.timeslots.filter((t) => t.dayOfWeek !== dayOfWeek);
      else draft.timeslots.push({ dayOfWeek: DayOfWeekEnum[dayOfWeek], since: null, till: null });
    });
  };

  const changeTill = (value: string, dayOfWeek: string, index: number) => {
    setState((draft) => {
      draft.timeslots.filter((t) => t.dayOfWeek === dayOfWeek)[index].till = value;
    });
  };

  const changeSince = (value: string, dayOfWeek: string, index: number) => {
    setState((draft) => {
      draft.timeslots.filter((t) => t.dayOfWeek === dayOfWeek)[index].since = value;
    });
  };*/

  const applyAddressBook = (partnerLocation: AddressBookTemplateOutDTO) => {
    setState(draft => {
      draft.country = partnerLocation.addressTemplate.country;
      draft.city = partnerLocation.addressTemplate.city;
      draft.streetNr = partnerLocation.addressTemplate.streetNr;
      draft.zipCode = partnerLocation.addressTemplate.postalCode;
      draft.contactEmail = partnerLocation.defaultContact.contactEmail;
      draft.contactName = partnerLocation.defaultContact.contactName;
      draft.contactPhone = partnerLocation.defaultContact.contactPhone;
      draft.fromAddressBookId = partnerLocation.id;
    });
  };

  const validationScheme = yup.object().shape({
    locationName: yup.string().required(),
    country: yup.string().required(),
    city: yup.string().required(),
    streetNr: yup.string().required(),
    zipCode: yup.string().required(),
    timeslots: yup.array().of(
      yup.object().shape({
        since: yup.string().required(),
        till: yup.string().required(),
      }),
    ),
    contactEmail: yup.string().email().nullable(),
  });

  const { clear, validateAndSend, validationErrors } = useValidation(validationScheme, () => onComplete(state));

  const isNotValid = (value: string) => {
    return value === undefined || value === null || value === '';
  };

  const [disabledProperties, setDisabledProperties] = useState({});

  const changeCargoItem = (orderIndex: number, orderItemIndex: number, value: CargoTemplateWithoutIdDTO) => {
    setState((draft: WritableDraft<EditDataLocationAndOrderOutDTO>) => {
      const orderItem = draft.orders[orderIndex].items[orderItemIndex];

      orderItem.cargoTemplate = value;

      const properties = [];

      if (!isNullOrUndefined(value.length)) {
        properties.push('length');
        orderItem.length = value.length;
      }
      if (!isNullOrUndefined(value.height)) {
        properties.push('height');
        orderItem.height = value.height;
      }
      if (!isNullOrUndefined(value.width)) {
        properties.push('width');
        orderItem.width = value.width;
      }
      setDisabledProperties({
        ...disabledProperties,
        [`${orderIndex}-${orderItemIndex}`]: properties,
      });
    });
  };

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Sidebar
        visible={visible}
        position="right"
        baseZIndex={1000000}
        onHide={() => onHide()}
        className="sidebar-modal  edit-router-sidebar"
        blockScroll={true}
        style={{ width: '750px' }}
      >
        {state && (
          <>
            <h3>{t(`EditRouteSidebar.editLocation`, `Upravit začátek trasy`)}</h3>
            <InputLayout>
              <LocationAutocomplete
                onChangeName={data =>
                  setState(draft => {
                    draft.locationName = data;
                  })
                }
                locationName={state.locationName}
                onSelectAddress={e => applyAddressBook(e)}
                wrapper="p-field p-col-12"
              >
                <ValidationDiv errors={validationErrors} path="locationName" />
              </LocationAutocomplete>
              <InputCountry
                label={t('wayPage.form.labelCountry')}
                value={state.country}
                onChange={value =>
                  setState(draft => {
                    draft.country = value;
                  })
                }
                classNameWrapper="p-col-12"
                error={findValidationMessage(validationErrors, 'country', t)}
              />
              <InputWrapper
                label={t('wayPage.form.labelZipCode')}
                type="text"
                value={state.zipCode}
                onChange={value =>
                  setState(draft => {
                    draft.zipCode = (value.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12"
                error={findValidationMessage(validationErrors, 'zipCode', t)}
              />
              <InputWrapper
                label={t('wayPage.form.labelCity')}
                type="text"
                value={state.city}
                onChange={value =>
                  setState(draft => {
                    draft.city = (value.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12"
                error={findValidationMessage(validationErrors, 'city', t)}
              />
              <InputWrapper
                label={t('wayPage.form.labelStreet')}
                type="text"
                value={state.streetNr}
                onChange={value =>
                  setState(draft => {
                    draft.streetNr = (value.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12"
                error={findValidationMessage(validationErrors, 'streetNr', t)}
              />
              <InputWrapper
                label={t('wayPage.form.labelContactPerson')}
                type="text"
                value={state.contactName}
                classNameWrapper="p-col-12"
                InputGroup
                onChange={value =>
                  setState(draft => {
                    draft.contactName = (value.target as HTMLInputElement).value;
                  })
                }
              />
              <InputWrapper
                label={t('wayPage.form.labelContactPhone')}
                type="text"
                value={state.contactPhone}
                classNameWrapper="p-col-12"
                onChange={value =>
                  setState(draft => {
                    draft.contactPhone = (value.target as HTMLInputElement).value;
                  })
                }
              />
              <InputWrapper
                label={t('wayPage.form.labelContactEmail')}
                type="text"
                value={state.contactEmail}
                onChange={value =>
                  setState(draft => {
                    draft.contactEmail = (value.target as HTMLInputElement).value;
                  })
                }
                classNameWrapper="p-col-12"
              >
                <ValidationDiv errors={validationErrors} path="contactEmail" />
              </InputWrapper>
            </InputLayout>
            <div className="p-mt-4">
              <Label title={t(`EditRouteSidebar.deliveryWindows`, `Expediční okna`)} />

              <OpeningHourDefinitionForm
                openingHours={state.openingHourDefinitions as OpeningHourDefinitionsDTO}
                onChange={v =>
                  setState(draft => {
                    draft.openingHourDefinitions = v as OpeningHourDefinitionsDTO;
                  })
                }
              />

              {/*{createView(state.timeslots).map((dayDefinition, index) => {
                return (
                  <div className="open-hours">
                    <div className="p-field-checkbox">
                      <Checkbox
                        inputId={`${uniqID}${index}`}
                        checked={dayDefinition.intervals.length > 0}
                        onChange={() => toggleEnable(dayDefinition.dayOfWeek)}
                      />
                      <label htmlFor={`${uniqID}${index}`}>
                        {t(`TemplateEdit.dayInWeek.${dayDefinition.dayOfWeek}`)}
                      </label>
                    </div>
                    {dayDefinition.intervals.length > 0 && (
                      <div className="open-hours__part">
                        {dayDefinition.intervals.map((interval, index) => {
                          return (
                            <div className="dropdown-wrapper-width">
                              <div className="dropdown-item p-mb-2">
                                <InputMask
                                  mask={'99:99'}
                                  value={interval.sinceTimeUtc}
                                  onComplete={(e) => changeSince(e.value, dayDefinition.dayOfWeek, index)}
                                />
                                {isNotValid(interval.sinceTimeUtc) && (
                                  <div className="p-invalid-feedback">Hodnota musí být zadána</div>
                                )}
                              </div>
                              <div className="delimiter"> — </div>
                              <div className="dropdown-item p-mb-2">
                                <InputMask
                                  mask={'99:99'}
                                  value={interval.tillTimeUtc}
                                  onComplete={(e) => changeTill(e.value, dayDefinition.dayOfWeek, index)}
                                />
                                {isNotValid(interval.tillTimeUtc) && (
                                  <div className="p-invalid-feedback">Hodnota musí být zadána</div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <Button
                          className="p-button-outlined p-mb-2"
                          label={t('EditRouteSidebar.addAnother', '+ Další')}
                          onClick={() => addInterval(dayDefinition.dayOfWeek)}
                        />
                      </div>
                    )}
                    {dayDefinition.intervals.length === 0 && (
                      <div className="open-hours__info">{t(`EditRouteSidebar.notSpecified`, `Není možné přijet`)}</div>
                    )}
                  </div>
                );
              })}*/}
            </div>
            {state.orders.map((loadedOrder, orderIndex, orders) => {
              return (
                <>
                  <div className="p-grid p-mb-2 p-ai-start p-formgroup-width-input">
                    <OrderIdSelector
                      orderName={loadedOrder.orderName}
                      onChangeOrderName={value =>
                        setState(draft => {
                          draft.orders[orderIndex].orderName = value;
                        })
                      }
                    />
                  </div>
                  {loadedOrder?.items
                    /*.map((it) => mapToOrderViewData(it))*/
                    .map((loadItem, orderItemIndex, orders) => (
                      <div key={orderItemIndex}>
                        <div className="p-flex p-ai-end p-mb-3 p-formgroup-inline p-formgroup-width">
                          <SkuSelector
                            sku={loadItem?.cargoTemplate}
                            onChange={value => {
                              changeCargoItem(orderIndex, orderItemIndex, value);
                            }}
                          />

                          <QuantitySelector
                            qty={loadItem.amount}
                            selectedQtyUnitCode={loadItem.quantityUnit}
                            onChangeQty={qty => {
                              changeOrderItemProperty(orderIndex, orderItemIndex, 'amount', qty);
                            }}
                            onChangeQtyUnit={value => {
                              changeOrderItemProperty(orderIndex, orderItemIndex, 'quantityUnit', value);
                            }}
                            qtyError={undefined}
                            qtyUnitError={undefined}
                          />
                          <div className="p-d-flex p-jc-between p-ml-auto p-mb-3">
                            {orders.length > 1 && (
                              <Button
                                icon="pi pi-trash"
                                className="p-button-outlined p-button-rounded p-ml-auto"
                                onClick={() => {
                                  deleteLoadingUnit(orderIndex, orderItemIndex);
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid p-ai-end">
                          <DimensionsSelector
                            width={loadItem.width}
                            height={loadItem.height}
                            length={loadItem.length}
                            onChangeDimensions={(property, value) => changeOrderItemProperty(orderIndex, orderItemIndex, property, value)}
                            widthError={undefined}
                            lengthError={undefined}
                            heightError={undefined}
                            disabledInputs={
                              // @ts-ignore
                              disabledProperties[`${orderIndex}-${orderItemIndex}`]
                            }
                          />
                        </div>
                        <div className="p-fluid p-formgrid p-grid p-ai-end">
                          <WeightSelector
                            weight={loadItem.weight}
                            weightMode={WeightModeEnum[loadItem.weightMode]}
                            onChangeWeight={value => {
                              changeOrderItemProperty(orderIndex, orderItemIndex, 'weight', value);
                            }}
                            onChangeMode={value => {
                              changeOrderItemProperty(orderIndex, orderItemIndex, 'weightMode', value);
                            }}
                            weightError={undefined}
                          />
                        </div>
                        <StackableWrapper wrapper="p-fluid">
                          <Label title={t(`EditRouteSidebar.stackable`, `Stohovatelné`)} required />
                          <Stackable
                            value={loadItem.stackable}
                            onChange={value => {
                              changeOrderItemProperty(orderIndex, orderItemIndex, 'stackable', value);
                            }}
                          />
                        </StackableWrapper>

                        <NewNote value={loadItem.itemNote} onChange={value => changeOrderItemProperty(orderIndex, orderItemIndex, 'itemNote', value)} />
                      </div>
                    ))}

                  <ButtonLayout>
                    <Button label={t('EditRouteSidebar.addAnother', '+ Další')} className="p-button-outlined p-mr-2" onClick={() => addLoadingUnit(orderIndex)} />
                  </ButtonLayout>
                </>
              );
            })}

            <ButtonLayout wrapper="WITH_PADDING_TOP_4">
              <Button label={t('labelSave')} className="p-mr-2" onClick={e => validateAndSend(state)} />
              <Button label={t('labelCancel')} className="p-button-text" onClick={() => onHide()} />
            </ButtonLayout>
          </>
        )}
      </Sidebar>
    </>
  );
};

export default EditRouteSidebar;
