import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import Card from '@components/framework/card/Card.tsx';
import SvgMapPin from '@components/framework/icons/MapPin.tsx';
import SectionPanel from '@components/framework/panels/SectionPanel.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import LocationSelect from '@components/framework/locationselect/LocationSelect.tsx';
import { Field } from '@components/framework/formfield/Field.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';
import ManageTimeslot from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';
import ReadonlyInput from '@components/framework/input/ReadonlyInput/ReadonlyInput.tsx';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import SvgRoute from '@components/ringil3/icons/heading/Route.tsx';
import { ExpeditionLocality, LocalityEntryModeEnum, NewExpeditionForm } from '@app/pages/expedition/create/types.ts';
import useForm from '@hooks/useForm/useForm.tsx';
import * as yup from 'yup';
import { newExpeditionInitialData } from '@app/pages/expedition/create/data.ts';
import SvgCoButtonDelete from '@icons/CoButtonDelete.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import { AnimatePresence, motion } from 'framer-motion';
import { generateUUID, isNotBlank, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import {
  AccessTypeEnum,
  EntityTypeEnum,
  ExpeditionStatusEnum,
  FeaturesEnum,
  GlobalOrdersRequest,
  OpeningEntityTypeEnum,
  SaveDistributionRequestInDTO,
  SaveExpeditionLocationRequestInDTO,
  SaveExpeditionRequestInDTO,
} from '@api/logsteo-api.v2.tsx';
import OrdersComponent from '@components/framework/orders/OrdersComponent.tsx';
import { expeditionConfig } from '@components/framework/orders/validation.ts';
import SvgLoadDown from '@icons/LoadDown.tsx';
import { emptyOrder, Order } from '@components/framework/orders/common.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';
import InputContact from '@components/framework/InputContact/InputContact.tsx';
import CreateDistributionOrAssign from '@app/pages/expedition/create/panels/CreateDistributionOrAssign.tsx';
import { ToastContext } from '@components/auth/ToastContext.tsx';
import StateTag from '@components/framework/tags/StateTag.tsx';
import TransportationTypeTag from '@components/framework/tags/TransportationTypeTag.tsx';
import ExpeditionTypeTag from '@components/framework/tags/ExpeditionTypeTag.tsx';
import ManipulationTag from '@components/framework/tags/ManipulationTag.tsx';
import CargoTypeTag from '@components/framework/tags/CargoTypeTag.tsx';
import TruckTag from '@components/framework/tags/TruckTag.tsx';
import RequirementsTag from '@components/framework/tags/RequirementsTag.tsx';
import CarrierNoteTag from '@components/framework/tags/CarrierNoteTag.tsx';
import ResponsiblePersonTag from '@components/framework/tags/ResponsiblePersonTag.tsx';
import InternalInfoNotifyTag from '@components/framework/tags/InternalInfoNotifyTag.tsx';
import InternalInfoNotifyByRoleTag from '@components/framework/tags/InternalInfoNotifyByRoleTag.tsx';
import { RoleEnum } from '@type/enums.ts';
import InternalInfoInvoiceState from '@components/framework/tags/InternalInfoInvoiceStateTag.tsx';
import InternalInfoDisponentTag from '@components/framework/tags/InternalInfoDisponentTag.tsx';
import InternalInfoPairingSymbol from '@components/framework/tags/InternalInfoPairingSymbol.tsx';
import InternalNoteTag from '@components/framework/tags/InternalNoteTag.tsx';
import InternalInfoRebillingTag from '@components/framework/tags/InternalInfoRebillingTag.tsx';
import AttachmentUploadPanel from '@app/pages/attachment/AttachmentUploadPanel.tsx';
import AttachmentsList from '@app/pages/attachment/AttachmentsList.tsx';
import { mapToAPIDateTime } from '@utils/date.tsx';
import dayjs from 'dayjs';
import InputCountry from '@components/framework/input/InputCountry/InputCountry.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon.tsx';
import SvgIconPlus from '@components/framework/orders/IconPlus.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import { formatAddress } from '@utils/formators.tsx';
import InternalInfoFolderTag from '@components/framework/tags/InternalInfoFolderTag.tsx';
import CustomerApplicationIdTag from '@components/framework/tags/CustomerApplicationIdTag.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import NavigationBreadCrumb from '@components/ringil3/BreadCrumb/NavigationBreadCrumb.tsx';

interface ComponentProps {}

const CreateExpedition: React.FC<ComponentProps> = () => {
  const { createExpedition } = useContext(ApiContext);
  const [hasMounted, setHasMounted] = useState(false);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { showToastMessage } = useContext(ToastContext);
  const { clear, BackendValidationComponent, setBeValidationErrors } = useBEValidation();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const form = useForm<NewExpeditionForm>(yup.object().shape({}), newExpeditionInitialData(), d => save(d), false, false);

  const mapDistribution = (data: NewExpeditionForm): SaveDistributionRequestInDTO => {
    if (data.distribution == null) return null;

    if (data.distribution.distributionType === 'FIXED') {
      return {
        fixedRequest: {
          price: data.distribution.price,
          responseDeadlineLocalTime: data.distribution.responseDeadlineLocalTime,
          responseDeadlineTimezone: data.distribution.responseDeadlineTimezone,
          selectedItems: data.distribution.selectedItems,
        },
      } as SaveDistributionRequestInDTO;
    }

    if (data.distribution.distributionType === 'ASSIGN') {
      return {
        directAssign: {
          price: data.distribution.price,
          carrierId: data.distribution.assigningCarrierId,
          notifyAssignedCarrier: data.distribution.notifyAssignedCarrier,
        },
      } as SaveDistributionRequestInDTO;
    }

    if (data.distribution.distributionType === 'SPOT') {
      return {
        spotRequest: {
          price: data.distribution.price,
          responseDeadlineLocalTime: data.distribution.responseDeadlineLocalTime,
          responseDeadlineTimezone: data.distribution.responseDeadlineTimezone,
          selectedItems: data.distribution.selectedItems,
        },
      } as SaveDistributionRequestInDTO;
    }
  };

  const mapLocation = (l: ExpeditionLocality): SaveExpeditionLocationRequestInDTO => {
    if (l.localityMode == LocalityEntryModeEnum.FROM_LOCATIONS) {
      return {
        companyLocationId: l.location.locationId,
        additionalContacts: l.additionalContacts,
        address: l.address,
        loadingReference: l.loadingReference,
        selectedPerson: l.selectedPerson,
        orders: l.orders,
        timeslots: l.timeslots,
        unloadingOrderIds: l.unloadingOrderIds,
        notifySelectedPerson: true,
      };
    } else {
      return {
        companyLocationId: null,
        additionalContacts: l.manualAddress.additionalPersons?.map(t => ({
          contactEmail: t.email,
          contactName: t.name,
        })),
        address: {
          name: l.manualAddress.name,
          city: l.manualAddress.city,
          country: l.manualAddress.countryCode3,
          postalCode: l.manualAddress.postalCode,
          streetNr: l.manualAddress.streetNr,
        },
        loadingReference: l.loadingReference,
        selectedPerson:
          [l.manualAddress.mainContactPerson, l.manualAddress.mainContactPhone, l.manualAddress.mainContactEmail].filter(t => t != null).length > 0
            ? {
                contactEmail: l.manualAddress.mainContactEmail,
                contactName: l.manualAddress.mainContactPerson,
                contactPhone: l.manualAddress.mainContactPhone,
              }
            : null,
        orders: l.orders,
        timeslots: l.timeslots,
        unloadingOrderIds: l.unloadingOrderIds,
        notifySelectedPerson: l.manualAddress.notifyContactPerson,
      };
    }
  };

  const save = (data: NewExpeditionForm) => {
    createExpedition(
      {
        applicationId: data.customApplicationId,
        locations: data.locations.map(t => mapLocation(t)),
        distribution: mapDistribution(data),
        expeditionType: data.expeditionType,
        labelIds: [],
        cargoType: data.cargoType,
        carrierNote: data.carrierNote,
        loadingTypes: data.loadingTypes,
        truckTypeCode: data.truckTypeCode,
        transportationType: data.transportationType,
        requirements: data.specialRequirements,
        responsiblePersonId: data.person?.id,
        notifiedPersonIds: data.persons?.map(t => t.id),
        invoiceState: data.invoiceState,
        disponentPersonId: data.disponentPerson?.id,
        pairingSymbol: data.pairingSymbol,
        internalNote: data.internalNote,
        rebiling: data.rebiling,
        folderId: data.folder?.id,
        attachments: data.attachments?.map(t => ({
          attachmentId: t.attachmentId,
          documentType: t.documentType,
          userNote: t.userNote,
          userDate: mapToAPIDateTime(dayjs(t.userDate)),
          accessType: AccessTypeEnum.ALL,
        })),
      } as SaveExpeditionRequestInDTO,
      d => {
        nav.navigate(nav.urlFunctions.createExpeditionDetailV2(d.expeditionId));
        showToastMessage('Uspech', `Expedice ${d.applicationId} byla vytvorena`, 'success');
      },
      null,
      { onValidationFailed: setBeValidationErrors },
    );
  };

  const addStop = (index: number) => {
    const newOrder = {
      orders: null,
      id: generateUUID(),
      unloadingOrderIds: null,
      timeslots: { dayWithInterval: [] },
    } as ExpeditionLocality;

    // add stop to the form immatubly
    form.setFieldValue('locations', [...form.values.locations.slice(0, index + 1), newOrder, ...form.values.locations.slice(index + 1)]);
  };

  const deleteLocation = (locIndex: number) => {
    form.useProduce(draft => {
      const unloadingOrderIds = draft.locations[locIndex].unloadingOrderIds || [];
      const allLoadingOrdersIds = draft.locations[locIndex].orders.map(t => t.id);
      draft.locations = draft.locations.filter((_, i) => i !== locIndex);
      draft.locations[draft.locations.length - 1].unloadingOrderIds = [
        ...draft.locations[draft.locations.length - 1].unloadingOrderIds.filter(t => !allLoadingOrdersIds.includes(t)),
        ...unloadingOrderIds,
      ];
    });
  };

  const getUnloadableOrders = (locIndex: number): GlobalOrdersRequest[] => {
    const prevLocations = form.values.locations.slice(0, locIndex);
    return prevLocations
      .map(loc => loc.orders)
      .flat()
      .filter(t => t != null)
      .filter(t => isNotBlank(t.orderName));
  };

  const unloadOrder = (locIndex: number, orderId: string) => {
    const previousLocation = form.values.locations.find(t => t.unloadingOrderIds?.includes(orderId));
    if (previousLocation) {
      const prevIndex = form.values.locations.indexOf(previousLocation);
      form.setFieldValue(
        `locations[${prevIndex}].unloadingOrderIds`,
        previousLocation.unloadingOrderIds.filter(t => t !== orderId),
      );
    }
    form.setFieldValue(`locations[${locIndex}].unloadingOrderIds`, Array.from(new Set([...(form.values.locations[locIndex].unloadingOrderIds || []), orderId])));
  };

  const getOrderName = (orderId: string): string => {
    const order = form.values.locations
      .map(loc => loc.orders)
      .flat()
      .filter(t => t != null)
      .find(t => t.id === orderId);
    return order?.orderName || '';
  };

  const deleteUnloadingLocation = (locIndex: number) => {
    const lastLocationIndex = form.values.locations.length - 1;
    form.setFieldValue(
      `locations[${lastLocationIndex}].unloadingOrderIds`,
      [...form.values.locations[lastLocationIndex].unloadingOrderIds, ...form.values.locations[locIndex].unloadingOrderIds],
      () => {
        form.setFieldValue(`locations[${locIndex}].unloadingOrderIds`, null);
      },
    );
  };

  const unloadNewlyAddedOrder = (orderId: string) => {
    const lastLocationIndex = form.values.locations.length - 1;
    const unloadingOrderIds = form.values.locations[lastLocationIndex]?.unloadingOrderIds || [];
    form.setFieldValue(`locations[${lastLocationIndex}].unloadingOrderIds`, [...unloadingOrderIds, orderId]);
  };

  const deleteOrderFromUnloadingLocation = (orderId: string) => {
    const location = form.values.locations.find(t => t.unloadingOrderIds?.includes(orderId));
    if (location) {
      const locIndex = form.values.locations.indexOf(location);
      form.setFieldValue(
        `locations[${locIndex}].unloadingOrderIds`,
        location.unloadingOrderIds.filter(t => t !== orderId),
      );
    }
  };

  /**
   * User clicked on the enable unloading on [1..n-1] location
   * @param locIndex
   */
  const onActivateUnloading = (locIndex: number) => {
    form.setFieldValue(`locations[${locIndex}].unloadingOrderIds`, []);
  };

  return (
    <>
      <div className={'flex flex-col gap-2'}>
        <BreadCrumbComponent items={[{ label: 'Expedice', url: nav.createNavigationLink(nav.urlFunctions.createExceptionList()) }]} />
        <BackendValidationComponent />
        <div className={'flex flex-row gap-4'}>
          <COHeadline variant={'h2'} title={tr('CreateExpedition.novaExpedice', 'Nová expedice')} />
          {/*<LabelsTag />*/}
        </div>
        <div className={'flex flex-col gap-2 my-4'}>
          <div className={'flex flex-row gap-4'}>
            <StateTag expeditionState={ExpeditionStatusEnum.NEW} />
            {loggedUser?.features.indexOf(FeaturesEnum.CUSTOM_EXP_APP_ID) != -1 && (
              <CustomerApplicationIdTag
                customApplicationId={form.values.customApplicationId}
                onChange={v =>
                  form.useProduce(d => {
                    d.customApplicationId = v;
                  })
                }
              />
            )}

            <TransportationTypeTag
              transportationType={form.values.transportationType}
              onChange={v => {
                form.useProduce(d => {
                  d.transportationType = v;
                });
              }}
            />

            <ExpeditionTypeTag
              value={form.values.expeditionType}
              onChange={v => {
                form.useProduce(d => {
                  d.expeditionType = v;
                });
              }}
            />

            <ManipulationTag
              value={form.values.loadingTypes}
              onChange={v =>
                form.useProduce(d => {
                  d.loadingTypes = v;
                })
              }
            />
          </div>
          <div className={'flex flex-row gap-4'}>
            <TruckTag
              truckTypeCode={form.values.truckTypeCode}
              onChange={v =>
                form.useProduce(d => {
                  d.truckTypeCode = v;
                })
              }
            />

            <CargoTypeTag
              cargoType={form.values.cargoType}
              onChange={v =>
                form.useProduce(d => {
                  d.cargoType = v;
                })
              }
            />
          </div>
          <div className={'flex flex-row gap-4'}>
            <RequirementsTag
              valueCodes={form.values.specialRequirements}
              onChange={v => {
                form.useProduce(draft => {
                  draft.specialRequirements = v;
                });
              }}
            />

            <CarrierNoteTag
              value={form.values.carrierNote}
              onChange={v =>
                form.useProduce(draft => {
                  draft.carrierNote = v;
                })
              }
            />
          </div>
        </div>
        {/*{dumpVars(form.values)}*/}
        <SectionPanel icon={<SvgRoute />} heading={tr('CreateExpedition.trasa', 'Trasa')}>
          <AnimatePresence>
            {form.values?.locations?.map((loc, locIndex, locArray) => {
              return (
                <motion.div
                  key={loc.id}
                  className={'flex flex-col gap-4'}
                  initial={hasMounted ? { opacity: 0, y: 10 } : false}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className={'flex flex-row items-center justify-between'}>
                    <NavigationBreadCrumb locationCount={form.values?.locations?.length} currentPosition={locIndex} idPrefix={'createExp'} />
                    {/*{locIndex !== 0 && locIndex !== locArray.length - 1 && (
                       <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} onClick={() => deleteLocation(locIndex)} />
                      )}*/}
                  </div>
                  <div className={'grid grid-cols-2 gap-4'}>
                    <Card>
                      <div className={'flex flex-col gap-4'}>
                        <COHeadline title={tr('CreateExpedition.lokalita', 'Lokalita')} variant={'h4'} icon={<SvgMapPin />} />
                        <InputSelect
                          selectedItemCode={loc.localityMode}
                          model={[
                            { label: tr('CreateExpedition.existujici', 'Existujici'), code: LocalityEntryModeEnum.FROM_LOCATIONS },
                            { label: tr('CreateExpedition.zadatRucne', 'Zadat ručně'), code: LocalityEntryModeEnum.MANUALLY },
                          ]}
                          onSelect={v => {
                            form.useProduce(draft => {
                              if (v === LocalityEntryModeEnum.MANUALLY) {
                                draft.locations[locIndex].location = null;
                                draft.locations[locIndex].manualAddress = {};
                              } else {
                                draft.locations[locIndex].manualAddress = null;
                              }
                              draft.locations[locIndex].localityMode = v as LocalityEntryModeEnum;
                            });
                          }}
                        />

                        {loc.localityMode === LocalityEntryModeEnum.FROM_LOCATIONS && (
                          <>
                            <Field label={tr('CreateExpedition.lokace', 'Lokace')} required>
                              <LocationSelect
                                fullWidth
                                value={loc.location?.locationId}
                                onChange={v => {
                                  form.useProduce(draft => {
                                    draft.locations[locIndex].location = v;
                                    draft.locations[locIndex].companyLocationId = v.locationId;
                                  });
                                }}
                              />
                            </Field>
                            {isNotNullOrUndefined(loc.location) && (
                              <Field label={tr('CreateExpedition.adresa', 'Adresa')} required>
                                <ReadonlyInput
                                  value={formatAddress(
                                    loc.location.addressTemplate.country,
                                    loc.location.addressTemplate.postalCode,
                                    loc.location.addressTemplate.city,
                                    loc.location.addressTemplate.streetNr,
                                  )}
                                />
                              </Field>
                            )}
                            <Field label={tr('CreateExpedition.hlavniKontakt', 'Hlavní kontakt')} required>
                              <InputContact
                                contacts={loc.location?.contacts}
                                value={loc?.selectedPerson}
                                onChange={v => form.setFieldValue(`locations[${locIndex}].selectedPerson`, v)}
                                fullWidth
                              />
                            </Field>
                            <Field label={tr('CreateExpedition.notifikovatOPreprave', 'Notifikovat o preprave')}>
                              <MultiSelect
                                options={loc.location?.contacts}
                                optionLabel={'contactName'}
                                value={loc.additionalContacts}
                                onChange={e => form.setFieldValue(`locations[${locIndex}].additionalContacts`, e.value)}
                              />
                            </Field>
                          </>
                        )}
                        {loc.localityMode === LocalityEntryModeEnum.MANUALLY && (
                          <>
                            <Field required label={tr('CreateExpedition.nazevLokace', 'Název lokace')}>
                              <RingilInputText
                                value={loc.manualAddress?.name}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.name = v;
                                  })
                                }
                              />
                            </Field>
                            <div className={'grid grid-cols-2 gap-4'}>
                              <Field label={tr('CreateExpedition.psc', 'PSČ')}>
                                <RingilInputText
                                  value={loc.manualAddress.postalCode}
                                  onChange={v =>
                                    form.useProduce(draft => {
                                      draft.locations[locIndex].manualAddress.postalCode = v;
                                    })
                                  }
                                />
                              </Field>
                              <Field label={tr('CreateExpedition.zeme', 'Země')}>
                                <InputCountry
                                  value={loc.manualAddress.countryCode3}
                                  onChange={v => {
                                    form.useProduce(d => {
                                      d.locations[locIndex].manualAddress.countryCode3 = v;
                                    });
                                  }}
                                />
                              </Field>
                            </div>
                            <Field required label={tr('CreateExpedition.mesto', 'Město')}>
                              <RingilInputText
                                value={loc.manualAddress?.city}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.city = v;
                                  })
                                }
                              />
                            </Field>
                            <Field required label={tr('CreateExpedition.uliceCp', 'Ulice, č.p')}>
                              <RingilInputText
                                value={loc.manualAddress?.streetNr}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.streetNr = v;
                                  })
                                }
                              />
                            </Field>
                            <COHeadline variant={'h4'} title={tr('CreateExpedition.kontakty', 'Kontakty')} />
                            <Field label={tr('CreateExpedition.hlavniKontaktJmeno', 'Hlavní kontakt jméno')}>
                              <RingilInputText
                                value={loc.manualAddress?.mainContactPerson}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.mainContactPerson = v;
                                  })
                                }
                              />
                            </Field>
                            <Field label={tr('CreateExpedition.hlavniKontaktniTelefon', 'Hlavní kontaktní telefon')}>
                              <RingilInputText
                                value={loc.manualAddress?.mainContactPhone}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.mainContactPhone = v;
                                  })
                                }
                              />
                            </Field>
                            <Field label={tr('CreateExpedition.hlavniKontaktniEmail', 'Hlavní kontaktní e-mail')}>
                              <RingilInputText
                                value={loc.manualAddress?.mainContactEmail}
                                onChange={v =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.mainContactEmail = v;
                                  })
                                }
                              />
                            </Field>
                            {loc.manualAddress.additionalPersons?.length > 0 && (
                              <COHeadline variant={'h4'} title={tr('CreateExpedition.notifikovatEmailemOPreprave', 'Notifikovat e-mailem o přepravě')} />
                            )}

                            {loc.manualAddress.mainContactEmail && loc.manualAddress.mainContactPerson && (
                              <InputCheckbox
                                value={loc.manualAddress.notifyContactPerson}
                                label={tr('CreateExpedition.hlavniKontakt', 'Hlavní kontakt')}
                                note={loc.manualAddress.mainContactEmail}
                                onChange={(_, v) =>
                                  form.useProduce(d => {
                                    d.locations[locIndex].manualAddress.notifyContactPerson = v;
                                  })
                                }
                              />
                            )}
                            {loc.manualAddress.additionalPersons?.map((person, personIndex) => {
                              return (
                                <React.Fragment key={personIndex}>
                                  <div className={'grid grid-cols-2 gap-4'}>
                                    <Field label={tr('CreateExpedition.jmenoOsoby', 'Jméno osoby')} required>
                                      <RingilInputText
                                        value={person.name}
                                        onChange={v =>
                                          form.useProduce(draft => {
                                            draft.locations[locIndex].manualAddress.additionalPersons[personIndex].name = v;
                                          })
                                        }
                                      />
                                    </Field>
                                    <Field
                                      label={tr('CreateExpedition.notifikovatEmailemOPreprave', 'Notifikovat e-mailem o přepravě')}
                                      required
                                      actionButtons={
                                        <RingilButton
                                          mode={'rect-small'}
                                          leftIcon={<SvgCoButtonDelete />}
                                          onClick={() => {
                                            form.useProduce(draft => {
                                              draft.locations[locIndex].manualAddress.additionalPersons = draft.locations[
                                                locIndex
                                              ].manualAddress.additionalPersons.filter((_, i) => i !== personIndex);
                                            });
                                          }}
                                        />
                                      }
                                    >
                                      <RingilInputText
                                        value={person.email}
                                        onChange={v =>
                                          form.useProduce(d => {
                                            d.locations[locIndex].manualAddress.additionalPersons[personIndex].email = v;
                                          })
                                        }
                                      />
                                    </Field>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                            <LinkWithIcon
                              label={tr('CreateExpedition.notifikovatDalsiEmail', 'Notifikovat další e-mail')}
                              icon={<SvgIconPlus />}
                              onClick={() => {
                                form.useProduce(draft => {
                                  if (draft.locations[locIndex].manualAddress.additionalPersons == null) {
                                    draft.locations[locIndex].manualAddress.additionalPersons = [];
                                  }
                                  draft.locations[locIndex].manualAddress.additionalPersons.push({
                                    name: '',
                                    email: '',
                                  });
                                });
                              }}
                            ></LinkWithIcon>
                          </>
                        )}

                        <Field label={tr('CreateExpedition.referenceProNakladku', 'Reference pro nakládku')}>
                          <RingilInputText value={loc.loadingReference} onChange={v => form.setFieldValue(`locations[${locIndex}].loadingReference`, v)} />
                        </Field>
                        <Field label={tr('CreateExpedition.ocekavanaManipulace', 'Očekávaná manipulace')} required>
                          <ManageTimeslot
                            timeslot={loc.timeslots}
                            externalLabel
                            onChange={v => {
                              form.setFieldValue(`locations[${locIndex}].timeslots`, v);
                            }}
                            entityId={loc.location?.locationId}
                            entityType={OpeningEntityTypeEnum.LOCATION}
                            noOpeningHours={false}
                          />
                        </Field>
                      </div>
                    </Card>

                    {/*// zde je pravy panel*/}
                    <div className={'flex flex-col gap-4'}>
                      {loc.orders?.length > 0 ? (
                        <div className={'flex flex-col gap-4'}>
                          <Card>
                            <OrdersComponent
                              form={form.getSubForm<{ orders: Order[] }>(`locations[${locIndex}]`)}
                              config={expeditionConfig}
                              canBeEmpty={true}
                              generateOrderId={true}
                              onOrderAdded={orderId => {
                                unloadNewlyAddedOrder(orderId);
                              }}
                              onDeleteOrder={orderId => {
                                deleteOrderFromUnloadingLocation(orderId);
                              }}
                            />
                          </Card>
                        </div>
                      ) : (
                        <>
                          {locIndex < locArray.length - 1 && (
                            <div className={'flex flex-col gap-4'}>
                              <AddButton
                                title={tr('CreateExpedition.nakladka', 'Nakladka')}
                                fullWidth
                                onClick={() => {
                                  const newOrder = emptyOrder(false, true);
                                  form.setFieldValue(`locations[${locIndex}].orders`, [newOrder]);
                                  form.setFieldValue(`locations[${form.values.locations.length - 1}].unloadingOrderIds`, [
                                    ...form.values.locations[form.values.locations.length - 1].unloadingOrderIds,
                                    newOrder.id,
                                  ]);
                                }}
                                type={'ROUNDED'}
                              />
                            </div>
                          )}
                        </>
                      )}

                      <div className={'flex flex-row gap-4'}>
                        {isNullOrUndefined(loc.unloadingOrderIds) && locIndex !== 0 ? (
                          <AddButton
                            title={tr('CreateExpedition.vykladka', 'Vykladka')}
                            fullWidth
                            onClick={() => onActivateUnloading(locIndex)}
                            type={'ROUNDED'}
                            hideButton={!(locIndex < locArray.length - 1)}
                          />
                        ) : (
                          <>
                            {locIndex !== 0 && (
                              <Card>
                                <div className={'flex flex-col gap-4'}>
                                  <div className={'flex flex-row justify-between items-center'}>
                                    <COHeadline variant={'h4'} title={tr('CreateExpedition.vylozitObjednavky', 'Vyložit objednávky')} icon={<SvgLoadDown />} />
                                    {locIndex < locArray.length - 1 && (
                                      <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} onClick={() => deleteUnloadingLocation(locIndex)} />
                                    )}
                                  </div>
                                  {loc.unloadingOrderIds?.map(orderId => {
                                    return (
                                      <Field label={tr('CreateExpedition.objednavka', 'Objednávka')} key={orderId}>
                                        <>
                                          <ReadonlyInput value={getOrderName(orderId)} />
                                          {/*<InputActionButton
                                        title={'Akce'}
                                        onClick={() => {}}
                                        icon={<SvgArrowDown />}
                                        menu={[{ label: 'test', code: 'test' }]}
                                        onMenuSelect={code => {}}
                                        />*/}
                                        </>
                                      </Field>
                                    );
                                  })}
                                  <Field label={tr('CreateExpedition.vylozitDalsiObjednavku', 'Vyložit další objednávku')}>
                                    <Dropdown
                                      options={getUnloadableOrders(locIndex)}
                                      optionLabel={'orderName'}
                                      optionValue={'id'}
                                      value={loc.unloadingOrderIds}
                                      onChange={e => {
                                        unloadOrder(locIndex, e.value);
                                      }}
                                    />
                                  </Field>
                                </div>
                              </Card>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {locIndex < locArray.length - 1 && (
                    <AddButton title={tr('CreateExpedition.zastavka', 'Zastavka')} fullWidth onClick={() => addStop(locIndex)} type={'ROUNDED'} />
                  )}
                </motion.div>
              );
            })}
          </AnimatePresence>
          <AttachmentsList
            attachments={form.values.attachments}
            onDeletedAttachment={attachmentId =>
              form.setFieldValue(
                form.names.attachments,
                form.values.attachments.filter(t => t.attachmentId !== attachmentId),
              )
            }
          ></AttachmentsList>
          <AttachmentUploadPanel
            onSave={v => {
              form.setFieldValue(form.names.attachments, [...(form.values.attachments || []), ...v.attachments]);
            }}
            loading={false}
          />

          <HorizontalLine variant={'tertiary'} />
          <CreateDistributionOrAssign distribution={form.values.distribution} onChange={v => form.setFieldValue(form.names.distribution, v)} />
          <COHeadline variant={'h3'} title={tr('CreateExpedition.interniInfo', 'Interní info')} />
          <div className={'flex flex-col gap-4'}>
            <div className={'flex flex-row gap-4'}>
              <ResponsiblePersonTag
                person={form.values.person}
                onChange={v => {
                  form.useProduce(d => {
                    d.person = v;
                  });
                }}
              />

              <InternalInfoNotifyTag
                persons={form.values.persons}
                onChange={v => {
                  form.useProduce(d => {
                    d.persons = v;
                  });
                }}
              />

              <InternalInfoNotifyByRoleTag
                notifyRoleName={RoleEnum.ROLE_EXP_NOTIFY}
                isActive={true}
                onChange={v => {
                  form.useProduce(d => {
                    d.notifyByRole = v;
                  });
                }}
              />
            </div>
            <div className={'flex flex-row gap-4'}>
              <InternalInfoInvoiceState
                value={form.values.invoiceState}
                onChange={v => {
                  form.useProduce(d => {
                    d.invoiceState = v;
                  });
                }}
              />

              <InternalInfoDisponentTag
                disponentPerson={form.values.disponentPerson}
                onChange={v => {
                  form.useProduce(d => {
                    d.disponentPerson = v;
                  });
                }}
              />

              <InternalInfoPairingSymbol
                value={form.values.pairingSymbol}
                onChange={v => {
                  form.useProduce(d => {
                    d.pairingSymbol = v;
                  });
                }}
              />

              <InternalNoteTag
                value={form.values.internalNote}
                onChange={v => {
                  form.useProduce(d => {
                    d.internalNote = v;
                  });
                }}
              />

              <InternalInfoRebillingTag
                value={form.values.rebiling}
                onChange={v => {
                  form.useProduce(d => {
                    d.rebiling = v;
                  });
                }}
              />

              <InternalInfoFolderTag
                folder={form.values.folder}
                onChange={v => {
                  form.useProduce(d => {
                    d.folder = v;
                  });
                }}
                entityType={EntityTypeEnum.EXPEDITION}
              />
            </div>
          </div>
          <div className={'flex flex-row mx-4 my-3'}>
            <RingilButton mode="filled-big" label={tr('CreateExpedition.vytvorit', 'Vytvořit')} onClick={() => form.validateAndSend()} />
          </div>
        </SectionPanel>
      </div>
    </>
  );
};

export default CreateExpedition;
