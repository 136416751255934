import React from 'react';
import styled from 'styled-components';
import { InputText } from 'primereact/inputtext';
import { RowWithGap } from '@components/styles.tsx';
import { isNullOrUndefined } from '@utils/utils.tsx';
import RingilInputText from '@components/framework/input/RingilInput/RingilInputText.tsx';

interface ComponentProps {
  suffix: string | React.ReactNode;
  inputTextPosition?: 'left' | 'right';
  value: string | number;
  onChange: (value: string) => void;
  button?: JSX.Element;
  readOnly?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  type?: string;
  disabled?: boolean;
}

const InputTextWithSuffix: React.FC<ComponentProps> = ({
  value,
  onChange,
  suffix,
  inputTextPosition = 'left',
  button,
  readOnly = false,
  fullWidth = false,
  fullHeight = false,
  type = 'text',
  disabled = false,
}) => {
  const style = {
    width: fullWidth ? '100%' : undefined,
    height: fullHeight ? '100%' : undefined,
  };
  return (
    <div className={'flex flex-row gap-2 items-center grow shrink'}>
      <div className="relative" style={style}>
        <RingilInputText
          readOnly={readOnly}
          value={isNullOrUndefined(value) ? '' : `${value}`}
          onChange={v => onChange(v)}
          className={'w-full p-2 pr-10 outline-none no-spinner'}
          type={type}
          disabled={disabled}
        />
        <span className="suffix absolute inset-y-0 right-0 flex items-center pr-3">{suffix}</span>
      </div>
      {button}
    </div>
  );
};

export default InputTextWithSuffix;
