import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useImmer } from 'use-immer';
import { TemplateEditHandlerView } from './types';
import {
  CargoTemplateInDTO,
  DistributionTemplateInDTO,
  DistributionTypeEnum,
  EditDataLocationAndOrderOutDTO,
  EditTemplateCarrierRequirementInDTO,
  EditTemplateLocationOrderInDTO,
  FindCarrierCarrierOrGroupDTO,
} from '@api/logsteo-api.v2';
import { isNullOrUndefined } from '@utils/utils';
import { ApiContext } from '@api/api';

interface TemplateEditHandlerContextType {
  state: TemplateEditHandlerView;
  updateRequirements: (expeditionTemplateId: string, data: EditTemplateCarrierRequirementInDTO, callback?: () => void) => void;
  updateTemplateName: (templateId: string, templateName: string, callback?: () => void) => void;
  updateCoverageRequest: (distributionTemplateId: string, data: DistributionTemplateInDTO, callback?: () => void) => void;
  updateTemplateLocation: (expeditionTemplateId: string, locationId: string, data: EditDataLocationAndOrderOutDTO, callback?: () => void) => void;
  changeDistributionType: (distributionType: DistributionTypeEnum) => void;
  changeDistributionPrice: (price: number) => void;
  changeDistributionSelectedItems: (items: FindCarrierCarrierOrGroupDTO[]) => void;
  toggleCreateDistribution: () => void;
  changeDistributionIntervalHour: (value: string) => void;
  createDistributionTemplate: () => void;
  changeCurrency: (currency: string) => void;
  changeAutomaticPublishing: (automaticallyPublish: boolean) => void;
  refreshDataFromDB: (onSuccess: () => void) => void;
}

export const TemplateEditHandlerContext = createContext<TemplateEditHandlerContextType>(undefined);

interface ComponentProps {
  initialData: TemplateEditHandlerView;
}

const TemplateEditHandlerProvider: React.FC<PropsWithChildren<ComponentProps>> = ({ initialData, children }) => {
  const [state, setState] = useImmer<TemplateEditHandlerView>(initialData);
  const {
    cuUpdateEditTemplateCarrierRequirement,
    cuGetTemplateData,
    cuUpdateEditTemplateName,
    cuUpdateEditDistribution,
    cuUpdateEditTemplateLocationAndOrder,
    cuCreateDistributionTemplate,
  } = useContext(ApiContext);

  const methods: TemplateEditHandlerContextType = {
    state,
    updateRequirements: (expeditionTemplateId: string, data: EditTemplateCarrierRequirementInDTO, callback = () => {}) => {
      cuUpdateEditTemplateCarrierRequirement(expeditionTemplateId, data, () => {
        cuGetTemplateData(expeditionTemplateId, result => {
          setState(draft => {
            return result;
          });
          callback();
        });
      });
    },
    updateTemplateName: (templateId: string, templateName: string, callback = () => {}) => {
      cuUpdateEditTemplateName(templateId, { templateName }, () => {
        cuGetTemplateData(templateId, result => {
          setState(draft => {
            return result;
          });
          callback();
        });
      });
    },
    updateCoverageRequest: (distributionTemplateId: string, data: DistributionTemplateInDTO, callback = () => {}) => {
      cuUpdateEditDistribution(distributionTemplateId, data, () => {
        cuGetTemplateData(distributionTemplateId, result => {
          setState(draft => {
            return result;
          });
          callback();
        });
      });
    },
    updateTemplateLocation: (expeditionTemplateId: string, locationId: string, data: EditDataLocationAndOrderOutDTO, callback = () => {}) => {
      cuUpdateEditTemplateLocationAndOrder(
        expeditionTemplateId,
        locationId,
        {
          locationName: data.locationName,
          fromAddressBookId: data.fromAddressBookId,
          city: data.city,
          contactEmail: data.contactEmail,
          country: data.country,
          orders: data.orders.map(o => {
            return {
              id: o.id,
              orderName: o.orderName,
              items: o.items.map(c => {
                return {
                  cargoItemTypeCode: c.cargoTemplate?.code,
                  amount: c.amount,
                  weight: c.weight,
                  length: c.length,
                  width: c.width,
                  weightMode: c.weightMode,
                  height: c.height,
                  quantityUnit: c.quantityUnit,
                  stackable: c.stackable,
                  itemNote: c.itemNote,
                } as CargoTemplateInDTO;
              }),
            } as EditTemplateLocationOrderInDTO;
          }),
          pickupCode: data.pickupCode,
          contactName: data.contactName,
          contactPhone: data.contactPhone,
          streetNr: data.streetNr,
          zipCode: data.zipCode,
          openingHourDefinitions: data.openingHourDefinitions,
          /* timeslots: data.timeslots.map((i) => {
            return {
              till: i.till,
              since: i.since,
              dayOfWeek: i.dayOfWeek,
            };
          }),*/
        },
        () => {
          cuGetTemplateData(expeditionTemplateId, result => {
            setState(draft => {
              return result;
            });
            callback();
          });
        },
      );
    },
    toggleCreateDistribution: () => {
      setState(draft => {
        if (isNullOrUndefined(draft.distribution))
          draft.distribution = {
            enabled: false,
            currency: 'CZK',
            isPublished: true,
          };
        draft.distribution.enabled = !draft.distribution.enabled;
        if (draft.distribution.enabled) {
          if (isNullOrUndefined(draft.distribution.intervalHour)) {
            draft.distribution.intervalHour = 96;
          }
          if (isNullOrUndefined(draft.distribution.selectedItems)) draft.distribution.selectedItems = [];
          if (isNullOrUndefined(draft.distribution.distributionType)) {
            draft.distribution.distributionType = DistributionTypeEnum.SPOT;
          }
        }
      });
    },
    changeDistributionType: (distributionType: DistributionTypeEnum) => {
      setState(draft => {
        draft.distribution.distributionType = distributionType;
      });
    },

    changeDistributionPrice: (price: number) => {
      setState(draft => {
        draft.distribution.price = price;
      });
    },
    changeDistributionSelectedItems: (items: FindCarrierCarrierOrGroupDTO[]) => {
      setState(draft => {
        draft.distribution.selectedItems = items;
      });
    },
    changeDistributionIntervalHour: (value: string) => {
      setState(draft => {
        const number = parseInt(value);
        draft.distribution.intervalHour = isNaN(number) ? null : number;
      });
    },
    createDistributionTemplate: () => {
      cuCreateDistributionTemplate(
        {
          price: state.distribution.price,
          intervalHour: state.distribution.intervalHour,
          name: state.templateName,
          currency: state.distribution.currency,
          distributionType: state.distribution.distributionType,
          templateExpeditionId: state.templateExpeditionId,
          carriersAndGroups: state.distribution.selectedItems,
          shouldExpeditionAutomaticallyPublished: state.distribution.isPublished,
        },
        () => {
          cuGetTemplateData(state.templateExpeditionId, result => {
            setState(draft => {
              return result;
            });
          });
        },
      );
    },
    changeCurrency: (currency: string) => {
      setState(draft => {
        draft.distribution.currency = currency;
      });
    },
    changeAutomaticPublishing: (automaticallyPublish: boolean) => {
      setState(draft => {
        draft.distribution.isPublished = automaticallyPublish;
      });
    },
    refreshDataFromDB: (onSuccess: () => void) => {
      cuGetTemplateData(state.templateExpeditionId, result => {
        setState(draft => {
          onSuccess();
          return result;
        });
      });
    },
  };

  return <TemplateEditHandlerContext.Provider value={methods}>{children}</TemplateEditHandlerContext.Provider>;
};

export default TemplateEditHandlerProvider;
