import React, { PropsWithChildren, useContext, useState } from 'react';
import SvgMenuDashboard from '../icons/menu/MenuDashboard.tsx';
import SvgMenuSettings from '../icons/menu/MenuSettings.tsx';
import SvgMenuUser from '../icons/menu/MenuUser.tsx';
import SvgMenuTms from '../icons/menu/MenuTms.tsx';
import SvgMenuTimeslot from '../icons/menu/MenuTimeslot.tsx';
import SvgMenuSn from '../icons/menu/MenuSn.tsx';
import SvgMenuContacts from '../icons/menu/MenuContacts.tsx';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { CompanyModuleEnum, FeaturesEnum, PermissionTypesEnum } from '@api/logsteo-api.v2.tsx';
import { isNullOrUndefined, useLocalStorage } from '@utils/utils.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import PermissionError from '@components/ringil3/PermissionError/PermissionError.tsx';
import MenuHeader from '@app/layout/MenuHeader.tsx';
import Menu from '@app/layout/Menu.tsx';
import { MenuItemModel, SubMenuItemModel } from '@components/menu/types.ts';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { useAuth0 } from '@auth0/auth0-react';
import { Note } from '@components/styles.tsx';
import { useTranslation } from 'react-i18next';
import { useTopInformation } from '@components/obsolete/TopInformation/TopInformation.tsx';
import NotificationMenuItem from '@app/layout/NotificationMenuItem.tsx';
import CustomerNotificationMenuItem from '@app/layout/CustomerNotificationMenuItem.tsx';
import Customer2InheritorNotificationSidebar from '@components/ringil3/Features/Customer2InheritorNotification/sidebars/Customer2InheritorNotificationSidebar.tsx';
import { ApiContext } from '@api/api.tsx';
import SvgMenuReports from '@app/icons/menu/MenuReports.tsx';
import { useConditionalDebounce } from '@hooks/useDebounceConditional/useDebounceConditional.tsx';

interface ComponentProps {
  title?: string;
  oneOfPermission?: PermissionTypesEnum[];
}

const Layout: React.FC<PropsWithChildren<ComponentProps>> = ({ title, oneOfPermission, children }) => {
  const [smallMenuForDebounce, setSmallMenu] = useState(false);
  const smallMenu = useConditionalDebounce<boolean>(smallMenuForDebounce, 700, t => false);

  const [lockedMenu, setLockedMenu] = useLocalStorage('Layout.lockedMenu', true);
  const [removeWidthLimt, setRemoveWidthLimit] = useLocalStorage('Layout.removeWidthLimit', true);
  const { tr } = useTranslationLgs();
  const nav = useNavigation();
  const { logout } = useAuth0();
  const { i18n } = useTranslation();
  const { versionInfo, TopInformation } = useTopInformation();
  const { listPowerBIReports } = useContext(ApiContext);

  const { loggedUser, hasModule, hasPermission, hasPermissionAndModule, isCustomerAdmin, isCompanyAdmin, isCarrierAdmin } = useContext(AuthenticatedUserContext);
  const [visible, setVisible] = useState(false);

  if (oneOfPermission?.length > 0 && isNullOrUndefined(loggedUser)) return <>Loading...</>;
  const showMessagedFromOwners =
    loggedUser?.modules?.filter(t => t.inheritingModule === true).length > 0 || loggedUser?.modules?.some(t => t.companyModule === CompanyModuleEnum.CARRIER);

  const showNotificationAdmin =
    loggedUser?.modules?.filter(t => t.inheritingModule === false).length > 0 && !loggedUser?.modules?.some(t => t.companyModule === CompanyModuleEnum.CARRIER);

  if (oneOfPermission?.length > 0 && !hasPermission(oneOfPermission))
    return (
      <>
        <PermissionError permission={oneOfPermission} />
      </>
    );

  const appMenu: MenuItemModel[] = [
    {
      title: tr('Layout.dashboard', 'Dashboard'),
      icon: <SvgMenuDashboard />,
      url: nav.createNavigationLink(nav.urlFunctions.createHomePage()),
      render: true,
    },
    {
      title: tr('Layout.expeditions', 'Expeditions'),
      icon: <SvgMenuTms />,
      render: hasModule(CompanyModuleEnum.CARRIER, null),
      subMenu: [
        {
          title: tr('Layout.allShipments', 'Všechny přiřazené'),
          url: nav.createNavigationLink(nav.urlFunctions.createCarrierShipmentsList()),
          render: true,
        },
        {
          title: tr('Layout.allDemands', 'Seznam všech'),
          url: nav.createNavigationLink(nav.urlFunctions.createCarrierDemandsList()),
          render: true,
        },
        {
          title: tr('Layout.allMultiDemands', 'Multi demands'),
          url: nav.createNavigationLink(nav.urlFunctions.createCarrierMultiDemandsList()),
          render: true,
        },
      ],
    },
    {
      title: tr('Layout.expeditions', 'Expeditions'),
      icon: <SvgMenuTms />,
      render: hasModule(CompanyModuleEnum.EXPEDITION, null),
      subMenu: [
        {
          title: tr('Layout.new', 'New'),
          url: nav.createNavigationLink(nav.urlFunctions.createCustomerNewExpedition()),
          render: true,
        },
        {
          title: tr('Layout.newV2', 'New v2'),
          url: nav.createNavigationLink(nav.urlFunctions.createCustomerNewExpeditionV2()),
          render: loggedUser?.features?.some(t => t == FeaturesEnum.NEW_RESERVATION),
        },
        {
          title: tr('Layout.expeditionList', 'Expedition list'),
          url: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionList()),
          render: true,
        },
        {
          title: tr('Layout.routes', 'Templates'),
          url: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionTemplates()),
          render: hasModule(CompanyModuleEnum.EXPEDITION, false),
        },
      ],
    },
    {
      title: tr('Layout.timeslots', 'Timeslots'),
      icon: <SvgMenuTimeslot />,
      render: hasModule(CompanyModuleEnum.TIMESLOTS, null),
      subMenu: [
        {
          title: tr('Layout.timeSlotsDashboard', 'Calendar'),
          url: nav.createNavigationLink(nav.urlFunctions.createTimeslotCalendar()),
          render:
            hasModule(CompanyModuleEnum.TIMESLOTS, false) &&
            hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DASHBOARD_RW, PermissionTypesEnum.PERMISSION_TIMESLOT_DASHBOARD_READ]),
        },
        {
          title: tr('Layout.timeSlotsDashboardMulti', 'CalendarMulti'),
          url: nav.createNavigationLink(nav.urlFunctions.createTimeslotCalendarMulti()),
          render:
            hasModule(CompanyModuleEnum.TIMESLOTS, false) &&
            hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_DASHBOARDMULTI_RW, PermissionTypesEnum.PERMISSION_TIMESLOT_DASHBOARDMULTI_READ]),
        },
        {
          title: tr('Layout.reservation', 'Reservation list'),
          url: nav.createNavigationLink(nav.urlFunctions.createReservationList()),
          render: hasPermission([PermissionTypesEnum.PERMISSION_TIMESLOT_LIST_RW, PermissionTypesEnum.PERMISSION_TIMESLOT_LIST_READ]),
        },
        {
          title: tr('Layout.cams', 'Cams'),
          url: nav.createNavigationLink(nav.urlFunctions.createCamsDetail()),
          render: loggedUser?.features?.indexOf(FeaturesEnum.ENTRY_CAMS) != -1 && hasPermission([PermissionTypesEnum.PERMISSION_CAM_ENTRYLOG]),
        },
        {
          title: tr('Layout.camsSettings', 'Cams settings'),
          url: nav.createNavigationLink(nav.urlFunctions.createCamsSettings()),
          render: loggedUser?.features?.indexOf(FeaturesEnum.ENTRY_CAMS) != -1 && hasPermission([PermissionTypesEnum.PERMISSION_CAM_SETTINGS]),
        },
        /*{
          title: tr('Layout.areaVisiting', 'Visitors'),
          url: nav.createNavigationLink(nav.urlFunctions.createVisitorList()),
          render: loggedUser?.features?.indexOf(FeaturesEnum.AREA_VISITORS) != -1,
        },*/
      ],
    },
    {
      title: tr('Layout.supplierNotification', 'Supplier notifications'),
      icon: <SvgMenuSn />,
      render: hasModule(CompanyModuleEnum.SUPPLIER_NOTIFICATION, null) || hasModule(CompanyModuleEnum.SHIPMENT, null),
      subMenu: [
        {
          title: tr('Layout.new', 'New'),
          url: nav.createNavigationLink(nav.urlFunctions.createSupplierNotificationCreate()),
          render: hasModule(CompanyModuleEnum.SUPPLIER_NOTIFICATION, null),
        },
        {
          title: tr('Layout.newDap', 'Nová DAP'),
          url: nav.createNavigationLink(nav.urlFunctions.createSupplierNotificationDapCreate()),
          render: loggedUser?.features?.indexOf(FeaturesEnum.DAP) != -1,
        },
        {
          title: tr('Layout.listSupplierNotifications', 'Supplier notification list'),
          url: nav.createNavigationLink(nav.urlFunctions.createSupplierNotificationList()),
          render: hasModule(CompanyModuleEnum.SUPPLIER_NOTIFICATION, null),
        },
        {
          title: tr('Layout.groupDemands', 'Group demands'),
          url: nav.createNavigationLink(nav.urlFunctions.createMultiDemansList()),
          render: hasModule(CompanyModuleEnum.SUPPLIER_NOTIFICATION, false),
        },
        {
          title: tr('Layout.shipmentsNew', 'Agent notification'),
          url: nav.createNavigationLink(nav.urlFunctions.createShipmentList()),
          render: hasModule(CompanyModuleEnum.SHIPMENT, null),
        },
      ],
    },
    {
      title: 'Reporty',
      icon: <SvgMenuReports />,
      render: loggedUser?.reports?.length > 0,
      subMenu: loggedUser?.reports?.map(t => ({
        title: t.reportName,
        url: nav.createNavigationLink(nav.urlFunctions.createReportUrl(t.reportId)),
        render: true,
      })),
    },
    {
      title: tr('Layout.contacts', 'Contacts'),
      icon: <SvgMenuContacts />,
      render: hasModule(CompanyModuleEnum.EXPEDITION, null),
      subMenu: [
        {
          title: tr('Layout.myCarriers', 'My carriers'),
          url: nav.createNavigationLink(nav.urlFunctions.createMyCarrierList()),
          render: true,
        },
        {
          title: tr('Layout.carrierGroups', 'Carrier groups'),
          url: nav.createNavigationLink(nav.urlFunctions.createCarrierGroups()),
          render: true,
        },
        {
          title: tr('Layout.carrierCatalog', 'Catalog'),
          url: nav.createNavigationLink(nav.urlFunctions.createCarrierCatalogList()),
          render: true,
        },
        {
          title: tr('Layout.partners', 'Partners'),
          url: nav.createNavigationLink(nav.urlFunctions.createPartnerList()),
          render: hasPermission([PermissionTypesEnum.PERMISSION_PARTNERS]),
        },
      ],
    },
    {
      title: tr('Layout.settings', 'Settings'),
      icon: <SvgMenuSettings />,
      render: true,
      subMenu: [
        {
          title: tr('Layout.locations', 'Lokality'),
          url: nav.createNavigationLink(nav.urlFunctions.createLocationsList()),
          render: hasPermission([PermissionTypesEnum.PERMISSION_LOCATIONS]),
        },
        /*{ title: tr("Layout.companySettings", "Company settings") },*/
        {
          title: tr('AppTopbar.companyProfile', 'Company profile'),
          url: nav.createNavigationLink(nav.urlFunctions.createCompanyProfile()),
          render: isCustomerAdmin() || isCarrierAdmin() || isCompanyAdmin(),
        },
        {
          title: tr('AppTopbar.inheritedCompanies', 'Inherited companies'),
          url: nav.createNavigationLink(nav.urlFunctions.createIhneritedCompanies()),
          render: isCustomerAdmin() || isCarrierAdmin() || isCompanyAdmin(),
        },
        {
          title: tr('AppTopbar.companyNotifications', 'Námi rozeslaná upozornění'),
          url: nav.createNavigationLink(nav.urlFunctions.createCompanyNotificationList()),
          render: showNotificationAdmin === true,
        },
        {
          title: tr('Layout.exceptions', 'Exceptions'),
          url: nav.createNavigationLink(nav.urlFunctions.createExceptionList()),
          render: hasPermission([PermissionTypesEnum.PERMISSION_EXCEPTIONS]),
        },
      ],
    },
  ] as MenuItemModel[];

  const languages = [
    { name: tr(`AppTopbar.czech`, `Czech`), code: 'cs', countryCode: 'cz' },
    { name: tr('AppTopbar.english', 'English'), code: 'en', countryCode: 'gb' },
    { name: tr('AppTopbar.german', 'Deutsch'), code: 'de', countryCode: 'de' },
    {
      name: tr('AppTopbar.hungarian', 'Hungrarian'),
      code: 'hu',
      countryCode: 'hu',
    },
    { name: tr('AppTopbar.poland', 'Polish'), code: 'pl', countryCode: 'pl' },
    { name: tr('AppTopbar.french', 'French'), code: 'fr', countryCode: 'fr' },
  ];

  const changeLanguage = (selectedLocale: string) => {
    //i18n.changeLanguage(selectedLocale);
    window.location.href = `/${selectedLocale}/`;
  };

  const displaySelectedLanguage = (
    languages: {
      name: string;
      code: string;
      countryCode: string;
    }[],
    selectedLanguage: string,
  ) => {
    return {
      label: languages.find(l => l.code === selectedLanguage)?.name,
      icon: languages.find(l => l.code === selectedLanguage)?.countryCode,
    };
  };

  const selectedLang = displaySelectedLanguage(languages, i18n.language);

  const userMenu = [
    {
      title: selectedLang.label,
      icon: <img src={`/images/flags/${selectedLang.icon}.svg`} alt={selectedLang.label} width={24} />,
      mode: 'open-outside-bottom',
      render: true,
      subMenu: languages.map(
        lang =>
          ({
            title: (
              <div className={'flex flex-row gap-4'}>
                <img src={`/images/flags/${lang.countryCode}.svg`} alt={selectedLang.label} width={24} />
                <div>{lang.name}</div>
              </div>
            ),
            onClick: () => {
              changeLanguage(lang.code);
            },
            active: false,
            render: true,
          } as SubMenuItemModel),
      ),
    },
    {
      title: (
        <div>
          <div>
            {loggedUser?.firstName} {loggedUser?.lastName}
          </div>
          <Note>{loggedUser?.company}</Note>
        </div>
      ),
      icon: <SvgMenuUser />,
      mode: 'open-outside-bottom',
      render: true,
      subMenu: [
        {
          title: tr('Layout.profile', 'Profile'),
          url: nav.createNavigationLink(nav.urlFunctions.createMyProfile()),
          render: true,
        },
        {
          title: tr('Layout.logout', 'Logout'),
          onClick: () => logout({ logoutParams: { returnTo: window.location.origin } }),
          render: true,
        },
      ],
    },
  ] as MenuItemModel[];

  if (isNullOrUndefined(loggedUser)) <></>;

  return (
    <>
      {title && <title>{title}</title>}
      <TopInformation />
      <div className={twMerge('h-screen flex flex-row bg-base-tertiary min-w-[1280px]', clsx({ 'max-w-[1600px]': removeWidthLimt === false }))}>
        <aside
          className={twMerge(
            clsx('min-h-screen justify-between flex flex-col w-[270px] duration-150 bg-white shrink-0', {
              'w-[80px]': smallMenu && !lockedMenu,
            }),
          )}
          onMouseOut={() => {
            setSmallMenu(true);
          }}
          onMouseOver={() => {
            if (!visible) setSmallMenu(false);
          }}
        >
          <div className={'flex flex-col grow  overflow-auto'}>
            <MenuHeader smallMenu={smallMenu} lockedMenu={lockedMenu} setLockedMenu={setLockedMenu} />
            <div className={'flex flex-col grow overflow-auto'}>
              <Menu menu={appMenu} smallMenu={smallMenu} lockedMenu={lockedMenu} localStorageKey={'Layout.menu1'} />
            </div>
          </div>
          <div className={'mb-5'}>
            <ul>
              <NotificationMenuItem smallMenu={smallMenu} lockedMenu={lockedMenu} />
              {showMessagedFromOwners === true && <CustomerNotificationMenuItem smallMenu={smallMenu} lockedMenu={lockedMenu} onClick={() => setVisible(true)} />}
            </ul>
            <hr className={'bg-base-base h-[1px] border-0'} />

            <Menu menu={userMenu} smallMenu={smallMenu} lockedMenu={lockedMenu} localStorageKey={'Layout.menu2'} />
            <Customer2InheritorNotificationSidebar setVisible={v => setVisible(v)} visible={visible} />
          </div>
        </aside>
        <main className={'flex-1 flex overflow-hidden w-full mx-4  '}>
          <div className={'flex-1 overflow-y-scroll pl-0.5 pb-0.5 pr-0.5 gap-2 flex-col'}>{children}</div>
        </main>
      </div>
    </>
  );
};

export default Layout;

/// https://api.whatsapp.com/send/?phone=15556256047&text=mkt
