import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import Tab from '@components/framework/tabs/Tab.tsx';
import SvgTabInternalInfo from '@components/ringil3/icons/TabInternalInfo.tsx';
import SvgTabAttachments from '@components/ringil3/icons/TabAttachments.tsx';
import SvgTabMessages from '@components/ringil3/icons/TabMessages.tsx';
import SvgTabExpedition from '@components/ringil3/icons/TabExpedition.tsx';
import SvgGlobe from '@icons/Globe.tsx';
import SvgIconFile from '@components/framework/icons/IconFile.tsx';

interface ComponentProps {
  onMessagesClick: () => void;
  onPaneChange?: (pane: RingilExpeditionTab) => void;
  showCustoms?: boolean;
  showCmr?: boolean;
}

export type RingilExpeditionTab = 'expedition' | 'internalInfoAndHistory' | 'attachments' | 'customs' | 'cmr';

export const useCreateRingilExpeditionTabs = () => {
  const [selectedPanel, setSelectedPanel] = useState<RingilExpeditionTab>('expedition');

  const RingilTabs: React.FC<ComponentProps> = ({ onMessagesClick, showCustoms, showCmr, onPaneChange = () => {} }) => {
    const { tr } = useTranslationLgs();
    return (
      <TabsInner>
        <Tab
          icon={<SvgTabExpedition />}
          label={tr(`RingilTabs.expedition`, `Expedition`)}
          active={selectedPanel === 'expedition'}
          onClick={() => {
            setSelectedPanel('expedition');
            onPaneChange('expedition');
          }}
        ></Tab>
        <Tab
          icon={<SvgTabInternalInfo />}
          label={tr(`RingilTabs.internalInfo`, `Internal info and history`)}
          active={selectedPanel === 'internalInfoAndHistory'}
          onClick={() => {
            setSelectedPanel('internalInfoAndHistory');
            onPaneChange('internalInfoAndHistory');
          }}
        ></Tab>
        {showCustoms && (
          <Tab
            icon={<SvgGlobe />}
            label={tr(`RingilTabs.customs`, `Clení`)}
            active={selectedPanel === 'customs'}
            onClick={() => {
              setSelectedPanel('customs');
              onPaneChange('customs');
            }}
          ></Tab>
        )}

        {showCmr === true && (
          <Tab
            icon={<SvgIconFile />}
            label={tr(`RingilTabs.cmr`, `CMR`)}
            active={selectedPanel === 'cmr'}
            onClick={() => {
              setSelectedPanel('cmr');
              onPaneChange('cmr');
            }}
          ></Tab>
        )}

        <Tab
          icon={<SvgTabAttachments />}
          label={tr(`RingilTabs.attachments`, `Attachments`)}
          active={selectedPanel === 'attachments'}
          onClick={() => {
            setSelectedPanel('attachments');
            onPaneChange('attachments');
          }}
        ></Tab>
        <Tab icon={<SvgTabMessages />} label={tr(`RingilTabs.messages`, `Messages`)} active={false} onClick={() => onMessagesClick()}></Tab>
      </TabsInner>
    );
  };
  return { RingilTabs, selectedPanel };
};

const TabsInner = styled.div`
  display: flex;
  margin: 1.5rem 0 0.5rem 0;
  gap: 0.5rem;
  border-bottom: 2px solid var(--ringil3-brand-color);
  position: sticky;
  top: 0;
  background-color: var(--ringil3-base-tertiary-color);
`;
