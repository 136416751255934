import React, { useContext, useEffect } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card } from 'primereact/card';
import { ButtonRow, RingilH3, Row, RowWithIcon } from '@components/styles';
import useForm, { proxiedPropertiesOf } from '@hooks/useForm/useForm';
import * as yup from 'yup';
import { AccessTypeEnum, EntityTypeEnum, ShipmentItemInDTO, ShipmentOrderInDTO, TransportationTypeEnum, WeightModeTypeOrderEnum } from '@api/logsteo-api.v2';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import produce from 'immer';
import { mapToAPIDateTime } from '@utils/date';
import dayjs from 'dayjs';
import { ApiContext } from '@api/api';
import { findValidationMessage } from '@utils/validation';
import { ShipmentPartiesData, ShipmentPartiesForm } from './forms/ShipmentPartiesForm';
import { CUSTOMIZATION_MODE_DISABLED, CUSTOMIZATION_MODE_OPTIONAL, CUSTOMIZATION_MODE_REQUIRED } from '@utils/exports';
import useNavigation from '@hooks/useNavigation';
import { AttachmentDocument } from '@components/ringil3/Features/Attachments/UploadAttachmentDialog.tsx';
import { CargoType, CargoTypeComponent } from '@components/obsolete/Form/CargoType/CargoType.tsx';
import { ContainerInfo, ContainerInfoForm } from '@components/obsolete/ContainerInfo/ContainerInfo.tsx';
import { Order, OrderItem } from '@components/obsolete/Orders/types.ts';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import BreadCrumbComponent, { BreadCrumbItemModel } from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import { useButtonEnablers } from '@components/obsolete/Buttons/ButtonEnablers/ButtonEnablers.tsx';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent.tsx';
import { isEntityOwner } from '@app/pages/customer/supplier-notification/utils.ts';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import { CustomsTypeComponent } from '@components/obsolete/CloComponent/CloComponent.tsx';
import { ClearButton } from '@components/obsolete/Buttons/ClearButton/ClearButton.tsx';
import { OrderComponent } from '@components/obsolete/Orders/Orders.tsx';
import { SaveButton } from '@components/obsolete/Buttons/SaveButton/SaveButton.tsx';
import { CargoTypeCodeEnum } from '@utils/constants.ts';

interface ComponentProps {}

interface FormData extends ShipmentPartiesData {
  attachments: AttachmentDocument[];
  cargoType: CargoType;
  containerInfo: ContainerInfoForm;
  customsTypeCode: string;
  referralCode: string;
  shipmentId: string;
  orders: Order[];
  companyCode?: string;
}

const emptyOrder = (): Order => {
  return {
    name: '',
    items: [{ quantityUnit: 'KS' }],
    currencyOrderValue: { currencyCode: 'CZK', amount: null },
    weight: {
      value: null,
      weightModeTypeOrder: WeightModeTypeOrderEnum.SUMMED,
    },
    cargoAmount: null,
    cargoDescription: '',
  };
};

const initialData: FormData = {
  attachments: [],
  transportationTypeCode: TransportationTypeEnum.GROUND,
  cargoType: { cargoTypeCode: CargoTypeCodeEnum.COMMON_GOODS },
  containerInfo: null,
  customsTypeCode: null,
  referralCode: null,
  shipmentId: null,

  orders: [emptyOrder()],
  companyId: null,
  supplierId: null,
  loadingAddress: {
    locationName: null,
    city: null,
    locationId: null,
    country: null,
    streetNr: null,
    zipCode: null,
  },
  unloadingAddress: {
    locationName: null,
    city: null,
    locationId: null,
    country: null,
    streetNr: null,
    zipCode: null,
  },
  arrivalAt: null,
  departureAt: null,
};

enum TTEnums {
  CONTAINER_SPEC_CODE = 'CONTAINER_SPEC_CODE',
  REFERRAL_CODE = 'REFERRAL_CODE',
  SHIPMENT_CODE = 'SHIPMENT_CODE',
}
const itemValidation = yup.object().shape({
  cargoItemType: yup.object().required(),
  quantity: yup.number().required(),
  length: yup.number().required(),
  width: yup.number().required(),
  height: yup.number().required(),
  weight: yup.number().required(),
});

const orderValidation = yup.object().shape({
  name: yup.string().required(),
  cargoAmount: yup.number().required(),
  items: yup.array().of(itemValidation),
  currencyOrderValue: yup.object().test('required', (value, b) => {
    const res = parseFloat(value.amount);
    return res != null && res >= 0;
  }),
});

const validationSchema = yup.object().shape({
  referralCode: yup.string().required(),
  companyId: yup.string().required(),
  supplierId: yup.string().required(),
  departureAt: yup.date().required(),
  arrivalAt: yup.date().required(),
  customsTypeCode: yup.string().required(),
  orders: yup.array().of(orderValidation),
  loadingAddress: yup.object().shape({
    city: yup.string().required(),
  }),
  unloadingAddress: yup.object().shape({
    city: yup.string().required(),
  }),
});

const CreateShipment: React.FC<ComponentProps> = () => {
  const { tr } = useTranslationLgs();
  const { createShipment } = useContext(ApiContext);
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const navigation = useNavigation();

  const form = useForm(validationSchema, initialData, values => save(values), false, false);

  const breadcrumbs = (): BreadCrumbItemModel[] => {
    return [
      {
        label: tr(`CreateShipment.newShipment`, `New shipment`),
        url: /*"/shipments/create-shipment"*/ navigation.createNavigationLink(navigation.urlFunctions.createShipmentCreate()),
        disabled: true,
      },
    ];
  };

  const addOrder = () => {
    form.setFieldValue('orders', [...form.values.orders, emptyOrder()]);
  };

  const deleteOrder = (index: number) => {
    form.setFieldValue(
      'orders',
      form.values.orders.filter((_, i) => i !== index),
    );
  };

  const save = (values: FormData) => {
    createShipment(
      {
        transportationType: values.transportationTypeCode,
        attachments: values.attachments.map(t => {
          return {
            attachmentId: t.attachmentId,
            accessType: t.accessType,
            documentType: t.documentType.documentType,
          };
        }),
        cargoType: values.cargoType,
        containerInfo: values.containerInfo,
        customsTypeCode: values.customsTypeCode,
        referralCode: values.referralCode,
        shipmentId: values.shipmentId,
        orders: values.orders.map(mapOders),
        companyId: values.companyId,
        supplierId: values.supplierId,
        loadingAddress: values.loadingAddress,
        unloadingAddress: values.unloadingAddress,
        departureAt: mapToAPIDateTime(dayjs(values.departureAt)),
        arrivalAt: mapToAPIDateTime(dayjs(values.arrivalAt)),
      },
      () => {
        /*router.push('/shipments');*/
        navigation.navigate(navigation.urlFunctions.createShipmentList());
      },
    );
  };

  const mapOders = (order: Order): ShipmentOrderInDTO => {
    return {
      ...order,
      items: order.items.map(mapItems),
      orderValue: order.currencyOrderValue,
      orderAmount: order.cargoAmount,
      orderDescription: order.cargoDescription,
      orderName: order.name,
    };
  };

  const mapItems = (item: OrderItem): ShipmentItemInDTO => {
    return {
      cargoItemTypeCode: item.cargoItemType.code,
      height: item.height,
      length: item.length,
      amount: item.quantity,
      quantityUnitCode: item.quantityUnit,
      weight: item.weight,
      width: item.width,
      stackable: item.stackable,
    };
  };

  const resolveLabel = () => {
    switch (form.values.transportationTypeCode) {
      case TransportationTypeEnum.GROUND:
        return tr('CreateShipment.cmrID', 'CMR ID');
      case TransportationTypeEnum.TRAIN:
        return tr('CreateShipment.blID', 'B/L ID');
      case TransportationTypeEnum.AIR:
        return tr('CreateShipment.awbID', 'AWB ID');
      case TransportationTypeEnum.BOAT:
        return tr('CreateShipment.blID', 'B/L ID');
    }
  };

  const { ButtonEnablersComponent, expandedSections, setExpandedSections, hideSection, showSection, setClosability, isClosable } = useButtonEnablers<TTEnums>(
    [
      {
        sectionCode: TTEnums.REFERRAL_CODE,
        label: tr('CreateShipment.referralCode', 'Referral code'),
        closable: false,
        expanded: true,
      },
      {
        sectionCode: TTEnums.CONTAINER_SPEC_CODE,
        label: tr('CreateShipment.containerSpecification', 'Container specification'),
        closable: true,
        expanded: false,
      },
      {
        sectionCode: TTEnums.SHIPMENT_CODE,
        label: resolveLabel(),
        closable: true,
        expanded: false,
      },
    ],
    (section, visible) => {
      switch (section) {
        case TTEnums.CONTAINER_SPEC_CODE:
          form.setFieldValue('containerInfo', visible ? form.values.containerInfo : null);
          return;
      }
    },
  );

  useEffect(() => {
    switch (form.values.transportationTypeCode) {
      case TransportationTypeEnum.GROUND:
        setClosability(TTEnums.CONTAINER_SPEC_CODE, true);
        return;
      case TransportationTypeEnum.TRAIN:
        showSection(TTEnums.CONTAINER_SPEC_CODE);
        showSection(TTEnums.SHIPMENT_CODE);
        setClosability(TTEnums.CONTAINER_SPEC_CODE, false);
        return;
      case TransportationTypeEnum.AIR:
        setClosability(TTEnums.CONTAINER_SPEC_CODE, true);
        return;
      case TransportationTypeEnum.BOAT:
        showSection(TTEnums.SHIPMENT_CODE);
        showSection(TTEnums.CONTAINER_SPEC_CODE);
        setClosability(TTEnums.CONTAINER_SPEC_CODE, false);
        return;
    }
  }, [form.values.transportationTypeCode]);

  const names = proxiedPropertiesOf<FormData>();

  const changeVisibility = (attachmentId: string, internal: boolean) => {
    form.setFieldValue(
      names.attachments,
      produce(form.values.attachments, draft => {
        const attachment = draft.find(t => t.attachmentId === attachmentId);
        attachment.accessType = internal ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL;
      }),
    );
  };

  const { AttachmentComponent, load: reloadAttachments } = useCreateAttachmentComponent(
    isEntityOwner(loggedUser, form.values.companyId),
    // @ts-ignore - dalsi Vlachova prasarna!!
    form.values.attachments,
  );

  return (
    <>
      <BreadCrumbComponent items={breadcrumbs()} />
      {/*
        // @ts-ignore*/}
      <Card>
        <ShipmentPartiesForm
          // @ts-ignore
          form={form}
          inSidebar={false}
          showParties={true}
          showLocations={true}
          showDates={true}
          showTransportationType={true}
        />

        <hr />
        <RingilH3>{tr(`CreateShipment.cargoInfo`, `Cargo info`)}</RingilH3>
        <CargoTypeComponent value={form.values.cargoType} onChange={v => form.setFieldValue('cargoType', v)} inSidebar={false} />
        <RR>
          <RC large={3} medium={2} small={1}>
            <HorizontalFieldValue
              required={true}
              validationError={findValidationMessage(form.validationErrors, 'customsTypeCode', tr)}
              label={tr('CreateShipment.customsType', 'Customs type')}
              value={<CustomsTypeComponent value={form.values.customsTypeCode} onChange={v => form.setFieldValue('customsTypeCode', v)} />}
            />
          </RC>
        </RR>
        {expandedSections.find(t => t.sectionCode === TTEnums.SHIPMENT_CODE).expanded && (
          <RR>
            <RC large={3} medium={2} small={1}>
              <HorizontalFieldValue
                label={resolveLabel()}
                required={true}
                value={
                  <RowWithIcon>
                    <InputText value={form.values.shipmentId} onChange={v => form.setFieldValue('shipmentId', v.target.value)} style={{ width: '100%' }} />
                    {isClosable(TTEnums.SHIPMENT_CODE) && <ClearButton onClick={() => hideSection(TTEnums.SHIPMENT_CODE)} />}
                  </RowWithIcon>
                }
              />
            </RC>
          </RR>
        )}
        {expandedSections.find(t => t.sectionCode === TTEnums.CONTAINER_SPEC_CODE).expanded && (
          <ContainerInfo
            containerInfo={form.values.containerInfo}
            onChange={v => form.setFieldValue('containerInfo', v)}
            onClear={() => {
              hideSection(TTEnums.CONTAINER_SPEC_CODE);
            }}
            clearable={isClosable(TTEnums.CONTAINER_SPEC_CODE)}
            inSidebar={false}
            validationErrors={form.validationErrors}
          />
        )}
        {expandedSections.find(t => t.sectionCode === TTEnums.REFERRAL_CODE).expanded && (
          <RR>
            <RC large={3} medium={2} small={1}>
              <HorizontalFieldValue
                required={true}
                validationError={findValidationMessage(form.validationErrors, 'referralCode', tr)}
                label={tr('CreateShipment.referralCode', 'Referral code')}
                value={
                  <RowWithIcon>
                    <InputText value={form.values.referralCode} onChange={v => form.setFieldValue('referralCode', v.target.value)} style={{ width: '100%' }} />
                  </RowWithIcon>
                }
              />
            </RC>
          </RR>
        )}
        <ButtonEnablersComponent></ButtonEnablersComponent>
        <RingilH3>{tr(`CreateShipment.orders`, `Orders`)}</RingilH3>
        {form.values.orders.map((order, orderKey) => {
          return (
            <OrderComponent
              inSidebar={false}
              key={orderKey}
              order={order}
              onChange={o => {
                const newOrders = produce(form.values.orders, draft => {
                  draft[orderKey] = o;
                });
                form.setFieldValue('orders', newOrders);
              }}
              canBeDeleted={form.values.orders.length > 1}
              onDelete={() => deleteOrder(orderKey)}
              showStackable={false}
              validationErrors={form.validationErrors}
              orderIndex={orderKey}
              companyCode={form.values.companyCode}
              selectedSupplierId={null}
              customerId={null}
              showExternalOrders={false}
              orderValueMode={CUSTOMIZATION_MODE_REQUIRED}
              manipulationUnitNoteMode={CUSTOMIZATION_MODE_DISABLED}
              amountOfOrderMode={CUSTOMIZATION_MODE_REQUIRED}
            />
          );
        })}
        <Row>
          <Button label={tr('CreateShipment.addOrder', '+ Add order')} className={'p-button-outlined'} onClick={e => addOrder()} />
        </Row>
        <AttachmentComponent
          onAddAttachment={attachments => {
            form.setFieldValue('attachments', [...form.values.attachments, ...attachments]);
          }}
          onDeletedAttachment={attachmentId => {
            form.setFieldValue(
              'attachments',
              form.values.attachments.filter(t => t.attachmentId !== attachmentId),
            );
          }}
          entityType={EntityTypeEnum.SHIPMENT}
          entityId={null}
          onChangeVisibility={changeVisibility}
        />
        <ButtonRow>
          <SaveButton onClick={e => form.validateAndSend()} />
        </ButtonRow>
      </Card>
    </>
  );
};

export default CreateShipment;
