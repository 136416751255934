import React, { useContext, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import { CreateCustomsInvoicesRequestInDTO } from '@api/logsteo-api.v2.tsx';
import * as yup from 'yup';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon.tsx';
import { HorizontalLine } from '@components/ringil3/styles.tsx';
import { InputText } from 'primereact/inputtext';
import { Field } from '@components/framework/formfield/Field.tsx';
import InputPriceWithCurrency from '@components/framework/input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import InputPlus from '@components/framework/input/InputPlus/InputPlus.tsx';
import RingilTextArea from '@components/framework/input/RingilTextArea.tsx';

interface ComponentProps {
  entityType: 'EXPEDITION' | 'SUPPLIER_NOTIFICATION';
  entityId: string;
  applicationId: string;
  onCompleted: () => void;
}

export const useCreateAddCustomsInvoiceSidebar = () => {
  const [visible, setVisible] = useState(false);

  const AddCustomsInvoiceSidebar: React.FC<ComponentProps> = ({ entityType, entityId, applicationId, onCompleted }) => {
    const { createExpeditionCustomsInvoice, createSNCustomsInvoice } = useContext(ApiContext);
    const { tr } = useTranslationLgs();

    const form = useForm<CreateCustomsInvoicesRequestInDTO>(
      (formData) =>
      yup.object().shape({
        invoiceNumber: yup.string().required(),
        price:
        isNotNullOrUndefined(formData.price) && isNotNullOrUndefined(formData.price.price) ?
        yup.object().shape({
          price: yup.number().required(),
          currency: yup.string().required()
        }) :
        yup.mixed().nullable()
      }),
      {
        invoiceNumber: null,
        invoiceNote: null,
        price: null
      },
      (d) => save(d),
      false,
      false
    );

    const save = (data: CreateCustomsInvoicesRequestInDTO) => {
      if (entityType === 'EXPEDITION') {
        createExpeditionCustomsInvoice(entityId, data, () => {
          setVisible(false);
          onCompleted();
        });
      } else {
        createSNCustomsInvoice(entityId, data, () => {
          setVisible(false);
          onCompleted();
        });
      }
    };

    return (
      <>
        <RingilSidebar
          visible={visible}
          heading={tr("AddCustomsInvoiceSidebar.fakturaProCleni", "Faktura pro clení")}
          onBack={() => setVisible(false)}
          onContinue={() => {
            form.validateAndSend();
          }}>

          <div className={'flex flex-col gap-2'}>
            {entityType == 'EXPEDITION' &&
            <VerticalFieldAndValueWithIcon label={tr("AddCustomsInvoiceSidebar.proPrepravu", "Pro přepravu")} valueText={applicationId} iconPath={'/images/icons/ringil3/forExpedition.svg'} />
            }

            {entityType === 'SUPPLIER_NOTIFICATION' &&
            <VerticalFieldAndValueWithIcon label={tr("AddCustomsInvoiceSidebar.proAvizaci", "Pro avizaci")} valueText={applicationId} iconPath={'/images/icons/ringil3/forExpedition.svg'} />
            }
            <HorizontalLine variant={'tertiary'} />
            <Field label={tr("AddCustomsInvoiceSidebar.cisloFaktury", "Číslo faktury")} errorMessage={findValidationMessage(form.validationErrors, 'invoiceNumber', tr)}>
              <InputText
                value={form.values.invoiceNumber || ''}
                onChange={(e) =>
                form.useProduce((d) => {
                  d.invoiceNumber = e.target.value;
                })
                } />

            </Field>
            <Field label={tr("AddCustomsInvoiceSidebar.value", "Value")} errorMessage={findValidationMessage(form.validationErrors, 'price.price', tr)}>
              <InputPriceWithCurrency
                price={form.values.price?.price}
                currency={form.values.price?.currency}
                changePrice={(v) =>
                form.useProduce((d) => {
                  if (isNullOrUndefined(d.price)) d.price = { price: null, currency: 'CZK' };
                  d.price.price = v;
                  return d;
                })
                }
                changeCurrency={(v) =>
                form.useProduce((d) => {
                  if (isNullOrUndefined(d.price)) d.price = { price: null, currency: 'CZK' };
                  d.price.currency = v;
                  return d;
                })
                } />

            </Field>
            <InputPlus title={tr("AddCustomsInvoiceSidebar.poznamka", "Poznámka")}>
              <RingilTextArea
                value={form.values.invoiceNote || ''}
                onChange={(v) =>
                form.useProduce((d) => {
                  d.invoiceNote = v;
                })
                }
                maxLength={255} />

            </InputPlus>
          </div>
        </RingilSidebar>
      </>);

  };

  return { AddCustomsInvoiceSidebar, visible, setVisible };
};