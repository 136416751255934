import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Column } from 'primereact/column';
import useNavigation from '@hooks/useNavigation';
import { CMRDocumentResponse, GetExpeditionCMRResponse } from '@api/logsteo-api.v2';
import { mapFromAPIToDateLong } from '@utils/date';
import { Link } from 'react-router-dom';
import { Clickable } from '@components/ringil3/styles.tsx';
import { Note } from '@components/styles.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';
import { useCreateTable } from '@components/ringil3/RingilDataTable/DataTable.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';

interface ComponentProps {
  expeditionId: string;
}

const CMRTableTab: React.FC<ComponentProps> = ({ expeditionId }) => {
  const navigation = useNavigation();
  const { getExpeditionCMR, createCMRFromExpedition, generateCMRPDF } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [data, setData] = useState<GetExpeditionCMRResponse>();

  const { Table, Column } = useCreateTable<CMRDocumentResponse>();

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    getExpeditionCMR(expeditionId, (d) => {
      setData(d);
    });
  };

  const regenerateCMRs = () => {
    createCMRFromExpedition(expeditionId, () => {
      reload();
    });
  };

  const downloadFile = (cmrId: string) => {
    generateCMRPDF(cmrId, (data) => {
      // @ts-ignore
      const linkSource = `data:${data.mimeType};base64,${data.data}`;
      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = data.fileName;
      downloadLink.click();
    });
  };

  const renderId = (row: CMRDocumentResponse) => {
    return (
      <div>
        <Link to={navigation.createNavigationLink(navigation.urlFunctions.createCMRDetail(expeditionId))}>
          <Clickable>{row.applicationId} </Clickable>
        </Link>
      </div>);

  };
  const renderCreatedBy = (row: CMRDocumentResponse) => {
    return (
      <>
        <div>{row.createdByFullName}</div>
        <Note>{row.createdByCompanyName}</Note>
      </>);

  };
  const renderCreatedAt = (row: CMRDocumentResponse) => {
    return <div>{mapFromAPIToDateLong(row.createdDate)}</div>;
  };
  const renderButtons = (row: CMRDocumentResponse) => {
    return (
      <>
        <RingilButton mode={'rect-small'} label={tr(`CMRTable.download`, `Download`)} onClick={() => downloadFile(row.id)} />
      </>);

  };

  /*emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}*/

  return (
    <>
      <Table value={data?.cmrDocuments}>
        <Column header={tr(`CMRTable.idCmr`, `ID CMR`)} body={renderId}></Column>
        <Column header={tr(`CMRTable.createdBy`, `Created by`)} body={renderCreatedBy}></Column>
        <Column header={tr(`CMRTable.createdAt`, `Created at`)} body={renderCreatedAt}></Column>
        <Column header={tr(`CMRTable.actions`, ``)} body={renderButtons}></Column>
      </Table>
      <AddButton type={'SQUARE'} title={tr("CMRTableTab.vygenerovatCmr", "Vygenerovat CMR")} onClick={() => regenerateCMRs()} fullWidth />
    </>);

};

export default CMRTableTab;