import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar';
import { ColWithGap, RowWithGap, RowWithSpaceBetween } from '@components/styles';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import ErrorCountBadge from '@components/ringil3/Badges/ErrorBadge';
import useForm from '@hooks/useForm/useForm';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon';
import { CompanyModuleEnum, ComputeReservationDurationConfiguration, FreeSlotsWrapperOutDTO, TimeslotCalendarDTO } from '@api/logsteo-api.v2';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { ClickableCursor, HorizontalLine, TwoColGrid } from '@components/ringil3/styles';
import { findValidationMessage } from '@utils/validation';
import InputCompanyRamp from '@components/ringil3/Input/InputCompanyRamp/InputCompanyRamp';
import InputTextWithSuffix from '@components/ringil3/Input/InputTextWithSuffix/InputTextWithSuffix';
import InputHourManual from '@components/ringil3/Input/InputHour/InputHourManual';
import { deepEqual, dumpVars, isNotNullOrUndefined, parseNumberOrUndefined } from '@utils/utils';
import LinkWithIcon from '@components/ringil3/Links/LinkWithIcon';
import SvgAvailableDates from '@components/ringil3/icons/reservation/AvailableDates';
import Panel from '@components/ringil3/Panel/Panel';
import SvgClose from '@components/ringil3/icons/panel/Close';
import COSelect from '@components/ringil3/COSelect/COSelect';
import { createZonedDateTime, mapFromAPIDateTime, mapFromAPIToTime, mapToAPIDateTime, toTime } from '@utils/date';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage';
import SvgRefresh from '@components/ringil3/icons/Refresh';
import { ProgressSpinner } from 'primereact/progressspinner';
import dayjs from 'dayjs';
import InputCompanyLocation from '@components/obsolete/Form/InputEntityOwner/InputCompanyLocation.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';

interface ComponentProps {
  reservationId: string;
  applicationId: string;
  companyLocationCustomerId: string;
  onRefresh: () => void;
  expeditionLocationId: string;
}

interface Form {
  companyLocationId: string;
  rampId: string;
  localDate: string;
  durationInMin: string;
  arrivalHourString: string;
}

export const useCreateEditReservationSlotSidebar = () => {
  const [visible, setVisible] = useState(false);

  const EditReservationSlotSidebar: React.FC<ComponentProps> = ({ reservationId, applicationId, companyLocationCustomerId, onRefresh, expeditionLocationId }) => {
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const [apiRequest, setApiRequest] = useState(false);
    const { getReservationDetail, updateReservationTimeslot, checkReservation, getFreeSlotsForLocation, getCreateEditReservationSettings } = useContext(ApiContext);
    const [slots, setSlots] = useState<FreeSlotsWrapperOutDTO[]>();
    const [originalValues, setOriginalValues] = useState<Omit<Form, 'companyLocationId'>>();
    const [settings, setSettings] = useState<ComputeReservationDurationConfiguration | null>(null);
    const [reservationCompanyOwnerId, setReservationCompanyOwnerId] = useState<string>();
    const { loggedUser } = useContext(AuthenticatedUserContext);

    useEffect(() => {
      getCreateEditReservationSettings(reservationCompanyOwnerId, (d) => {
        setSettings(d);
      });
    }, [reservationCompanyOwnerId]);

    useEffect(() => {
      const ac = new AbortController();

      getReservationDetail(
        reservationId,
        (d) => {
          if (ac.signal.aborted) return;

          setReservationCompanyOwnerId(d.customerId);
          form.overwriteValues({
            localDate: d.slot?.localDate ? d.slot?.localDate : dayjs().format('YYYY-MM-DD'),
            rampId: d.rampId,
            arrivalHourString: d.slot?.sinceHourString,
            durationInMin: d.slot?.durationInMin ? `${d.slot?.durationInMin}` : '30',
            companyLocationId: d?.locationId
          });
          setOriginalValues({
            localDate: d.slot?.localDate ? d.slot?.localDate : dayjs().format('YYYY-MM-DD'),
            rampId: d.rampId,
            arrivalHourString: d.slot?.sinceHourString,
            durationInMin: d.slot?.durationInMin ? `${d.slot?.durationInMin}` : '30'
          });
        },
        null,
        { onValidationFailed: setBeValidationErrors }
      );
      return () => {
        ac.abort();
      };
    }, [reservationId]);

    const form = useForm<Form>(
      yup.object().shape({
        rampId: yup.string().required(),
        durationInMin: yup.number().required(),
        localDate: yup.string().required(),
        companyLocationId: yup.string().required(),
        arrivalHourString: yup.string().required()
      }),
      null,
      (d) => save(d),
      false,
      false,
      null,
      'scroll'
    );

    const save = (data: Form) => {
      updateReservationTimeslot(
        reservationId,
        {
          rampId: data.rampId,
          localDate: data.localDate,
          sinceHourString: data.arrivalHourString,
          durationInMinutes: parseInt(data.durationInMin)
        },
        () => {
          setVisible(false);
          onRefresh();
        },
        null,
        { onValidationFailed: setBeValidationErrors }
      );
    };

    const offerAvailableSlots = () => {
      if (form.values.localDate && form.values.durationInMin && form.values.companyLocationId) {
        getFreeSlotsForLocation(form.values.localDate, parseInt(form.values.durationInMin), form.values.companyLocationId, [], (d) => {
          setSlots(d);
        });
      }
    };

    const applySlot = (slot: TimeslotCalendarDTO, rampId: string) => {
      form.setFieldValue(form.names.arrivalHourString, toTime(mapFromAPIDateTime(slot.since)));
      form.setFieldValue(form.names.rampId, rampId);
      setSlots(undefined);
    };

    const [reservationValid, setReservationValid] = useState(undefined);

    useEffect(() => {
      const ab = new AbortController();
      if (form.values?.rampId && form.values?.durationInMin && form.values?.arrivalHourString && form.values?.localDate) {
        setStateForDeffered({
          rampId: form.values.rampId,
          durationInMin: form.values.durationInMin,
          arrivalHourString: form.values.arrivalHourString,
          localDate: form.values.localDate
        });
      }

      setReservationValid(undefined);
      return () => {
        ab.abort();
      };
    }, [form.values?.arrivalHourString, form.values?.durationInMin, form.values?.rampId, form.values?.localDate]);

    const [stateForDeffered, setStateForDeffered] = useState(undefined);
    const [debouncedValue] = useDebounce(stateForDeffered, 700);

    useEffect(() => {
      checkReservationHandler();
    }, [debouncedValue]);

    const checkReservationHandler = () => {
      if (!deepEqual(debouncedValue, originalValues)) {
        if (isNotNullOrUndefined(debouncedValue)) {
          setApiRequest(true);
          checkReservation(
            [reservationId],
            {
              customerId: companyLocationCustomerId,
              timeslot: {
                since: mapToAPIDateTime(createZonedDateTime(debouncedValue.localDate, debouncedValue.arrivalHourString, 'Europe/Prague')),
                till: mapToAPIDateTime(
                  createZonedDateTime(debouncedValue.localDate, debouncedValue.arrivalHourString, 'Europe/Prague').add(debouncedValue.durationInMin, 'minute')
                )
              },
              rampId: debouncedValue.rampId,
              locationId: form.values.companyLocationId,
              labelIds: [],
              linkToExpeditionLocationId: expeditionLocationId,
              openGate: false
            },
            () => {
              setApiRequest(false);
              setReservationValid(true);
            },
            null,
            {
              onError: (e) => {
                setApiRequest(false);
                setReservationValid(false);
              },
              onValidationFailed: (v) => {
                setApiRequest(false);
                setReservationValid(false);
              }
            }
          );
        }
      } else {
        setReservationValid(true);
      }
    };

    const isOwner = loggedUser?.companyId == reservationCompanyOwnerId;
    const isEditDurationDisabled = isOwner ? settings?.editDuration == false : settings?.editDurationCounterParty == false;

    return (
      <>
        <RingilSidebar
          visible={visible}
          onBack={() => setVisible(false)}
          heading={
          <RowWithSpaceBetween>
              <div>{tr('EditReservationSlot.editReservationSlot', 'Edit reservation slot')}</div>
              <ErrorCountBadge errorCount={new Set(form.validationErrors.map((t) => t.path)).size} />
            </RowWithSpaceBetween>
          }
          onContinue={() => {
            form.validateAndSend();
          }}>

          {isNotNullOrUndefined(form.values) &&
          <>
              <BackendValidationComponent />
              <ColWithGap>
                <VerticalFieldAndValueWithIcon
                label={tr(`EditReservationSlotSidebar.forReservation`, `For reservation`)}
                valueText={applicationId}
                iconPath={'/images/icons/ringil3/forExpedition.svg'} />

                <HorizontalLine variant={'tertiary'} />
                <VerticalFieldAndValue
                label={tr("EditReservationSlot.location", "Location")}
                labelMode={'normal'}
                readOnly={false}
                required={true}
                errorMessage={findValidationMessage(form.validationErrors, form.names.companyLocationId, tr)}>

                  <InputCompanyLocation
                  companyId={companyLocationCustomerId}
                  companyLocationId={form.values.companyLocationId}
                  onChange={(v) => {
                    form.setValues({
                      companyLocationId: v.id,
                      rampId: null
                    });
                  }}
                  moduleName={CompanyModuleEnum.TIMESLOTS}
                  disabled={false}
                  showOnlyWithRamp={true} />

                </VerticalFieldAndValue>
                <HorizontalLine variant={'tertiary'} />
                <COHeadline variant={'h3'} title={tr(`EditReservationSlotSidebar.plan`, `Plan`)}></COHeadline>
                <VerticalFieldAndValue
                label={tr(`EditReservationSlotSidebar.localDate`, `Date`)}
                labelMode={'normal'}
                required={true}
                errorMessage={findValidationMessage(form.validationErrors, form.names.localDate, tr)}>

                  <input
                  style={{
                    width: '100%',
                    padding: '0.5rem',
                    color: 'var(--ringil3-gr4)'
                  }}
                  type="date"
                  onChange={(e) => {
                    form.setFieldValue(form.names.localDate, e.target.value);
                  }}
                  value={form.values.localDate} />

                </VerticalFieldAndValue>
                <LinkWithIcon
                icon={<SvgAvailableDates />}
                label={tr(`CreateTimeslotSidebar.offerAvailableSlots`, `Offer available slots`)}
                onClick={() => {
                  offerAvailableSlots();
                }} />

                {slots &&
              <Panel onClose={() => setSlots(undefined)} headingText={tr('CreateTimeslotSidebar.availableSlots', 'Available slots')} closeIcon={<SvgClose />}>
                    <ColWithGap>
                      {slots[0].freeSlots.map((s, i) => {
                    return (
                      <VerticalFieldAndValue label={s.name} labelMode={'normal'} key={i}>
                            <SlotsGrid>
                              {s.slots.map((slot, j) => {
                            return (
                              <ClickableCursor onClick={() => applySlot(slot, s.rampId)}>
                                    <COSelect text={`${mapFromAPIToTime(slot.since)} - ${mapFromAPIToTime(slot.till)}`} key={j} />
                                  </ClickableCursor>);

                          })}
                            </SlotsGrid>
                          </VerticalFieldAndValue>);

                  })}
                    </ColWithGap>
                  </Panel>
              }
                <TwoColGrid>
                  <VerticalFieldAndValue
                  label={tr(`EditReservationSlotSidebar.ramp`, `Ramp`)}
                  labelMode={'normal'}
                  required={true}
                  errorMessage={findValidationMessage(form.validationErrors, form.names.rampId, tr)}>

                    <InputCompanyRamp
                    value={form.values.rampId}
                    onChange={(v) => form.setFieldValue(form.names.rampId, v)}
                    companyLocationId={form.values.companyLocationId}
                    companyId={companyLocationCustomerId} />

                  </VerticalFieldAndValue>
                  <VerticalFieldAndValue
                  label={tr(`EditReservationSlotSidebar.duration`, `Duration`)}
                  labelMode={'normal'}
                  required={true}
                  errorMessage={findValidationMessage(form.validationErrors, form.names.durationInMin, tr)}>

                    <InputTextWithSuffix
                    suffix={'min'}
                    value={form.values.durationInMin}
                    onChange={(v) => form.setFieldValue(form.names.durationInMin, parseNumberOrUndefined(v))}
                    readOnly={isEditDurationDisabled} />

                  </VerticalFieldAndValue>
                </TwoColGrid>

                <VerticalFieldAndValue
                label={tr(`EditReservationSlotSidebar.arrival`, `Arrival`)}
                labelMode={'normal'}
                required={true}
                errorMessage={findValidationMessage(form.validationErrors, form.names.arrivalHourString, tr)}>

                  <InputHourManual value={form.values.arrivalHourString} onChange={(v) => form.setFieldValue(form.names.arrivalHourString, v)} />
                </VerticalFieldAndValue>

                <RowWithGap>
                  <LinkWithIcon
                  icon={<SvgRefresh />}
                  label={tr(`CreateTimeslotSidebar.checkReservation`, `Check reservation`)}
                  onClick={() => {
                    checkReservationHandler();
                  }} />

                  {apiRequest &&
                <>
                      <ProgressSpinner
                    style={{
                      width: '15px',
                      height: '15px',
                      marginRight: '0.5rem',
                      color: 'var(--ringil3-brand-color)'
                    }}
                    strokeWidth="3"
                    animationDuration="1s" />

                    </>
                }
                </RowWithGap>
                {reservationValid &&
              <COInlineMessage variant={'success'}>
                    {tr(`CreateTimeslotSidebar.reservationPossible`, `Reservation is possible in this time and ramp`)}
                  </COInlineMessage>
              }

                {reservationValid == false &&
              <COInlineMessage variant={'error'}>
                    {tr(`CreateTimeslotSidebar.reservationImpossible`, `Reservation is not possible in this time and ramp`)}
                  </COInlineMessage>
              }
              </ColWithGap>
            </>
          }
        </RingilSidebar>
      </>);

  };

  return { EditReservationSlotSidebar, setVisible, visible };
};

const SlotsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;