import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import classNames from 'clsx';
import { CreateTemplateHandlerContext } from '../CreateTemplateHandler';
import { findValidationMessage } from '@utils/validation';
import { OrderViewData } from '@components/obsolete/new-expedition/types.tsx';
import { useTranslation } from 'react-i18next';
import { OrderIdSelector } from '@components/obsolete/common/OrderIdSelector.tsx';
import { SkuSelector } from '@components/obsolete/Form/SkuSelector';
import { QuantitySelector } from '@components/obsolete/Form/QuantitySelector';
import { StackableWrapper } from '@components/obsolete/Stackable/StackableWrapper.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import { Stackable } from '@components/obsolete/Stackable/Stackable.tsx';
import { DimensionsSelector } from '@components/obsolete/Form/DimensionsSelector';
import { WeightSelector } from '@components/obsolete/Form/WeightSelector';
import { NewNote } from '@components/obsolete/NewNote/NewNote.tsx';

interface ComponentProps {
  className?: any;
  locationIndex: number;
  orderIndex: number;
  order: OrderViewData;
}

export const OrdersComponent: React.FC<ComponentProps> = ({ className, order, locationIndex, orderIndex }) => {
  const { t } = useTranslation('common');

  const { state, deleteOrder, changeOrderName, changeOrderItemProperty, deleteLoadingUnit, changeCargoItem } = useContext(CreateTemplateHandlerContext);
  const classes = classNames(className);

  return (
    <>
      <div className={classes}>
        <div className="p-grid p-mb-2 p-formgroup-width-input">
          <OrderIdSelector orderName={order.name} onChangeOrderName={(orderName) => changeOrderName(locationIndex, orderIndex, orderName)} />

          <div className="p-col-12 p-d-flex p-jc-md-end">
            {orderIndex > 0 &&
            <Button
              label={t('loadDetailsPage.buttonDeleteReference')}
              className="p-button-outlined p-button-rounded"
              icon="pi pi-trash"
              onClick={() => deleteOrder(order.internalId)} />

            }
          </div>
        </div>
        {order?.items?.map((loadItem, orderItemIndex) =>
        <div key={orderItemIndex}>
            <div className="p-grid p-flex p-mb-3">
              <SkuSelector
              sku={loadItem?.cargoItemType}
              onChange={(value) => {
                changeCargoItem(locationIndex, orderIndex, orderItemIndex, value);
              }} />

              <QuantitySelector
              qty={loadItem.amount}
              selectedQtyUnitCode={loadItem.quantityUnit}
              onChangeQty={(qty) => {
                changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'amount', qty);
              }}
              onChangeQtyUnit={(value) => {
                changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'quantityUnit', value);
              }}
              qtyError={findValidationMessage(state.validationErrors, `locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].amount`, t)} />

              <StackableWrapper wrapper="p-fluid p-col-12 p-md-3">
                <Label title={t(`OrdersComponent.stackable`, `Stackable`)} required />
                <Stackable
                value={loadItem.stackable}
                onChange={(value) => {
                  changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'stackable', value);
                }} />

              </StackableWrapper>
              <div className="delete-btn-wrapper p-d-flex p-jc-between p-ml-auto p-mb-3">
                {orderItemIndex > 0 &&
              <Button
                icon="pi pi-trash"
                className="p-button-outlined p-button-rounded p-ml-auto"
                onClick={() => {
                  deleteLoadingUnit(locationIndex, orderIndex, orderItemIndex);
                }} />

              }
              </div>
            </div>
            <div className="p-fluid p-formgrid p-grid p-ai-end">
              <DimensionsSelector
              width={loadItem.width}
              height={loadItem.height}
              length={loadItem.length}
              onChangeDimensions={(property, value) => changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, property, value)}
              heightError={findValidationMessage(state.validationErrors, `locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].height`, t)}
              lengthError={findValidationMessage(state.validationErrors, `locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].length`, t)}
              widthError={findValidationMessage(state.validationErrors, `locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].width`, t)}
              disabledInputs={[]} />

              <WeightSelector
              weight={loadItem.weight}
              weightMode={loadItem.weightMode}
              onChangeWeight={(value) => {
                changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'weight', value);
              }}
              onChangeMode={(value) => {
                changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'weightMode', value);
              }}
              weightError={findValidationMessage(state.validationErrors, `locations[${locationIndex}].loadingOrders[${orderIndex}].items[${orderItemIndex}].weight`, t)} />

            </div>

            <div className="p-grid">
              <div className="p-col-12 p-xl-9">
                <NewNote value={loadItem.itemNote} onChange={(value) => changeOrderItemProperty(locationIndex, orderIndex, orderItemIndex, 'cargoMessage', value)} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>);

};