import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { AccessTypeEnum, DetailShipmentOutDTO, EntityTypeEnum, WeightModeEnum } from '@api/logsteo-api.v2';
import { CenteredRowWithGap, Label, RingilH3, RingilHeading, Row, RowWithSpaceBetween } from '@components/styles.tsx';
import { formatLocationNotino, formatPieces, formatPrice, formatWeight, isNullOrUndefined } from '@utils/utils';
import { Button } from 'primereact/button';
import { mapFromApiToDate, mapFromAPIToDateShort } from '@utils/date';
import { Checkbox } from 'primereact/checkbox';
import useNavigation from '@hooks/useNavigation';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { AttachmentDocument } from '@components/ringil3/Features/Attachments/UploadAttachmentDialog.tsx';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent.tsx';
import { isNotAgent } from '@components/obsolete/shipment/utils.ts';
import { MoreButtonsShipment } from '@components/obsolete/ButtonMoreNavigation/MoreButtonsShipment.tsx';
import Badge, { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import { inputTransporationTypeConvertToIcon } from '@components/obsolete/Form/InputTransportationType/InputTransportationType.tsx';
import Labels from '@components/obsolete/Labels/Labels.tsx';
import CreatedBadge from '@components/obsolete/Badge/CreatedBadge.tsx';
import UpdatedBadge from '@components/obsolete/Badge/UpdatedBadge.tsx';
import Section from '@components/obsolete/Section/Section.tsx';
import EditButton from '@components/obsolete/Buttons/EditButton/EditButton.tsx';
import { RC, RR } from '@components/obsolete/RC.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import OrderInfoComponent from '@components/obsolete/OrderInfoComponent/OrderInfoComponent.tsx';
import InfoPanelWithIcon from '@components/obsolete/InfoPanelWithIcon/InfoPanelWithIcon.tsx';
import { useCreateEditShipmentOrdersSidebar } from '@components/obsolete/shipment/sidebars/EditShipmentOrdersSidebar.tsx';
import { useCreateEditShipmentCargoInfoSidebar } from '@components/obsolete/shipment/sidebars/EditShipmentCargoInfoSidebar.tsx';
import { useCreateEditShipmentPartiesSidebar } from '@components/obsolete/shipment/sidebars/EditShipmentLocationSidebar.tsx';
import { useCreateEditShipmentLoadingUnloadingSidebar } from '@components/obsolete/shipment/sidebars/EditShipmentLoadingUnloadingSidebar.tsx';
import { CargoTypeCodeEnum } from '@utils/constants.ts';

interface ComponentProps {
  shipmentId: string;
}

const ShipmentDetail: React.FC<ComponentProps> = ({ shipmentId }) => {
  const { tr } = useTranslationLgs();
  const { detailShipmentById, genericUploadV2, deleteDocumentForEntity, changeDocumentVisibility } = useContext(ApiContext);
  const [data, setData] = useState<DetailShipmentOutDTO>();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const navigation = useNavigation();

  useEffect(() => {
    load();
  }, [shipmentId]);

  const load = () => {
    detailShipmentById(shipmentId, d => {
      setData(d);
      reloadAttachments(EntityTypeEnum.SHIPMENT, d.id);
    });
  };
  const addAttachment = (attachments: AttachmentDocument[]) => {
    genericUploadV2(
      {
        entityType: EntityTypeEnum.SHIPMENT,
        entityIds: [shipmentId],
        attachments: attachments.map(t => {
          return {
            attachmentId: t.attachmentId,
            accessType: t.accessType,
            documentType: t.documentType?.documentType,
            userNote: t.userNote,
            userDate: t.userDate,
          };
        }),
        companyOwnerId: null,
      },
      () => {
        load();
      },
    );
  };

  const delAttachment = (attachmentId: string) => {
    deleteDocumentForEntity(EntityTypeEnum.SHIPMENT, shipmentId, attachmentId, () => {
      load();
    });
  };
  const changeAttachmentVisibility = (attachmentId: string, internal: boolean) => {
    changeDocumentVisibility(attachmentId, { accessType: internal ? AccessTypeEnum.INTERNAL : AccessTypeEnum.ALL }, () => {
      load();
    });
  };

  const { EditShipmentOrdersSidebar, setVisible: setVisibleOrdersSidebar } = useCreateEditShipmentOrdersSidebar(() => {
    load();
  });

  const { EditShipmentCargoInfoSidebar, setVisible: setVisibleShipmentCargoInfo } = useCreateEditShipmentCargoInfoSidebar(() => {
    load();
  });

  const { EditShipmentPartiesSidebar, setVisible: setVisibleShipmentParties } = useCreateEditShipmentPartiesSidebar(() => {
    load();
  });

  const { EditShipmentLoadingUnloadingSidebar, setVisible: setVisibleShipmentLoadingUloading } = useCreateEditShipmentLoadingUnloadingSidebar(() => {
    load();
  });

  const { AttachmentComponent, load: reloadAttachments } = useCreateAttachmentComponent(false);

  if (isNullOrUndefined(data) || isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      <RowWithSpaceBetween>
        <RingilHeading>
          {tr(`ShipmentDetail.shipmentApplicationId`, `Shipment {{applicationId}}`, {
            applicationId: data.applicationId,
          })}
        </RingilHeading>
        <CenteredRowWithGap>
          {isNullOrUndefined(data.supplierNotificationCreated) && isNotAgent(loggedUser, data.entityOwnerCompanyId) && (
            <Button
              label={tr('ShipmentDetail.createSupplierNotification', 'Create supplier notification')}
              onClick={(e /*router.push(`/supplier-notifications/process-shipment?fromShipmentId=${data.id}`)*/) =>
                navigation.navigate(navigation.urlFunctions.createProcessShipment(data.id))
              }
            />
          )}
          <MoreButtonsShipment shipmentId={data.id} archived={data.isArchived} onChange={() => load()} />
        </CenteredRowWithGap>
      </RowWithSpaceBetween>
      <RowWithSpaceBetween>
        <BadgeRow>
          <Badge text={data.transportationTypeCode.name} icon={inputTransporationTypeConvertToIcon(data.transportationTypeCode.transportationType)} />
          <Badge text={formatPrice(data.orderValue.amount, data.orderValue.currencyCode)} />
          <Badge text={formatWeight(data.totalWeight)} />
          <Badge
            text={formatPieces(
              data.orders.reduce((previousValue, currentValue) => {
                return previousValue + currentValue.orderAmount;
              }, 0),
              tr,
            )}
          />
          <Badge
            text={tr(
              `SystemLabel.piecesCount`,
              `Pieces count {{count}}`,
              {
                count: data.orders.reduce((previousValue, currentValue) => {
                  return previousValue + currentValue.items.reduce((p, c) => p + c.amount, 0);
                }, 0),
              },
              data.orders.reduce((previousValue, currentValue) => {
                return previousValue + currentValue.items.reduce((p, c) => p + c.amount, 0);
              }, 0),
            )}
          />

          <Labels objectType={EntityTypeEnum.SHIPMENT} objectId={shipmentId} />
        </BadgeRow>
        <BadgeRow>
          <CreatedBadge createdAt={data.createdAt} createdBy={data.createdBy.fullName} createdEmail={data.createdBy.email} />
          {data.updatedAt && <UpdatedBadge updatedAt={data.updatedAt} updatedBy={data.updatedBy.fullName} updatedEmail={data.updatedBy.email} />}
        </BadgeRow>
      </RowWithSpaceBetween>
      <Section
        label={tr(`ShipmentDetail.parties`, `Cooperating parties`)}
        buttons={
          isNotAgent(loggedUser, data.entityOwnerCompanyId) ? (
            <EditButton
              label={tr(`ShipmentDetail.edit`, `Edit`)}
              onClick={e => {
                setVisibleShipmentParties(true);
              }}
            />
          ) : (
            <></>
          )
        }
      >
        <RR>
          <RC large={4} medium={6} small={6}>
            <RingilH3>{tr(`ShipmentDetail.supplier`, `Supplier`)}</RingilH3>
            <Label>{data.loadingCompany?.name}</Label>
            <div>{formatLocationNotino(data.loadingAddress.country, data.loadingAddress.city, data.loadingAddress.zipCode, data.loadingAddress.streetNr)}</div>
          </RC>
          <RC large={4} medium={6} small={6}>
            <RingilH3>{tr(`ShipmentDetail.customer`, `Customer`)}</RingilH3>
            <Label>{data.unloadingCompany.name}</Label>
            <div>{formatLocationNotino(data.unloadingAddress.country, data.unloadingAddress.city, data.unloadingAddress.zipCode, data.unloadingAddress.streetNr)}</div>
          </RC>
        </RR>
      </Section>
      <Section
        label={tr(`ShipmentDetail.shipmentTimeslots`, `Shipment timeslots`)}
        buttons={
          isNotAgent(loggedUser, data.entityOwnerCompanyId) ? (
            <EditButton
              label={tr(`ShipmentDetail.edit`, `Edit`)}
              onClick={e => {
                setVisibleShipmentLoadingUloading(true);
              }}
            />
          ) : (
            <></>
          )
        }
      >
        <RR>
          <RC large={4} medium={6} small={6}>
            <Label>{tr(`ShipmentDetail.departure`, `Departure`)}</Label>
            <div>{mapFromAPIToDateShort(data.departureAt)}</div>
          </RC>
          <RC large={4} medium={6} small={6}>
            <Label>{tr(`ShipmentDetail.arrival`, `Arrival`)}</Label>
            <div>{mapFromAPIToDateShort(data.arrivalAt)}</div>
          </RC>
        </RR>
      </Section>
      <Section
        label={tr(`ShipmentDetail.cargoInfo`, `Cargo information`)}
        buttons={
          isNotAgent(loggedUser, data.entityOwnerCompanyId) ? (
            <EditButton
              label={tr(`ShipmentDetail.edit`, `Edit`)}
              onClick={e => {
                setVisibleShipmentCargoInfo(true);
              }}
            />
          ) : (
            <></>
          )
        }
      >
        {isNotAgent(loggedUser, data.entityOwnerCompanyId) && (
          <Row>
            <HorizontalFieldValue
              label={tr(`SupplierManifestDetail.additionalCarrierRequirements`, `Additional carrier requirements`)}
              value={
                <RR>
                  {data.specialRequirements.map((requirement, requirementIndex) => (
                    <RC large={4} medium={6} small={12} key={requirementIndex}>
                      <Checkbox checked={true} className="p-mr-2" disabled />
                      <label>{requirement.name}</label>
                    </RC>
                  ))}
                </RR>
              }
            />
          </Row>
        )}

        <RR>
          {data.cargoType && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue
                label={tr(`ShipmentDetail.cargoType`, `Cargo type`)}
                value={[
                  data.cargoType.cargoType.cargoTypeName,
                  data.cargoType.cargoType.cargoTypeCode === CargoTypeCodeEnum.ADR_LQ ? formatWeight(data.cargoType.adrWeight) : null,
                ]
                  .filter(t => t != null)
                  .join(', ')}
              />
            </RC>
          )}

          {data.containerInfo && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue
                label={tr(`ShipmentDetail.container`, `Container`)}
                value={`${data.containerInfo.containerInfoName}, ${data.containerInfo.containerId}`}
              />
            </RC>
          )}

          {data.customsTypeCode && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue label={tr(`ShipmentDetail.customsType`, `Customs type`)} value={data.customsTypeName} />
            </RC>
          )}

          {data.shipmentId && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue label={tr(`ShipmentDetail.shipmentId`, `Shipment ID`)} value={data.shipmentId} />
            </RC>
          )}

          {data.referralCode && (
            <RC large={4} medium={6} small={6}>
              <HorizontalFieldValue label={tr(`ShipmentDetail.referralCode`, `Referral code`)} value={data.referralCode} />
            </RC>
          )}
        </RR>
      </Section>
      <Section
        label={tr(`ShipmentDetail.orders`, `Orders`)}
        buttons={
          isNotAgent(loggedUser, data.entityOwnerCompanyId) ? (
            <EditButton
              label={tr(`ShipmentDetail.edit`, `Edit`)}
              onClick={e => {
                setVisibleOrdersSidebar(true);
              }}
            />
          ) : (
            <></>
          )
        }
      >
        {data.orders.map((order, orderIndex) => {
          return (
            <React.Fragment key={orderIndex}>
              <Label>{order.orderName}</Label>
              <RR>
                <RC large={4} medium={6} small={6}>
                  {order.items.map((item, itemIndex) => {
                    return (
                      <OrderInfoComponent
                        key={itemIndex}
                        name={item.cargoItemType.name}
                        amount={item.amount}
                        unit={item.quantityUnitCode}
                        weight={null}
                        length={item.length}
                        height={item.height}
                        width={item.width}
                        stackable={null}
                      />
                    );
                  })}
                </RC>
                <RC large={4} medium={6} small={6}>
                  <InfoPanelWithIcon icon={'/images/icons/info.svg'}>
                    <div>
                      {order.orderDescription}, {order.orderAmount} ks
                    </div>
                    {order.weight && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tr('ShipmentDetail.totalWeight', 'Total weight: <strong>{{totalWeight}} kg</strong>', {
                            totalWeight: order.weight.value,
                          }),
                        }}
                      />
                    )}

                    {order.orderValue && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tr('ShipmentDetail.orderValue', 'Order value: <strong>{{orderValue}}</strong>', {
                            orderValue: formatPrice(order.orderValue.amount, order.orderValue.currencyCode),
                          }),
                        }}
                      />
                    )}
                  </InfoPanelWithIcon>
                </RC>
              </RR>
            </React.Fragment>
          );
        })}
      </Section>
      <AttachmentComponent
        onAddAttachment={attachments => {
          addAttachment(attachments);
        }}
        onDeletedAttachment={attachmentId => {
          delAttachment(attachmentId);
        }}
        onChangeVisibility={(attachmentId, internal) => {
          changeAttachmentVisibility(attachmentId, internal);
        }}
        entityId={data.id}
        entityType={EntityTypeEnum.SHIPMENT}
      />
      <EditShipmentOrdersSidebar
        shipmentId={data.id}
        companyId={data.unloadingCompany.id}
        initialData={{
          orders: data.orders.map(t => {
            return {
              name: t.orderName,
              items: t.items.map(t2 => {
                return {
                  length: t2.length,
                  quantity: t2.amount,
                  width: t2.width,
                  height: t2.height,
                  weightMode: WeightModeEnum.SKU_WEIGHT,
                  weight: t2.weight,
                  cargoMessage: null,
                  quantityUnit: t2.quantityUnitCode.toString(),
                  cargoItemType: {
                    name: t2.cargoItemType.name,
                    code: t2.cargoItemType.code,
                    quantity: 1,
                  },
                  stackable: null,
                };
              }),
              cargoDescription: t.orderDescription,
              weight: t.weight,
              currencyOrderValue: t.orderValue,
              cargoAmount: t.orderAmount,
            };
          }),
        }}
      />
      <EditShipmentCargoInfoSidebar
        shipmentId={data.id}
        companyId={data.unloadingCompany.id}
        transportationTypeCode={data.transportationTypeCode.transportationType}
        initialData={{
          shipmentId: data.shipmentId,
          cargoType: {
            cargoTypeCode: data.cargoType.cargoType.cargoTypeCode,
            adrWeight: data.cargoType.adrWeight,
          },
          customsTypeCode: data.customsTypeCode,
          referralCode: data.referralCode,
          containerInfo: data.containerInfo,
          specialRequirementIds: data.specialRequirements.map(t => t.code),
        }}
      />

      <EditShipmentPartiesSidebar
        shipmentId={data.id}
        companyId={data.loadingCompany.id}
        initialData={{
          companyId: data.unloadingCompany.id,
          loadingAddress: {
            locationId: data.loadingAddress.locationId,
            city: data.loadingAddress.city,
            country: data.loadingAddress.country,
            locationName: data.loadingAddress.locationName,
            streetNr: data.loadingAddress.streetNr,
            zipCode: data.loadingAddress.zipCode,
          },
          unloadingAddress: {
            locationId: data.unloadingAddress.locationId,
            city: data.unloadingAddress.city,
            country: data.unloadingAddress.country,
            locationName: data.unloadingAddress.locationName,
            streetNr: data.unloadingAddress.streetNr,
            zipCode: data.unloadingAddress.zipCode,
          },
          transportationTypeCode: data.transportationTypeCode.transportationType,
          arrivalAt: mapFromApiToDate(data.arrivalAt),
          departureAt: mapFromApiToDate(data.departureAt),
          supplierId: data.unloadingCompany.id,
        }}
      />

      <EditShipmentLoadingUnloadingSidebar
        shipmentId={data.id}
        companyId={data.loadingCompany.id}
        initialData={{
          arrivalAt: mapFromApiToDate(data.arrivalAt),
          departureAt: mapFromApiToDate(data.departureAt),
        }}
      />
    </>
  );
};

export default ShipmentDetail;
