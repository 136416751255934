import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { dateFormatOnlyDateShort, shortDateFormat } from '@utils/date';
import { formatWeight, getTime, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import dayjs from 'dayjs';
import useResponsiblePersons from '@hooks/responsible-persons/responsible-persons';
import { Location, OrderViewData, PageID } from '@components/obsolete/customer/new-expedition/types';
import { mapCodeToCargoItem, resolveSpecialRequirement } from '@components/obsolete/customer/new-expedition/helpers';
import { DistributionTypeEnum, EntityTypeEnum, InvoiceRebillingTypeEnum, InvoiceStateEnum, LovDTO, LovWithoutIdDTO, OpeningModeEnum } from '@api/logsteo-api.v2';
import { InputMask } from 'primereact/inputmask';
import useCargoTypes from '@hooks/use-cargo-types/use-cargo-types';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import useValidation from '@hooks/validation-hook/useValidation';
import * as yup from 'yup';
import ValidationDiv, { findValidationMessage } from '@utils/validation';
import { InputTextarea } from 'primereact/inputtextarea';
import { ApiContext } from '@api/api';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import LoadInfoItem from '@components/obsolete/LoadDetails/LoadInfo/LoadInfoItem.tsx';
import Address from '@components/obsolete/Address/Address.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import CheckboxLayout from '@components/obsolete/new-expedition/CheckboxLayout/CheckboxLayout.tsx';
import CheckboxWrapper from '@components/obsolete/new-expedition/CheckboxWrapper/CheckboxWrapper.tsx';
import CheckboxItem from '@components/obsolete/new-expedition/CheckboxWrapper/CheckboxItem.tsx';
import { NewExpeditionViewContext } from '@components/obsolete/new-expedition/NewExpeditionView.tsx';
import { Panel } from 'primereact/panel';
import CardView from '@components/obsolete/new-expedition/CardView/CardView.tsx';
import SelectedTruckType from '@components/SelectedTruckType.tsx';
import InfoProvided from '@components/obsolete/InfoProvided/InfoProvided.tsx';
import InfoProvidedItem from '@components/obsolete/InfoProvided/InfoProvidedItem.tsx';
import LoadInfo from '@components/obsolete/LoadDetails/LoadInfo/LoadInfo.tsx';
import AccordionTitle from '@components/obsolete/AccordionTitle/AccordionTitle.tsx';
import { ExpeditionContactInfo } from '@components/obsolete/common/ExpeditionContactInfo.tsx';
import InputLayout from '@components/obsolete/Form/InputLayout/InputLayout.tsx';
import DropdownLayout from '@components/obsolete/Form/DropdownLayout/DropdownLayout.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import InputDepartmentPlain from '@components/obsolete/Form/InputDepartmentPlain.tsx';
import InputPartnerPlain from '@components/obsolete/Form/InputPartner/InputPartnerPlain.tsx';
import InputResponsiblePersonPlain from '@components/obsolete/Form/InputResponsiblePerson/InputResponsiblePersonPlain.tsx';
import LabelsControlled from '@components/obsolete/Labels/LabelsControlled.tsx';
import PanelCard from '@components/obsolete/PanelCard/PanelCard.tsx';
import Alert from '@components/obsolete/Alert/Alert.tsx';
import DistributionAndPrice from '@components/obsolete/DistributionAndPrice/DistributionAndPrice.tsx';
import { DelayedPublishingInputComponent, DelayedPublishingLabel } from '@components/obsolete/delayed-publishing';
import ButtonLayout from '@components/obsolete/Form/ButtonLayout/ButtonLayout.tsx';
import { SelectCarriers } from '@components/obsolete/SelectCarriers/SelectCarriers.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';

interface ContactInfoProps {
  location: Location;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ location }) => {
  const { t }: { t: any } = useTranslation('common');
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">
            <img src="/images/contacts.svg" alt="" />
          </div>
          <div>
            <Label title={t('expeditionOverviewPage.labelContacts')} />
            <LoadInfoItem name={location.contactName} phone={location.contactPhone} email={location.contactEmail} className="p-mb-3"></LoadInfoItem>
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-5 p-lg-4">
        <Address company={`${location.name}, ${location.city}`} address={`${location.streetNr}, ${location.zipCode}`} />
        {/*<Address company="Firma XYZ, České Budějovice" address="Sokolovská 8, 180 00" />*/}
      </div>
    </div>
  );
};

interface FTLLoadingUloadingDetailProps {
  index: number;
  size: number;
  carrierCode: string;
}

const FTLLoadingUloadingDetail: React.FC<FTLLoadingUloadingDetailProps> = ({ index, size, carrierCode = '-' }) => {
  const { t }: { t: any } = useTranslation('common');
  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">
            <img src={index < size - 1 ? '/images/icon_pickup.png' : '/images/icon_dropoff.png'} alt="" />
          </div>
          <div>
            <Label
              title={index == 0 ? t('loadDetailsPage.labelPickUp') : index < size - 1 ? t('loadDetailsPage.labelPickUpDropOff') : t('loadDetailsPage.labelDropOff')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface LoadingUloadingDetailProps {
  loading: boolean;
  order: OrderViewData;
  carrierCode?: string;
  isFirstElement?: boolean;
}

const LoadingUloadingDetail: React.FC<LoadingUloadingDetailProps> = ({ loading, order, carrierCode = '-', isFirstElement = true }) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();

  return (
    <div className="p-grid row-accordion-info">
      <div className="p-col-12 p-md-5 p-lg-4">
        <div className="p-d-flex">
          <div className="icon-accordion">{isFirstElement && <img src={loading ? '/images/icon_pickup.png' : '/images/icon_dropoff.png'} alt="" width={46} />}</div>
          <div>
            {isFirstElement && <Label title={loading ? t('loadDetailsPage.labelPickUp') : t('loadDetailsPage.labelDropOff')} />}
            <LoadInfoItem bookingRef={order.name} className="p-mb-3" />
          </div>
        </div>
      </div>
      <div className="p-col-12 p-md-5 p-lg-4">
        {order?.items?.map((orderItem, orderItemIndex) => {
          return (
            <LoadInfoList
              key={orderItemIndex}
              title={`${orderItem.cargoItemType.name} (${orderItem.amount} ${translateQuantityCode(orderItem.quantityUnit, tr)})`}
              description={weightAndDimensions(orderItem.height, orderItem.length, orderItem.width, orderItem.weight, t)}
              stackable={orderItem.stackable}
              itemNote={orderItem.itemNote}
            />
          );
        })}
      </div>
    </div>
  );
};

interface OverviewSpecialRequirementsProps {
  selectedSpecialRequirements: string[];
}

const OverviewSpecialRequirements: React.FC<OverviewSpecialRequirementsProps> = ({ selectedSpecialRequirements }) => {
  const [specialRequirements, setSpecialRequirements] = useState<LovWithoutIdDTO[]>([]);
  const { lovSpecialRequirements } = useContext(ApiContext);
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();

  useEffect(() => {
    lovSpecialRequirements(data => {
      setSpecialRequirements(data);
    });
  }, []);

  return (
    <>
      {selectedSpecialRequirements && (
        <CheckboxLayout label={t('expeditionOverviewPage.labelAdditionalRequirements')} className="p-mt-3">
          <CheckboxWrapper className="p-lg-7">
            {selectedSpecialRequirements
              .map(reqCode => resolveSpecialRequirement(reqCode, specialRequirements))
              .filter(sr => sr !== undefined)
              .map((requirement, index) => (
                <CheckboxItem className="p-md-3" key={index}>
                  <Checkbox checked={true} className="p-mr-2" disabled />
                  <label>{requirement.name}</label>
                </CheckboxItem>
              ))}
          </CheckboxWrapper>
        </CheckboxLayout>
      )}
    </>
  );
};

interface ComponentProps {}
const NewExpeditionStep4: React.FC<ComponentProps> = ({}) => {
  const { t }: { t: any } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const responsiblePersons = useResponsiblePersons();
  const cargoTypes = useCargoTypes();
  const [loading, setLoading] = useState(false);

  const {
    state,
    toggleCreateDistribution,
    changeDistributionType,
    changeDistributionPrice,
    changeDistributionDate,
    changeDistributionSelectedItems,
    changeResponsiblePerson,
    applyDistributionTemplate,
    changeDistributionTime,
    clickOnBreadCrumb,
    saveExpedition,
    changeDistributionCurrency,
    setCollaborators,
    setIntenalNote,
    changeDistributionPublishing,
    changeFlatProperty,
  } = useContext(NewExpeditionViewContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationScheme = yup.object().shape({
    invoiceDepartment: yup.mixed().when('invoiceRebillingType', {
      is: InvoiceRebillingTypeEnum.INTERNAL_REBILLING,
      then: yup.mixed().required(tr('validation.required', 'Required')),
      otherwise: yup.mixed().nullable(),
    }),
    distribution: yup
      .object()
      .shape({
        responseDeadline: yup.object().when('enabled', {
          is: true,

          then: yup
            .object()
            .required()
            .test(
              'is-future',
              'isFuture',
              // @ts-ignore
              value => value.isValid() && value.isAfter(dayjs(), 'minutes'),
            ),
          otherwise: yup.object(),
        }),
        distributionType: yup.string().when('enabled', {
          is: true,
          then: yup.string().defined().oneOf([DistributionTypeEnum.SPOT, DistributionTypeEnum.FIXED_PRICE]),
          otherwise: yup.string(),
        }),
        price: yup.number().when('enabled', {
          is: true,
          then: yup.number().when('distributionType', {
            is: DistributionTypeEnum.FIXED_PRICE,
            then: yup.number().defined().min(0),
            otherwise: yup.number().nullable().min(0),
          }),
          otherwise: yup.number(),
        }),
        selectedItems: yup.array().when('enabled', {
          is: true,
          then: yup.array().min(1),
          otherwise: yup.array(),
        }),
      })
      .required(),
  });

  const toast = useRef(undefined);

  const { clear, validationErrors, validateAndSend, find } = useValidation(validationScheme, () => {
    setLoading(true);
    saveExpedition({
      onValidationFailed: errorMessages => {
        setLoading(false);
        if (toast?.current) {
          toast.current?.show({
            severity: 'error',
            summary: tr(`NewExpeditionStep4.validationError`, `Validation error`),
            detail: errorMessages.map((t: any) => tr(`${t.type}.${t.scope}.${t.message}`, `${t.type}.${t.scope}.${t.message}`)).map((t: any) => <div>{t}</div>),
          });
        } else {
          alert('Problem');
        }
      },
    });
  });

  return (
    <>
      {/*
        // @ts-ignore*/}
      <Card title={t('expeditionOverviewPage.titlePage')}>
        {/*
        // @ts-ignore*/}
        <Panel>
          <div className="p-d-flex p-grid">
            <CardView title={t(`global.expeditionTypeShort${state.expeditionType}`)} option={t(`global.expeditionType${state.expeditionType}`)} />
            {state.truckType && <SelectedTruckType selectedTruckId={state.truckType} />}
            <CardView
              title={
                state.manipulationTypes &&
                state.manipulationTypes
                  .map(loadingType => {
                    return t(`global.manipulation.${loadingType}`);
                  })
                  .join(',')
              }
              option={t('expeditionOverviewPage.manipulationTitle')}
            />
            <CardView title="- km" option={t('expeditionOverviewPage.routeLength')} />
          </div>

          <OverviewSpecialRequirements selectedSpecialRequirements={state.specialRequirements} />

          <InfoProvided className="p-mt-3">
            <InfoProvidedItem title={t(`NewExpeditionStep4.cargoType`, `Typ nákladu`)}>
              <p className="p-mt-1">{mapCodeToCargoItem(cargoTypes, state.cargoType)?.name}</p>
            </InfoProvidedItem>

            <InfoProvidedItem title={tr(`NewExpeditionStep4.adrWeight`, `ADR weight`)}>
              <p className="p-mt-1">{formatWeight(state.adrWeight)}</p>
            </InfoProvidedItem>

            {state.carrierNote && (
              <InfoProvidedItem title={t(`NewExpeditionStep4.carrierNote`, `Poznámka pro dopravce`)}>
                <p className="p-mt-1" style={{ whiteSpace: 'pre' }}>
                  {state.carrierNote}
                </p>
              </InfoProvidedItem>
            )}
          </InfoProvided>

          <LoadInfo loadingLocations={state.locations.filter(l => l.loadingLocation)} />

          <div className="p-mt-6">
            <Label title={t('expeditionOverviewPage.labelRoute')} />
            {/*
        // @ts-ignore*/}
            <Accordion>
              {state.locations.map((location, index, locations) => {
                return (
                  // @ts-ignore
                  <AccordionTab
                    key={index}
                    header={
                      <>
                        <AccordionTitle number={index + 1} title={location.name} pretitle={location.city} btnTitle={t('expeditionOverviewPage.accordionDetail')} />
                      </>
                    }
                  >
                    <div className="p-grid row-accordion-info">
                      <div className="p-col-12 p-md-5 p-lg-4">
                        <div className="p-d-flex">
                          <div className="icon-accordion">
                            <img src="/images/calendar.svg" alt="" />
                          </div>
                          <div>
                            <Label title={t('loadDetailsPage.panelLocationDetails.calendar')} />
                            <div className="time-wrapper">
                              {location.timeslots.dayWithInterval
                                .filter(t => t.openingMode !== OpeningModeEnum.CLOSED)
                                .map((time, timeslotIndex) => {
                                  return (
                                    <div className="time-item" key={timeslotIndex}>
                                      <div className="p-mr-2">{dateFormatOnlyDateShort(dayjs(time.day))}</div>
                                      <div>
                                        {time.intervals.map((interval, intervalIndex) => {
                                          return <div className="time-item" key={intervalIndex}>{`${interval.sinceHoursString} - ${interval.tillHoursString}`}</div>;
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {location.loadingLocation &&
                      location.loadingOrders.map((loadingOrders, orderIndex) => {
                        return (
                          <LoadingUloadingDetail
                            key={orderIndex}
                            loading={true}
                            order={loadingOrders}
                            carrierCode={location.carrierCode}
                            isFirstElement={orderIndex === 0}
                          />
                        );
                      })}
                    {location.unloadingLocation &&
                      state.locations
                        .map(location => location.loadingOrders)
                        .flat(1)
                        .filter(order => order.unloadingLocationId === index)
                        .map((loadingOrders, orderIndex) => {
                          return (
                            <LoadingUloadingDetail
                              key={orderIndex}
                              loading={false}
                              order={loadingOrders}
                              carrierCode={location.carrierCode}
                              isFirstElement={orderIndex === 0}
                            />
                          );
                        })}
                    <ExpeditionContactInfo
                      location={{
                        contactEmail: location.contactEmail,
                        contactPhone: location.contactPhone,
                        contactName: location.contactName,
                        name: location.name,
                        address: {
                          city: location.city,
                          country: location.country,
                          streetNr: location.streetNr,
                          zipCode: location.zipCode,
                        },
                        enabledEmailNotificationForContactPerson: location.enabledEmailNotificationForContactPerson,
                        pickupCode: location.carrierCode,
                        additionalNotificationContacts: location.additionalNotificationPersons?.map(t => ({
                          contactEmail: t.contactEmail,
                          contactPhone: t.contactPhone,
                          contactName: t.contactName,
                        })),
                      }}
                    />
                  </AccordionTab>
                );
              })}
            </Accordion>
          </div>

          <InputLayout className="p-mt-4">
            <DropdownLayout>
              <Label title={t('expeditionOverviewPage.labelPerson')} />
              <Dropdown
                value={state.responsiblePerson}
                dataKey="id"
                filter={true}
                options={responsiblePersons}
                onChange={e => changeResponsiblePerson(e.target.value)}
                optionLabel="name"
                itemTemplate={(option: LovDTO) => (
                  <>
                    {option.active ? (
                      <span>{option.name}</span>
                    ) : (
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: 'gray',
                        }}
                      >
                        {option.name}
                      </span>
                    )}
                  </>
                )}
              />
            </DropdownLayout>
          </InputLayout>
          <InputLayout className="p-mt-2">
            <DropdownLayout>
              <Label title={tr(`NewExpeditionStep4.subscribeOthers`, `Notifikovat o změnách další spolupracovníky`)} />
              <MultiSelect
                value={state.subscribersList}
                options={responsiblePersons}
                optionLabel="name"
                filter={true}
                onChange={e => setCollaborators(e.value)}
                style={{ width: '100%' }}
                className="multiselect-custom"
                placeholder={tr(`NewExpeditionStep4.chooseCollaborators`, `Vyberte spolupracovníky`)}
                itemTemplate={(option: LovDTO) => (
                  <>
                    {option.active ? (
                      <span>{option.name}</span>
                    ) : (
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: 'gray',
                        }}
                      >
                        {option.name}
                      </span>
                    )}
                  </>
                )}
              />
            </DropdownLayout>
          </InputLayout>
          <div className="p-grid">
            <div className="p-field p-col-12 p-md-8 p-lg-4">
              <div className="p-d-flex p-ai-center">
                <Label title={tr(`NewExpeditionStep4.internalNote`, `Interní poznámka k expedici`)} />
                <Button
                  type="button"
                  label="?"
                  tooltip={tr(`NewExpeditionStep4.internalNoteInvisibleForCarrier`, `Poznámka nebude zobrazována dopravci`)}
                  className="p-button-text p-ml-2 p-mb-2"
                  tooltipOptions={{ position: 'top' }}
                />
              </div>
              <div className="p-fluid p-grid">
                <div className={`p-col-12 p-md-8 p-lg-12`}>
                  <InputTextarea value={state.internalNote} onChange={e => setIntenalNote(e.target.value)} className={`p-col-8 p-md-8 p-lg-12`} rows={6} cols={30} />
                </div>
              </div>
            </div>
          </div>

          <hr />
          <HorizontalFieldValue
            label={tr(`NewExpeditionStep4.reaccountTo`, `Reaccount to`)}
            value={
              <>
                <Dropdown
                  value={state.invoiceRebillingType}
                  style={{ width: '20rem' }}
                  options={[
                    InvoiceRebillingTypeEnum.NON_REBILLING,
                    InvoiceRebillingTypeEnum.INTERNAL_REBILLING,
                    InvoiceRebillingTypeEnum.EXTERNAL_REBILLING,
                    InvoiceRebillingTypeEnum.OTHER,
                  ]}
                  showClear={true}
                  valueTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
                  itemTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceRebillingTypeEnum.${e}`, e)}</div>}
                  onChange={e => changeFlatProperty('invoiceRebillingType', e.target.value)}
                />
              </>
            }
          />

          {state.invoiceRebillingType === InvoiceRebillingTypeEnum.INTERNAL_REBILLING && (
            <HorizontalFieldValue
              label={tr(`NewExpeditionStep4.internalDepartment`, `Internal department`)}
              validationError={findValidationMessage(validationErrors, 'invoiceDepartment', t)}
              value={
                <>
                  <InputDepartmentPlain department={state.invoiceDepartment} style={{ width: '100%' }} onChange={v => changeFlatProperty('invoiceDepartment', v)} />
                </>
              }
            />
          )}
          {state.invoiceRebillingType === InvoiceRebillingTypeEnum.EXTERNAL_REBILLING && (
            <HorizontalFieldValue
              label={tr(`NewExpeditionStep4.externalPartner`, `External partner`)}
              value={
                <>
                  <InputPartnerPlain style={{ width: '20rem' }} partnerId={state.invoicePartnerId} onChange={v => changeFlatProperty('invoicePartnerId', v)} />
                </>
              }
            />
          )}
          <HorizontalFieldValue
            label={tr(`NewExpeditionStep4.disponent`, `Disponent`)}
            value={
              <InputResponsiblePersonPlain
                responsiblePersonId={state.invoiceUserId}
                style={{ width: '20rem' }}
                onChange={v => changeFlatProperty('invoiceUserId', v)}
                showClearButton={true}
              />
            }
          />
          <HorizontalFieldValue
            label={tr(`NewExpeditionStep4.invoicingState`, `Invoicing state`)}
            value={
              <Dropdown
                value={state.invoiceState}
                options={[InvoiceStateEnum.NON_INVOICED, InvoiceStateEnum.INVOICED]}
                showClear={true}
                style={{ width: '20rem' }}
                valueTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceStateEnum.${e}`, e)}</div>}
                itemTemplate={e => <div>{tr(`NewExpeditionStep4.InvoiceStateEnum.${e}`, e)}</div>}
                onChange={e => changeFlatProperty('invoiceState', e.target.value)}
              />
            }
          />
          <HorizontalFieldValue
            label={tr(`NewExpeditionStep4.pairingSymbol`, `Pairing symbol`)}
            value={<InputText value={state.pairingSymbol} onChange={e => changeFlatProperty('pairingSymbol', e.target.value)} />}
          />
          <HorizontalFieldValue
            label={tr(`NewExpeditionStep4.setYourLabels`, `Set your labels`)}
            value={
              <>
                <LabelsControlled
                  value={state.labelCodes}
                  entityType={EntityTypeEnum.EXPEDITION}
                  onChange={v => {
                    changeFlatProperty('labelCodes', v);
                  }}
                />
              </>
            }
          />
        </Panel>
        <>
          <PanelCard className="p-mt-4">
            <div className="p-sm-6 p-mb-4 p-d-flex p-ai-center">
              <InputSwitch checked={state.distribution.enabled} onChange={() => toggleCreateDistribution()} className="p-mr-2" />
              <label>{t('expeditionOverviewPage.labelWrite')}</label>
            </div>
          </PanelCard>
          {!state.distribution.enabled && <p className="p-mt-0 p-mb-4">{t('expeditionOverviewPage.labelInfo')}</p>}
          {state.distribution.enabled && (
            <>
              {state.distributionTemplate && state.distributionTemplate?.distributionType && !state.distributionTemplateApplied && (
                <Alert className="p-mb-4">
                  <i>
                    {t(`NewExpeditionStep4.youCanUseTemplate`, `U této expedice můžete využít vytvořenou šablonu poptávky.`)}
                    <Button
                      className="p-ml-4 p-button-text p-p-0 p-text-italic"
                      label={t('expeditionOverviewPage.labelFillOut')}
                      onClick={() => applyDistributionTemplate()}
                    />
                  </i>
                </Alert>
              )}

              {state.distributionTemplateApplied && (
                <Alert className="p-mb-4 text-green">
                  <i>{t(`NewExpeditionStep4.templateLoadedSuccessfully`, `Šablona distribuce nahrána úspěšně.`)}</i>
                </Alert>
              )}
              <div className="p-grid p-d-flex">
                <div className="p-col-12 p-lg-6 p-xl-4">
                  <DistributionAndPrice
                    price={state.distribution.price}
                    currency={state.distribution.currency}
                    distributionType={state.distribution.distributionType}
                    changeDistributionPrice={changeDistributionPrice}
                    changeDistributionType={changeDistributionType}
                    changeDistributionCurrency={changeDistributionCurrency}
                    validationErrors={validationErrors}
                    disabled={false}
                    validationPrefix={'distribution.'}
                  />
                  <div className="p-field p-grid p-mb-4">
                    <div className="p-col-12">
                      <Label title={t('expeditionOverviewPage.labelCarriers')} />
                    </div>
                    <div className="p-col-12">
                      <SelectCarriers
                        value={state.distribution.selectedItems}
                        onSelected={v => changeDistributionSelectedItems(v)}
                        onlyCarriers={false}
                        isDisabled={false}
                        placeholder={tr(`NewExpeditionStep4.chooseCarrierOrGroup`, `Vyberte dopravce nebo skupinu`)}
                      />
                      <ValidationDiv errors={validationErrors} path={'distribution.selectedItems'} />
                    </div>
                  </div>

                  <div className="p-field p-grid">
                    <div className="p-col-12">
                      <Label title={t('expeditionOverviewPage.labelabelDeadlinel')} />
                    </div>

                    <div className="p-field p-col-12 p-md-6">
                      <Calendar
                        locale={'cs'}
                        dateFormat={shortDateFormat}
                        value={state.distribution.responseDeadline.toDate()}
                        showIcon
                        selectionMode="single"
                        readOnlyInput
                        onChange={e => {
                          changeDistributionDate(dayjs(e.value as Date));
                        }}
                        style={{ width: '100%' }}
                      />
                      <ValidationDiv errors={validationErrors} path={'distribution.responseDeadline'} />
                    </div>
                    <div className="p-field  p-col-12 p-md-6">
                      <InputMask
                        mask={'99:99'}
                        value={getTime(state.distribution.responseDeadline)}
                        onComplete={e => changeDistributionTime(e.value)}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                  <div className="p-field p-grid">
                    <div className="p-col-12">
                      <DelayedPublishingLabel />
                    </div>
                    <div className="p-col-12">
                      <DelayedPublishingInputComponent changeAutomaticPublishing={e => changeDistributionPublishing(e)} value={state.distribution.isPublished} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <ButtonLayout>
            <Button label={t('wayPage.form.buttonContinue')} className="p-mr-2" loading={loading === true} onClick={e => validateAndSend(state)} />
            <Button label={t('wayPage.form.buttonBack')} className="p-button-text" onClick={e => clickOnBreadCrumb(PageID.STEP3)} />
          </ButtonLayout>
        </>
      </Card>
      <Toast ref={toast} position={'top-right'}></Toast>
    </>
  );
};

export default NewExpeditionStep4;
