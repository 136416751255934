import React, { useContext } from 'react';
import * as yup from 'yup';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ShipmentItemInDTO, ShipmentOrderInDTO, WeightModeTypeOrderEnum } from '@api/logsteo-api.v2';
import { Form } from '@hooks/useForm/useForm';
import produce from 'immer';
import { Button } from 'primereact/button';
import { isNullOrUndefined } from '@utils/utils';
import { CUSTOMIZATION_MODE_DISABLED, CUSTOMIZATION_MODE_OPTIONAL, CUSTOMIZATION_MODE_REQUIRED } from '@utils/exports';
import { Order, OrderItem } from '@components/obsolete/Orders/types.ts';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import { RingilH3, Row } from '@components/styles.tsx';
import { OrderComponent } from '@components/obsolete/Orders/Orders.tsx';

export interface ShipmentOrdersData {
  orders: Order[];
  companyCode?: string;
}

export const emptyOrder = (): Order => {
  return {
    name: '',
    items: [{ quantityUnit: 'KS' }],
    currencyOrderValue: { currencyCode: 'CZK', amount: null },
    weight: {
      value: null,
      weightModeTypeOrder: WeightModeTypeOrderEnum.SUMMED,
    },
    cargoAmount: null,
    cargoDescription: '',
  };
};

interface ComponentProps {
  form: Form<ShipmentOrdersData>;
  readonly companyId: string;
  inSidebar: boolean;
}

export const supplierNotificationValidation = yup.object().shape({});

export const mapOders = (order: Order): ShipmentOrderInDTO => {
  return {
    ...order,
    items: order.items.map(mapItems),
    orderAmount: order.cargoAmount,
    orderDescription: order.cargoDescription,
    orderName: order.name,
    orderValue: order.currencyOrderValue,
  };
};

const mapItems = (item: OrderItem): ShipmentItemInDTO => {
  return {
    cargoItemTypeCode: item.cargoItemType.code,
    height: item.height,
    length: item.length,
    amount: item.quantity,
    quantityUnitCode: item.quantityUnit,
    weight: item.weight,
    width: item.width,
    stackable: isNullOrUndefined(item.stackable) ? false : item.stackable,
  };
};

const ShipmentOrdersForm: React.FC<ComponentProps> = ({ form, companyId, inSidebar }) => {
  const { tr } = useTranslationLgs();
  const { loggedUser } = useContext(AuthenticatedUserContext);

  const addOrder = () => {
    form.setFieldValue('orders', [...form.values.orders, emptyOrder()]);
  };

  const deleteOrder = (index: number) => {
    form.setFieldValue(
      'orders',
      form.values.orders.filter((_, i) => i !== index),
    );
  };

  if (isNullOrUndefined(loggedUser)) return <></>;

  return (
    <>
      <RingilH3>{tr(`CreateShipment.orders`, `Orders`)}</RingilH3>
      {form.values.orders.map((order, orderKey) => {
        return (
          <OrderComponent
            key={orderKey}
            inSidebar={inSidebar}
            order={order}
            onChange={o => {
              const newOrders = produce(form.values.orders, draft => {
                draft[orderKey] = o;
              });
              form.setFieldValue('orders', newOrders);
            }}
            canBeDeleted={form.values.orders.length > 1}
            onDelete={() => deleteOrder(orderKey)}
            showStackable={false}
            validationErrors={[]}
            orderIndex={orderKey}
            companyCode={form.values.companyCode}
            customerId={null}
            showExternalOrders={false}
            orderValueMode={CUSTOMIZATION_MODE_REQUIRED}
            manipulationUnitNoteMode={CUSTOMIZATION_MODE_DISABLED}
            amountOfOrderMode={CUSTOMIZATION_MODE_OPTIONAL}
          />
        );
      })}
      <Row>
        <Button label={tr('CreateShipment.addOrder', '+ Add order')} className={'p-button-outlined'} onClick={e => addOrder()} />
      </Row>
    </>
  );
};

export default ShipmentOrdersForm;
