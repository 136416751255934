import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import Heading from '@components/ringil3/Heading/Heading';
import { isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { EntityTypeEnum, ExpeditionDetailV4OutDTO, ExpeditionStatusEnum, FeaturesEnum, InvoiceRebillingTypeEnum, LoadingTypesEnum, LovDTO } from '@api/logsteo-api.v2';
import { Panel, Ringil3BadgeRow } from '@components/ringil3/styles';
import DistanceBadge from '@components/ringil3/Badges/DistanceBadge';
import WeightBadge from '@components/ringil3/Badges/WeightBadge';
import PiecesBadge from '@components/ringil3/Badges/PiecesBadge';
import LocationFromBadge from '@components/ringil3/Badges/LocationFromBadge';
import LocationToBadge from '@components/ringil3/Badges/LocationToBadge';
import StateTag from '@components/framework/tags/StateTag.tsx';
import ManipulationTag from '@components/framework/tags/ManipulationTag.tsx';
import TruckTag from '@components/framework/tags/TruckTag.tsx';
import CargoTypeTag from '@components/framework/tags/CargoTypeTag.tsx';
import LabelsTag from '@components/framework/tags/LabelsTag.tsx';
import RequirementsTag from '@components/framework/tags/RequirementsTag.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import NavigationBreadCrumb from '@components/ringil3/BreadCrumb/NavigationBreadCrumb';
import ExpeditionLocation from '@components/ringil3/ExpeditionLocation/ExpeditionLocation';
import ExtraCosts from '@components/ringil3/Features/Expedition/ExtraCosts/ExtraCosts';
import LinkedSNBadge from '@components/ringil3/Badges/LinkedSNBadge';
import CarrierNoteTag from '@components/framework/tags/CarrierNoteTag.tsx';
import AuditLog from '@components/ringil3/Features/Expedition/AuditLog/AuditLog';
import TabExpeditionStateNew from '@components/ringil3/Features/Expedition/TabExpeditionStateNew';
import TabExpeditionQuotes from '@components/ringil3/Features/Expedition/TabExpeditionQuotes';
import TabExpeditionAssignedPlus from '@components/ringil3/Features/Expedition/TabExpeditionAssignedPlus';
import SvgRoute from '@components/ringil3/icons/heading/Route';
import { useCreateAttachmentComponent } from '@components/ringil3/Features/Attachments/AttachementComponent';
import SubscribeComponent from '@components/obsolete/Subscribe/SubscribeComponent.tsx';
import CustomerChatComponent from '@components/obsolete/Chat/CustomerChatComponent.tsx';
import { useCreateRingilExpeditionTabs } from '@components/ringil3/RingilTabs/RingilTabs.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import BreadCrumbComponent from '@components/obsolete/BreadCrumbComponent/BreadCrumbComponent.tsx';
import useNavigation from '@hooks/useNavigation.tsx';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import CustomsDetail from '@app/pages/Customs/CustomsDetail.tsx';
import { CargoType } from '@components/framework/input/InputCargoType/InputCargoType.tsx';
import ResponsiblePersonTag from '@components/framework/tags/ResponsiblePersonTag.tsx';
import InternalInfoNotifyTag from '@components/framework/tags/InternalInfoNotifyTag.tsx';
import InternalInfoInvoiceState from '@components/framework/tags/InternalInfoInvoiceStateTag.tsx';
import InternalInfoDisponentTag from '@components/framework/tags/InternalInfoDisponentTag.tsx';
import InternalInfoPairingSymbol from '@components/framework/tags/InternalInfoPairingSymbol.tsx';
import InternalNoteTag from '@components/framework/tags/InternalNoteTag.tsx';
import InternalInfoRebillingTag, { RebillingType } from '@components/framework/tags/InternalInfoRebillingTag.tsx';
import ExpeditionMoreButtons from '@components/ringil3/Features/Expedition/MoreButton/ExpeditionMoreButtons.tsx';
import InternalInfoFolderTag from '@components/framework/tags/InternalInfoFolderTag.tsx';
import CMRTableTab from '@components/obsolete/cmr/CMRTableTab.tsx';

interface ComponentProps {
  expeditionId: string;
}

interface ExpeditionMessage {
  expeditionId: string;
}

const ExpeditionDetail: React.FC<ComponentProps> = ({ expeditionId }) => {
  const { getExpeditionV4Detail, listExtraCostsForExpedition, editHeaderExpedition, editInternalInfoExpedition } = useContext(ApiContext);
  const { tr } = useTranslationLgs();
  const [expedition, setExpedition] = useState<ExpeditionDetailV4OutDTO>();
  const nav = useNavigation();
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const acRef = React.useRef<AbortController | null>();

  const [showDialog, setShowDialog] = useState(false);

  const { selectedPanel, RingilTabs } = useCreateRingilExpeditionTabs();
  const { load: loadAttachments, AttachmentComponent } = useCreateAttachmentComponent(false);

  const [selectedCarrierId, setSelectedCarrierId] = useState(undefined);

  useEffect(() => {
    const ac = new AbortController();
    acRef.current = ac;
    if (isNotNullOrUndefined(expeditionId)) {
      load();
      loadAttachments(EntityTypeEnum.EXPEDITION, expeditionId);
    }
    return () => {
      acRef.current.abort();
    };
  }, [expeditionId]);

  const load = () => {
    getExpeditionV4Detail(
      expeditionId,
      d => {
        if (!acRef.current.signal.aborted) {
          setExpedition(d);
        }
      },
      null,
      {
        onError: e => {},
      },
    );
  };

  const reloadExtraCosts = () => {
    listExtraCostsForExpedition(expedition.customerId, expedition.applicationId, data => {
      setExpedition(prev => {
        return { ...prev, extraCosts: data };
      });
    });
  };

  const mapRebilling = (v: RebillingType) => {
    if (v == null) return null;

    if (v.invoiceRebillingType == InvoiceRebillingTypeEnum.EXTERNAL_REBILLING) {
      return { invoiceRebillingType: v.invoiceRebillingType, invoicePartnerId: v.invoicePartner?.companyId };
    }
    if (v.invoiceRebillingType == InvoiceRebillingTypeEnum.INTERNAL_REBILLING) {
      return { invoiceRebillingType: v.invoiceRebillingType, invoiceDepartment: v.invoiceDepartment };
    }
    return { invoiceRebillingType: v.invoiceRebillingType };
  };

  if (isNullOrUndefined(expedition)) return <></>;

  const hasQuotes = expedition.expeditionStatus !== ExpeditionStatusEnum.NEW && expedition.quotes.length > 0;
  const isAssignedPlus = [
    ExpeditionStatusEnum.ASSIGNED,
    ExpeditionStatusEnum.ACTIVE,
    ExpeditionStatusEnum.WAITING_FOR_DOCS,
    ExpeditionStatusEnum.READY_TO_SHIPMENT,
    ExpeditionStatusEnum.FINISHED,
    ExpeditionStatusEnum.CANCELED,
  ].includes(expedition.expeditionStatus);

  const isOwner = expedition.customerId === loggedUser?.companyId;
  const showCmr = loggedUser?.features?.some(t => t === FeaturesEnum.CMR);

  return (
    <Test>
      <BreadCrumbComponent
        items={[
          {
            label: tr(`ExpeditionDetail.expeditionList`, `Expedition list`),
            url: nav.createNavigationLink(nav.urlFunctions.createCustomerExpeditionList()),
          },
          { label: tr(`ExpeditionDetail.expeditionDetail`, `Expedition detail`) },
        ]}
      />

      <Heading
        title={expedition.applicationId}
        headingVariant={'h1'}
        labels={<LabelsTag objectId={expedition.id} objectType={EntityTypeEnum.EXPEDITION} resolveLabelsForType={EntityTypeEnum.EXPEDITION} />}
        buttons={[
          <SubscribeComponent key={1} customerId={expedition.customerId} applicationId={expedition.applicationId} />,
          <ExpeditionMoreButtons
            expeditionId={expedition.id}
            customerId={expedition.customerId}
            applicationId={expedition.applicationId}
            isExpeditionCanceled={expedition.expeditionStatus === ExpeditionStatusEnum.CANCELED}
            onCanceledExpedition={() => {
              window.location.reload();
            }}
            onSwitchedToManualExpedition={(applicationId: string) => {
              nav.navigate(nav.urlFunctions.createCustomerExpeditionDetail(applicationId));
            }}
            currency={expedition.expeditionCurrency}
            onAddedExtraCosts={() => {
              window.location.reload();
            }}
            showExtraCosts={
              expedition.expeditionStatus !== ExpeditionStatusEnum.NEW &&
              expedition.expeditionStatus !== ExpeditionStatusEnum.ASSIGNING &&
              expedition.expeditionStatus !== ExpeditionStatusEnum.CANCELED
            }
            onManuallyAssignedCarrier={() => {
              window.location.reload();
            }}
            inStateNew={expedition.expeditionStatus === ExpeditionStatusEnum.NEW}
            assignedDispatcherEmail={expedition.carrierDispatcherEmail}
            isArchived={expedition.isArchived}
            onArchivedChanged={() => {
              window.location.reload();
            }}
            canBeFinished={expedition.expeditionStatus !== ExpeditionStatusEnum.FINISHED && expedition.expeditionStatus !== ExpeditionStatusEnum.CANCELED}
            isDistributionCreated={expedition.isDistributionCreated === true}
            linkedToSN={expedition.supplierNotificationLink != null}
            firstLocationName={expedition.locations[0].name}
            customsId={expedition.customsLoadingNumber}
          />,
        ]}
      />

      <Ringil3BadgeRow>
        <LinkedSNBadge supplierNotificationLink={expedition.supplierNotificationLink} />
        <DistanceBadge distance={expedition.distance} />
        <PiecesBadge piecesCount={2} />
        <LocationFromBadge locationName={expedition.locations[0].name} />
        <LocationToBadge locationName={expedition.locations[expedition.locations.length - 1].name} />
        <WeightBadge weight={0} />
      </Ringil3BadgeRow>
      <Ringil3BadgeRow>
        <StateTag expeditionState={expedition.expeditionStatus} />
        <ManipulationTag
          value={expedition.loadingTypes}
          onChange={v => {
            editHeaderExpedition(expeditionId, { loadingTypes: { loadingTypes: v as LoadingTypesEnum[] } }, () => load());
          }}
        />

        <TruckTag truckTypeCode={expedition.truckType?.code} onChange={v => editHeaderExpedition(expeditionId, { truckType: { truckTypeCode: v } }, () => load())} />
        <CargoTypeTag
          cargoType={{ cargoTypeCode: expedition.cargoTypeCode, adrWeight: expedition.adrWeight } as CargoType}
          onChange={v => {
            editHeaderExpedition(
              expeditionId,
              {
                cargoType: {
                  cargoTypeCode: v.cargoTypeCode,
                  adrWeight: v.adrWeight,
                },
              },
              () => load(),
            );
          }}
        />
      </Ringil3BadgeRow>
      <Ringil3BadgeRow>
        <RequirementsTag
          valueCodes={expedition.requirementCodes}
          onChange={v => {
            editHeaderExpedition(expeditionId, { specialRequirements: { specialRequirementCodes: v } }, () => load());
          }}
        />
      </Ringil3BadgeRow>
      <Ringil3BadgeRow>
        <CarrierNoteTag
          value={expedition.carrierNote}
          onChange={v => {
            editHeaderExpedition(expeditionId, { carrierNote: { carrierNote: v } }, () => load());
          }}
        />
      </Ringil3BadgeRow>
      <RingilTabs
        onMessagesClick={() => setSelectedCarrierId(expedition.assignedCarrierId)}
        showCustoms={loggedUser?.features?.some(t => t == FeaturesEnum.CUSTOMS && isOwner === true)}
        showCmr={showCmr}
      />

      {selectedPanel === 'expedition' && (
        <>
          {expedition.expeditionStatus === ExpeditionStatusEnum.NEW && <TabExpeditionStateNew expedition={expedition} onReload={() => load()} />}
          {/* assigning */}
          {expedition.expeditionStatus === 'ASSIGNING' && <TabExpeditionQuotes expedition={expedition} onReload={() => load()} />}
          {/* assigning */}
          {isAssignedPlus && <TabExpeditionAssignedPlus expedition={expedition} onReload={() => load()} />}
          <Panel>
            <COHeadline variant={'h2'} title={tr(`ExpeditionDetail.route`, `Route`)} icon={<SvgRoute />}></COHeadline>
            {expedition.locations.map((location, locationIndex) => {
              return (
                <React.Fragment key={locationIndex}>
                  <NavigationBreadCrumb currentPosition={locationIndex} locationCount={expedition.locations.length} idPrefix={'createExp'} />
                  <ExpeditionLocation
                    isLastLocation={locationIndex === expedition.locations.length - 1}
                    location={location}
                    applicationId={expedition.applicationId}
                    expeditionId={expeditionId}
                    allUnloadingLocations={expedition.locations
                      .filter((_, index) => index > locationIndex)
                      .map(t => ({
                        id: t.id,
                        name: t.name,
                      }))}
                    onReload={() => load()}
                  ></ExpeditionLocation>
                </React.Fragment>
              );
            })}
          </Panel>
        </>
      )}
      {selectedPanel === 'customs' && <>{isOwner && <CustomsDetail expeditionId={expedition.id} applicationId={expedition.applicationId} />}</>}
      {selectedPanel === 'internalInfoAndHistory' && (
        <>
          <ExtraCosts costs={expedition.extraCosts} companyId={expedition.customerId} applicationId={expedition.applicationId} onRemoved={() => reloadExtraCosts()} />
          <AuditLog applicationId={expedition.applicationId} customerId={expedition.customerId} />
          {[ExpeditionStatusEnum.NEW, ExpeditionStatusEnum.ASSIGNING].indexOf(expedition.expeditionStatus) === -1 && (
            <TabExpeditionQuotes
              expedition={expedition}
              onReload={() => load()}
              heading={tr('ExpeditionDetail.historyOfTheTenderProcedure', 'History of the tender procedure')}
            />
          )}
          <COHeadline variant={'h3'} title={tr('ExpeditionDetail.interniInfo', 'Interní info')} />
          <div className={'flex flex-col gap-4'}>
            <div className={'flex flex-row gap-4'}>
              <ResponsiblePersonTag
                person={
                  isNotNullOrUndefined(expedition.internalInfo?.responsiblePerson)
                    ? ({
                        name: expedition.internalInfo?.responsiblePerson.userFullName,
                        id: expedition.internalInfo?.responsiblePerson.userId,
                      } as LovDTO)
                    : null
                }
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { responsiblePerson: { responsiblePersonId: v?.id } }, () => load());
                }}
              />

              <InternalInfoNotifyTag
                persons={expedition.internalInfo?.notifiedPerson?.map(t => ({
                  name: t.userFullName,
                  id: t.userId,
                  active: true,
                }))}
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { notifiedPersons: { notifiedPersonIds: v?.map(t => t.id) } }, () => load());
                }}
              />

              <InternalInfoFolderTag
                folder={
                  isNotNullOrUndefined(expedition.internalInfo?.folder)
                    ? ({
                        name: expedition.internalInfo?.folder.folderName,
                        id: expedition.internalInfo?.folder.folderId,
                      } as LovDTO)
                    : null
                }
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { folder: { folderId: v?.id } }, () => load());
                }}
                entityType={EntityTypeEnum.EXPEDITION}
              />

              {/*<InternalInfoNotifyByRoleTag
               notifyRoleName={RoleEnum.ROLE_EXP_NOTIFY}
               isActive={expedition.internalInfo?.notifyByRole}
               onChange={v => {
                 editInternalInfoExpedition(expeditionId, { notifyByRole: { notifyByRole: v?.map(t => t.id) } }, () => load());
               }}
              />*/}
            </div>
            <div className={'flex flex-row gap-4'}>
              <InternalInfoInvoiceState
                value={expedition.internalInfo?.invoiceState}
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { invoiceState: { invoiceState: v } }, () => load());
                }}
              />

              <InternalInfoDisponentTag
                disponentPerson={
                  isNotNullOrUndefined(expedition.internalInfo?.disponentPerson)
                    ? {
                        name: expedition.internalInfo?.disponentPerson.userFullName,
                        id: expedition.internalInfo?.disponentPerson.userId,
                        active: true,
                      }
                    : null
                }
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { disponentPerson: { disponentPersonId: v?.id } }, () => load());
                }}
              />

              <InternalInfoPairingSymbol
                value={expedition.internalInfo?.pairingSymbol}
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { pairingSymbol: { pairingSymbol: v } }, () => load());
                }}
              />

              <InternalNoteTag
                value={expedition.internalInfo?.internalNote}
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { internalNote: { internalNote: v } }, () => load());
                }}
              />

              <InternalInfoRebillingTag
                value={expedition.internalInfo?.rebilling}
                onChange={v => {
                  editInternalInfoExpedition(expeditionId, { rebilling: { rebilling: mapRebilling(v) } }, () => load());
                }}
              />
            </div>
          </div>
        </>
      )}
      {selectedPanel === 'cmr' && (
        <>
          <CMRTableTab expeditionId={expeditionId} />
        </>
      )}

      {selectedPanel === 'attachments' && (
        <>
          <AttachmentComponent entityId={expeditionId} entityType={EntityTypeEnum.EXPEDITION} />
        </>
      )}

      <CustomerChatComponent
        companyId={expedition.customerId}
        entityId={expeditionId}
        entityType={EntityTypeEnum.EXPEDITION}
        visible={isNotNullOrUndefined(selectedCarrierId)}
        selectedCarrierId={selectedCarrierId}
        onHide={() => setSelectedCarrierId(undefined)}
        readOnly={false}
      />
    </Test>
  );
};

const Test = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--ringil3-brand-color);
    background: var(--ringil3-brand-color);
  }
`;

export default ExpeditionDetail;
