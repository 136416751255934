import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { GlobalOrderResponse } from '@api/logsteo-api.v2';
import IconBlock from '@components/ringil3/IconBlock/IconBlock';
import { ErrorText, GrayText, LargeText } from '@components/ringil3/styles';
import { formatPrice, formatWeight, isNotBlank, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import SvgCargoItem from '@components/ringil3/icons/CargoItem';
import SvgGrayInfo from '@components/ringil3/icons/GrayInfo';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import SvgRedInfo from '@components/ringil3/icons/RedInfo.tsx';

interface ComponentProps {
  type: 'loading' | 'unloading' | 'neutral';
  order: GlobalOrderResponse;
  showDiscrepancy?: boolean;
}

const RingilOrder: React.FC<PropsWithChildren<ComponentProps>> = ({ type = 'neutral', order, showDiscrepancy = false }) => {
  const { tr } = useTranslationLgs();

  const calculateOrderWeight = (order: GlobalOrderResponse): number => {
    return order.items.reduce((acc, item) => acc + item.weight, 0);
  };

  return (
    <RingilOrderInner>
      <div className={'mb-3'}>
        <COBadge variant="filled" type={type}>
          {order.orderName}
        </COBadge>
      </div>
      {order.items.map((cargo, cargoIndex) => {
        return (
          <React.Fragment key={cargoIndex}>
            <IconBlock icon={<SvgCargoItem />}>
              {cargo.stackable ? (
                <LargeText>
                  {tr(`RingilOrder.cargoTemplateStackable`, `{{unit}} ({{count}}), stohovalné`, {
                    unit: cargo.cargoTemplate.name,
                    count: cargo.amount,
                  })}
                </LargeText>
              ) : (
                <LargeText>
                  {tr(`RingilOrder.cargoTemplateNonstackable`, `{{unit}} ({{count}}), nestohovalné`, {
                    unit: cargo.cargoTemplate.name,
                    count: cargo.amount,
                  })}
                </LargeText>
              )}
              {showDiscrepancy && cargo.discrepancyAmount === cargo.amount ? (
                <b>
                  {tr(`RingilOrder.cargoTemplateDiscrepancy`, `Reality: {{unit}} ({{count}})`, {
                    unit: cargo.cargoTemplate.name,
                    count: cargo.discrepancyAmount,
                  })}
                </b>
              ) : (
                showDiscrepancy &&
                cargo.discrepancyAmount > 0 && (
                  <ErrorText>
                    {tr(`RingilOrder.cargoTemplateDiscrepancy`, `Reality: {{unit}} ({{count}})`, {
                      unit: cargo.cargoTemplate.name,
                      count: cargo.discrepancyAmount,
                    })}
                  </ErrorText>
                )
              )}
              <GrayText>
                {cargo.weight > 0 && <div>{formatWeight(cargo.weight)}</div>}
                {cargo.length + cargo.height + cargo.width > 0 && (
                  <div>
                    {tr(`RingilOrder.dimensions`, `dimensions: {{length}} x {{height}} x {{width}} (l x h x w)`, {
                      length: cargo.length,
                      height: cargo.height,
                      width: cargo.width,
                    })}
                  </div>
                )}
                {isNotBlank(cargo.itemNote) && <div>{tr('RingilOrder.itemNote', 'Poznámka: {{itemNote}}', { itemNote: cargo.itemNote })}</div>}
              </GrayText>
            </IconBlock>
          </React.Fragment>
        );
      })}
      <IconBlock icon={<SvgGrayInfo />}>
        <div>
          {isNotNullOrUndefined(order.orderAmount) && isNotNullOrUndefined(order.orderDescription) && (
            <GrayText>
              {tr(`RingilOrder.cargoDescription`, `{{cargoDescription}}: {{cargoAmount}} ks`, {
                cargoDescription: order.orderDescription,
                cargoAmount: order.orderAmount,
              })}
            </GrayText>
          )}
          <GrayText>
            {tr(`RingilOrder.orderWeight`, `Celková hmotnost: {{weight}}`, {
              weight: isNullOrUndefined(order.orderTotalWeight) ? formatWeight(calculateOrderWeight(order)) : formatWeight(order.orderTotalWeight),
            })}
          </GrayText>
          {isNotNullOrUndefined(order.orderValue) && isNotNullOrUndefined(order.orderValue?.price) && (
            <GrayText>
              {tr(`RingilOrder.orderValues`, `Hodnota objednávky: {{formattedPrice}}`, {
                formattedPrice: formatPrice(order.orderValue.price, order.orderValue.currency),
              })}
            </GrayText>
          )}

          {isNotBlank(order.orderNote) && (
            <GrayText>
              {tr(`RingilOrder.orderNote`, `Poznamka: {{orderNote}}`, {
                orderNote: order.orderNote,
              })}
            </GrayText>
          )}
        </div>
      </IconBlock>
      {showDiscrepancy && order.discrepancyNote && (
        <IconBlock icon={<SvgRedInfo />}>
          <LargeText>{tr(`RingilOrder.discrepancyNote`, `Discrepancy note`)}</LargeText>
          {order.discrepancyNote}
        </IconBlock>
      )}
    </RingilOrderInner>
  );
};

const RingilOrderInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export default RingilOrder;
