import * as yup from 'yup';
import { RouteType } from './types';
import { DistributionTypeEnum, ExpeditionTypeEnum, WeightModeEnum } from '@api/logsteo-api.v2';
import { timeslotValidationSchema } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot.tsx';

const locationSchema = yup.object({
  name: yup.string().defined().min(1),
  streetNr: yup.string().defined().min(1),
  city: yup.string().defined().min(1),
  country: yup.string().defined().min(1),
  zipCode: yup.string().defined().min(1),
  contactName: yup.string().nullable(),
  contactPhone: yup.string().nullable(),
  contactEmail: yup.string().email().nullable(),
});

export const stateStep2 = yup.object({
  routeType: yup.string().defined().oneOf([RouteType.DIRECT, RouteType.ROUND_TRIP]),
  locations: yup.array().of(locationSchema),
});

export const orderValidation = yup.object({
  amount: yup.number().defined().min(1),
  cargoItemType: yup.object().defined(),
  quantityUnit: yup.string().defined(),
  height: yup.number().defined().min(1),
  width: yup.number().defined().min(1),
  length: yup.number().defined().min(1),
  weight: yup.number().defined().min(0),
  weightMode: yup.string().defined().oneOf([WeightModeEnum.SKU_WEIGHT, WeightModeEnum.TOTAL_WEIGHT]),
});

export const stage2validation = yup.object({
  expeditionType: yup.string().defined().oneOf([ExpeditionTypeEnum.LESS_TRUCK_LOAD, ExpeditionTypeEnum.FULL_TRUCK_LOAD]),
  manipulationTypes: yup.array().min(1).required(),
  truckType: yup.string().nullable().when('expeditionType', {
    is: ExpeditionTypeEnum.FULL_TRUCK_LOAD,
    then: yup.string().defined(),
  }),
  cargoType: yup.string().defined().min(1),
  locations: yup
    .array()
    .nullable()
    .of(
      yup.object(
        {
          timeslots: timeslotValidationSchema,
          loadingOrders: yup
            .array()
            .nullable()
            .of(
              yup.object({
                items: yup.array().of(orderValidation),
              }),
            ),
          /*loadingLocation: yup
              .boolean()
              .test('loadingUnloadingNeeded', 'loadingUnloadingNeeded', (value, context) => {
                const l = context['parent'] as Location;
                return l.loadingLocation || l.unloadingLocation;
              }),*/
        },
        // [['loadingLocation', 'unloadingLocation']]
      ),
    ),
});

export const stage4validation = yup.object({
  distribution: yup.object({
    distributionType: yup.string().when('enabled', {
      is: true,
      then: yup.string().oneOf([DistributionTypeEnum.FIXED_PRICE, DistributionTypeEnum.SPOT]),
    }),
    expectedPrice: yup.number().nullable().when('distributionType', {
      is: DistributionTypeEnum.SPOT,
      then: yup.number(),
    }),
    fixedPrice: yup.number().nullable().when('distributionType', {
      is: DistributionTypeEnum.FIXED_PRICE,
      then: yup.number(),
    }),
    selectedItems: yup.array().length(1),
  }),
});
