import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from '@api/api';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ColWithGap, Row, RowForTagsAndBadges } from '@components/styles';
import DistributionValidTillTag from '@components/framework/tags/DistributionValidTillTag.tsx';
import ExpectedPriceTag from '@components/framework/tags/ExpectedPriceTag.tsx';
import SelectedCarrierTag from '@components/framework/tags/SelectedCarrierTag.tsx';
import CarPlateTag from '@components/framework/tags/CarPlateTag.tsx';
import AssignedDriverTag from '@components/framework/tags/AssignedDriverTag.tsx';
import RouteTable from '@components/ringil3/Features/Expedition/RouteTable/RouteTable';
import { ExpeditionDetailV3OutDTO, ExpeditionDetailV4OutDTO } from '@api/logsteo-api.v2';
import SvgLkw from '@components/ringil3/icons/heading/Lkw';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { createLocalDateTime } from '@utils/date.tsx';

interface ComponentProps {
  expedition: ExpeditionDetailV4OutDTO;
  onReload: () => void;
}

const TabExpeditionAssignedPlus: React.FC<ComponentProps> = ({ expedition, onReload }) => {
  const { tr } = useTranslationLgs();
  const { changeExpeditionDistributionDate } = useContext(ApiContext);

  const saveCarPlate = (carPlate: string) => {
    alert('TODO');
  };

  return (
    <>
      <ColWithGap>
        <COHeadline variant={'h2'} title={tr(`ExpeditionDetail.carrierTitle`, `Carriers`)} icon={<SvgLkw />}></COHeadline>
        <RowForTagsAndBadges>
          <Row>
            <DistributionValidTillTag
              onChanged={v => {
                changeExpeditionDistributionDate(
                  expedition.id,
                  {
                    localDateTime: createLocalDateTime(v),
                    timeZone: v.timeZone,
                  },
                  () => onReload(),
                );
              }}
              readonly={true}
              dateTimeResponse={expedition?.distribution?.validTill}
            />
            <ExpectedPriceTag
              price={expedition.distribution?.price}
              currency={expedition.distribution?.currency}
              expeditionId={expedition.id}
              onReload={onReload}
              readOnly={true}
            />
            <SelectedCarrierTag carrierName={expedition.assignedCarrierName} carrierId={null} companyLocationCustomerId={expedition.customerId} readOnly={false} />
            <CarPlateTag carPlate={expedition.carPlate} onChange={carPlate => saveCarPlate(carPlate)} readOnly={false} />
            <AssignedDriverTag assignedDriverName={expedition.assignedDriver} />
          </Row>
        </RowForTagsAndBadges>
        <RouteTable
          routes={expedition.routes}
          onChanged={() => onReload()}
          applicationId={expedition.applicationId}
          companyId={expedition.customerId}
          expeditionId={expedition.id}
        ></RouteTable>
      </ColWithGap>
    </>
  );
};

export default TabExpeditionAssignedPlus;
