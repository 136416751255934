import React, { Fragment, useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import RingilSidebar from '@components/ringil3/Sidebar/RingilSidebar.tsx';
import useForm from '@hooks/useForm/useForm.tsx';
import * as yup from 'yup';
import { VerticalFieldAndValueWithIcon } from '@components/ringil3/Containers/VerticalFieldAndValueWithIcon.tsx';
import useBEValidation from '@hooks/useBEValidation/useBEValidation.tsx';
import SvgCalendar from '@components/framework/icons/Calendar.tsx';
import SvgForExpedition from '@icons/ForExpedition.tsx';
import { ColWithGap, HorizontalLine } from '@components/styles.tsx';
import RingilInputSwitch from '@components/framework/input/RingilInputSwitch/RingilInputSwitch.tsx';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import SvgRepeatIcon from '@app/icons/admin/icon/RepeatIcon.tsx';
import {
  CarrierGroupInDTO,
  CheckDuplicationExpeditionResponseOutDTO,
  DirectAssignRequestDistributionRequestInDTO,
  DistributionTypeEnum,
  OpeningHourStateEnum,
  OpeningModeEnum,
  ReservationStateEnum,
  SaveDistributionRequestInDTO } from
'@api/logsteo-api.v2.tsx';
import COInlineMessage from '@components/ringil3/COInlineMessage/COInlineMessage.tsx';
import SvgGrayInfo from '@components/ringil3/icons/GrayInfo.tsx';
import { dumpVars, formatLocalDate, isBlank, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils.tsx';
import SvgCheckCircle from '@icons/CheckCircle.tsx';
import COText from '@app/pages/COText/COText.tsx';
import SvgArrowDown from '@icons/ArrowDown.tsx';
import SvgAlert from '@icons/Alert.tsx';
import clsx from 'clsx';
import { useDebounceWithEvents } from '@hooks/useDebounceWithEvents/useDebounceWithEvents.tsx';
import AlertCircle from '@icons/AlertCircle.tsx';
import { remapOpeningHours } from '@components/ringil3/Features/Expedition/DuplicateExpeditionSidebar/common.ts';
import SvgIconFile from '@components/framework/icons/IconFile.tsx';
import SvgArrowUp from '@icons/ArrowUp.tsx';
import { Link } from 'react-router-dom';
import useNavigation from '@hooks/useNavigation.tsx';
import InputSelect from '@components/ringil3/Input/InputSelect/InputSelect.tsx';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue.tsx';
import { findValidationMessage } from '@utils/validation.tsx';
import { SelectCarrier } from '@components/ringil3/Input/InputCarriers/SelectCarrier.tsx';
import InputPriceWithCurrency from '@components/ringil3/Input/InputPriceWithCurrency/InputPriceWithCurrency.tsx';
import { InputCheckbox } from '@components/ringil3/Input/InputCheckbox/InputCheckbox.tsx';
import InputSpotDirect from '@components/ringil3/Input/InputSpotDirect/InputSpotDirect.tsx';
import { SelectCarriers } from '@components/ringil3/Input/InputCarriers/SelectCarriers.tsx';
import InputDateAndTime from '@components/ringil3/Input/InputDateAndTime/InputDateAndTime.tsx';
import dayjs from 'dayjs';
import { AuthenticatedUserContext } from '@components/auth/AuthenticatedUser.tsx';
import RingilButton from '@components/framework/buttons/RingilButton.tsx';
import SvgCoButtonDelete from '@icons/CoButtonDelete.tsx';
import DuplicateDatesButtons from '@components/features/DuplicateDatesButtons/DuplicateDatesButtons.tsx';
import SvgWarningExclamation from '@components/ringil3/icons/WarningExclamation.tsx';
import SvgQuestionMark from '@icons/QuestionMark.tsx';

interface ComponentProps {
  expeditionId: string;
}

enum DistributionMode {
  ASSIGN = 'ASSIGN',
  SPOT_FIXED = 'SPOT_FIXED',
  NONE = 'NONE',
}

interface Distribution {
  distributionType: DistributionTypeEnum;
  price?: number;
  selectedItems: CarrierGroupInDTO[];
  responseDeadline: string;
  responseDeadlineTimezone: string;
  currency: string;
  isPublished: boolean;
}

interface Form {
  duplicateReservations: boolean;
  copyAttachments: boolean;
  fromExpeditionId: string;
  copyToLocalDates: string[];
  distribution?: {
    directAssign?: DirectAssignRequestDistributionRequestInDTO;
    spotDirect?: Distribution;
  };
  distributionMode: DistributionMode;
}

export const useCreateDuplicateExpeditionSidebar = () => {
  const [visible, setVisible] = useState(false);

  const DuplicateExpeditionSidebar: React.FC<ComponentProps> = ({ expeditionId }) => {
    const { checkDuplicationExpedition, saveDuplicationExpedition } = useContext(ApiContext);
    const { tr } = useTranslationLgs();
    const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
    const { BackendValidationComponent: AssignCarrierBV, setBeValidationErrors: setAssignCarrierBV } = useBEValidation(() => {
      setLoading(false);
      setStoring(false);
    });
    const [duplicationCheck, setDuplicationCheck] = useState<CheckDuplicationExpeditionResponseOutDTO>();
    const [loading, setLoading] = useState(false);
    const [openSections, setOpenSections] = useState<Set<string>>(new Set());
    const [storing, setStoring] = useState(false);
    const nav = useNavigation();
    const { loggedUser } = useContext(AuthenticatedUserContext);

    const save = (data: Form) => {
      setStoring(true);
      saveDuplicationExpedition(
        {
          fromExpeditionId: data.fromExpeditionId,
          duplicateReservations: data.duplicateReservations,
          copyAttachments: data.copyAttachments,
          distribution: resolveDistribution(data),
          copyToLocalDates: data.copyToLocalDates
        },
        (d) => {
          setDuplicationCheck(d);
          setStoring(false);
        },
        null,
        {
          onValidationFailed: setAssignCarrierBV
        }
      );
    };

    const resolveDistribution = (data: Form): SaveDistributionRequestInDTO => {
      if (data.distributionMode === DistributionMode.NONE) {
        return null;
      }
      if (data.distributionMode === DistributionMode.ASSIGN) {
        return {
          directAssign: data.distribution.directAssign
        };
      }
      if (data.distributionMode === DistributionMode.SPOT_FIXED && data.distribution.spotDirect.distributionType === DistributionTypeEnum.SPOT) {
        return {
          spotRequest: {
            responseDeadlineTimezone: data.distribution.spotDirect.responseDeadlineTimezone,
            responseDeadlineLocalTime: data.distribution.spotDirect.responseDeadline,
            selectedItems: data.distribution.spotDirect.selectedItems,
            price: { price: data.distribution.spotDirect.price, currency: data.distribution.spotDirect.currency }
          }
        };
      }
      if (data.distributionMode === DistributionMode.SPOT_FIXED && data.distribution.spotDirect.distributionType === DistributionTypeEnum.FIXED_PRICE) {
        return {
          fixedRequest: {
            responseDeadlineTimezone: data.distribution.spotDirect.responseDeadlineTimezone,
            responseDeadlineLocalTime: data.distribution.spotDirect.responseDeadline,
            selectedItems: data.distribution.spotDirect.selectedItems,
            price: { price: data.distribution.spotDirect.price, currency: data.distribution.spotDirect.currency }
          }
        };
      }
    };

    const form = useForm<Form>(
      (d) => {
        if (d.distributionMode == DistributionMode.ASSIGN) {
          return yup.object().shape({
            distributionMode: yup.string().required(),
            distribution: yup.object().shape({
              directAssign: yup.object().shape({
                carrierId: yup.string().required(),
                priceAndCurrency: yup.mixed().test('required', tr(`validation.required`, 'Value is required'), (_, context) => {
                  const value = context.parent;
                  if (isNullOrUndefined(value.price) || isNullOrUndefined(value.price.price) || isNullOrUndefined(value.price.currency)) return false;

                  if (isNaN(value.price.price)) return false;

                  if (value.price.price <= 0) return false;

                  if (isBlank(value.price.currency)) return false;

                  return true;
                })
              })
            })
          });
        } else if (d.distributionMode == DistributionMode.SPOT_FIXED) {
          return yup.object().shape({
            distributionMode: yup.string().required(),
            distribution: yup.object().shape({
              spotDirect: yup.object().shape({
                responseDeadline: yup.
                string().
                required().
                test(
                  'is-future',
                  'isFuture',
                  // @ts-ignore
                  (value) => dayjs(value).isValid() && dayjs(value).isAfter(dayjs(), 'minutes')
                ),
                selectedItems: yup.array().min(1).required()
              })
            })
          });
        }
      },
      {
        duplicateReservations: false,
        copyAttachments: false,
        fromExpeditionId: expeditionId,
        distributionMode: DistributionMode.NONE,
        distribution: null,
        copyToLocalDates: []
      },
      (d) => save(d),
      false,
      false
    );
    const [debouncedFormValues, isDebounced] = useDebounceWithEvents(form.values, 1000);

    useEffect(() => {
      check();
    }, [debouncedFormValues]);

    useEffect(() => {
      setLoading(true);
      checkDuplicationExpedition(
        {
          fromExpeditionId: expeditionId,
          duplicateReservations: false,
          copyAttachments: false,
          copyToLocalDates: []
        },
        (d) => {
          setLoading(false);
          setDuplicationCheck(d);
        }
      );
    }, []);

    const check = () => {
      if (isNullOrUndefined(form.values)) return;

      setLoading(true);
      checkDuplicationExpedition(
        {
          fromExpeditionId: expeditionId,
          duplicateReservations: form.values.duplicateReservations,
          copyAttachments: form.values.copyAttachments,
          copyToLocalDates: form.values.copyToLocalDates
        },
        (d) => {
          setLoading(false);
          setDuplicationCheck(d);
        }
      );
    };

    const deleteDate = (date: string) => {
      form.useProduce((d) => {
        d.copyToLocalDates = d.copyToLocalDates.filter((v) => v !== date);
      });
    };

    const loadSpotDirectFromOriginal = () => {
      form.useProduce((d) => {
        const previous = duplicationCheck.distribution.spot || duplicationCheck.distribution.fixed;
        d.distribution.spotDirect = {
          isPublished: true,
          price: previous.price?.price,
          currency: previous.price?.currency,
          responseDeadlineTimezone: 'Europe/Prague',
          responseDeadline: dayjs().add(8, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
          selectedItems: previous.selectedItems,
          distributionType: isNotNullOrUndefined(duplicationCheck.distribution.spot) !== null ? DistributionTypeEnum.SPOT : DistributionTypeEnum.FIXED_PRICE
        };
      });
    };

    const resolveText = (duplicationCheck: CheckDuplicationExpeditionResponseOutDTO) => {
      if (duplicationCheck?.firstDay?.openingMode === OpeningModeEnum.OPEN) return `${formatLocalDate(duplicationCheck?.firstDay?.localDate)} | non-stop`;
      return `${formatLocalDate(duplicationCheck?.firstDay?.localDate)} | ${duplicationCheck?.firstDay?.intervals.sinceHoursString} - ${
      duplicationCheck?.firstDay?.intervals.tillHoursString}`;

    };

    const toggleOpenSection = (key: string) => {
      const newSet = new Set(openSections);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      setOpenSections(new Set(newSet));
    };

    const hasColision = duplicationCheck?.locations?.
    map((t) => t.duplications)?.
    flat()?.
    some((t) => t.openingHourState === OpeningHourStateEnum.COLLISION);

    const hasConflictingTimeslots: 'NA' | 'TRUE' | 'FALSE' =
    form.values.duplicateReservations && duplicationCheck?.locations?.length > 0 ?
    duplicationCheck?.locations?.
    map((t) => t.duplications)?.
    flat()?.
    some((t) => t.reservationState === ReservationStateEnum.FULL) ?
    'TRUE' :
    'FALSE' :
    'NA';

    return (
      <>
        <RingilSidebar
          visible={visible}
          heading={tr("DuplicateExpeditionSidebar.duplikacePrepravy", "Duplikace přepravy")}
          onBack={() => setVisible(false)}
          onContinue={() => {
            form.validateAndSend();
          }}
          loading={storing}
          showSaveButton={duplicationCheck?.isFinishedState == false && duplicationCheck?.locations?.length > 0}>

          <div className={'flex flex-col gap-6'}>
            <BackendValidationComponent />
            {duplicationCheck?.isFinishedState === true &&
            <div className={'flex flex-col gap-2'}>
                {duplicationCheck.isFinishedState === true &&
              <COInlineMessage variant={'success'} icon={<SvgCheckCircle fill={'#B2B2B2'} />}>
                    Přeprava je vytvořená.
                  </COInlineMessage>
              }

                {duplicationCheck?.isSomeReservationFailed === true &&
              <COInlineMessage variant={'warning'} icon={<SvgGrayInfo />}>
                    Některé rezervace se nepodařilo založit.
                  </COInlineMessage>
              }
              </div>
            }

            <div className={'flex flex-col gap-2'}>
              <VerticalFieldAndValueWithIcon label={tr("DuplicateExpeditionSidebar.vychoziPreprava", "Výchozí přeprava")} valueText={duplicationCheck?.originalApplicationId} icon={<SvgForExpedition />} />
              <VerticalFieldAndValueWithIcon label={tr("DuplicateExpeditionSidebar.zacinajici", "Začínající")} valueText={resolveText(duplicationCheck)} icon={<SvgCalendar />} />
            </div>
            <HorizontalLine variant={'tertiary'} />
            <div className={'flex flex-col gap-2'}>
              <RingilInputSwitch
                label={tr("DuplicateExpeditionSidebar.duplikovatVcetneTerminuRezervaci", "Duplikovat včetně termínů rezervací")}
                value={form.values.duplicateReservations}
                onChange={(v) => {
                  form.useProduce((d) => {
                    d.duplicateReservations = v;
                  });
                }}
                disabled={duplicationCheck?.isReservationsPresent !== true} />

              <RingilInputSwitch
                label={tr("DuplicateExpeditionSidebar.kopirovatPrilohyZPrepravy(fv)", "Kopírovat přílohy z přepravy (FV)")}
                value={form.values.copyAttachments}
                onChange={(v) => {
                  form.useProduce((d) => {
                    d.copyAttachments = v;
                  });
                }}
                disabled={duplicationCheck?.isAttachmentsPresent !== true} />

            </div>
            <HorizontalLine variant={'tertiary'} />

            {duplicationCheck?.locations?.length == 0 ?
            <div className={'flex flex-col gap-2'}>
                <COHeadline variant={'h4'} icon={<SvgRepeatIcon />} title={tr("DuplicateExpeditionSidebar.vytvoritNovou", "Vytvořit novou")} loading={loading} />
                <DuplicateDatesButtons
                startLocalDate={duplicationCheck.firstDay.localDate}
                computedLocalDates={form.values.copyToLocalDates}
                onChanged={(v) =>
                form.useProduce((d) => {
                  d.copyToLocalDates = v;
                })
                } />

                <COInlineMessage variant={'info'} icon={<SvgGrayInfo />}>
                  Všechny termíny na ostatních lokalitách budou posunuté o stejný časový interval jako je nastavený ve výchozí přepravě.{' '}
                </COInlineMessage>
              </div> :

            <>
                {duplicationCheck?.locations.map((loc, locationIndex) => {
                return (
                  <React.Fragment key={locationIndex}>
                      <div className={` m-[-16px] px-4 py-6 gap-6 flex flex-col ${locationIndex % 2 == 1 ? 'bg-base-quaternary' : ''}`}>
                        <div className={'flex flex-row gap-6 items-center '}>
                          <div className={'text-content-brand font-medium min-w[100px]'}>{locationIndex + 1}.</div>
                          <div className={'text-content-secondary'}>
                            <div>{`${loc.postalCode} ${loc.city}`}</div>
                            <div>{loc.locationName}</div>
                          </div>
                        </div>
                        {locationIndex > 0 &&
                      <COInlineMessage variant={'info'} icon={<SvgGrayInfo />}>
                            <COText label={tr("DuplicateExpeditionSidebar.terminyJsouUrceneZacatkemTrasy", "Termíny jsou určené začátkem trasy.")} />
                          </COInlineMessage>
                      }

                        <div className={'grid grid-cols-4 text-black gap-y-4'}>
                          <div className={'text-xs'}>Duplikovat</div>
                          <div className={'text-xs'}>Otevírací doba</div>
                          <div className={'text-xs'}>Rezervace</div>
                          <div></div>
                        </div>
                        {loc.duplications.map((dupl, duplIndex) => {
                        return (
                          <React.Fragment key={duplIndex}>
                              <div key={duplIndex} className={'grid grid-cols-4 text-black gap-y-4'}>
                                <div>{formatLocalDate(dupl.day)}</div>
                                <div className={'flex flex-row gap-2'}>
                                  {dupl.openingHourState === OpeningHourStateEnum.NO_DATA &&
                                <>
                                      <SvgQuestionMark />
                                      <COText label={tr("DuplicateExpeditionSidebar.neuvedeno", "Neuvedeno")} />
                                      {openSections.has(`${locationIndex}-${dupl.day}`) ?
                                  <SvgArrowUp onClick={() => toggleOpenSection(`${locationIndex}-${dupl.day}`)} /> :

                                  <SvgArrowDown onClick={() => toggleOpenSection(`${locationIndex}-${dupl.day}`)} />
                                  }
                                    </>
                                }
                                  {dupl.openingHourState === OpeningHourStateEnum.OK &&
                                <>
                                      <SvgCheckCircle />
                                      <COText label={tr("DuplicateExpeditionSidebar.vPoradku", "V pořádku")} />
                                      {openSections.has(`${locationIndex}-${dupl.day}`) ?
                                  <SvgArrowUp onClick={() => toggleOpenSection(`${locationIndex}-${dupl.day}`)} /> :

                                  <SvgArrowDown onClick={() => toggleOpenSection(`${locationIndex}-${dupl.day}`)} />
                                  }
                                    </>
                                }
                                  {dupl.openingHourState === OpeningHourStateEnum.COLLISION &&
                                <>
                                      <SvgAlert />
                                      <COText label={tr("DuplicateExpeditionSidebar.kolize", "Kolize")} />
                                      {openSections.has(`${locationIndex}-${dupl.day}`) ?
                                  <SvgArrowUp onClick={() => toggleOpenSection(`${locationIndex}-${dupl.day}`)} /> :

                                  <SvgArrowDown onClick={() => toggleOpenSection(`${locationIndex}-${dupl.day}`)} />
                                  }
                                    </>
                                }
                                  {isNotNullOrUndefined(dupl.expeditionLink) &&
                                <>
                                      <Link
                                    to={nav.createNavigationLink(nav.urlFunctions.createExpeditionDetailV2(dupl.expeditionLink.id))}
                                    onClick={(e) => setVisible(false)}>

                                        {dupl.expeditionLink.applicationId}
                                      </Link>
                                    </>
                                }
                                </div>
                                <div className={'flex flex-row gap-2'}>
                                  {dupl.reservationState === ReservationStateEnum.FREE &&
                                <>
                                      <SvgCheckCircle />
                                      <COText label={tr("DuplicateExpeditionSidebar.volno", "Volno")} />
                                    </>
                                }
                                  {dupl.reservationState === ReservationStateEnum.FREE_NOSLOT &&
                                <>
                                      <div className="relative group cursor-pointer">
                                        <SvgAlert />
                                        <div className="w-[100px] absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1">
                                          <COText label={tr("DuplicateExpeditionSidebar.rezervaceNamaCasovySlot", "Rezervace namá časový slot.")} />
                                        </div>
                                      </div>
                                      <COText label={tr("DuplicateExpeditionSidebar.volno", "Volno")} />
                                    </>
                                }
                                  {dupl.reservationState === ReservationStateEnum.FULL &&
                                <>
                                      <AlertCircle />
                                      <COText label={tr("DuplicateExpeditionSidebar.obsazeno", "Obsazeno")} />
                                    </>
                                }
                                  {isNotNullOrUndefined(dupl.reservationLink) &&
                                <>
                                      {dupl.reservationLink.isCreated === true &&
                                  <Link to={nav.createNavigationLink(nav.urlFunctions.createReservationDetail(dupl.reservationLink.id))}>
                                          {dupl.reservationLink.applicationId}
                                        </Link>
                                  }
                                      {dupl.reservationLink.isCreated === false &&
                                  <>
                                          <AlertCircle />
                                          <COText label={tr("DuplicateExpeditionSidebar.nevytvoreno", "Nevytvořeno")} />
                                        </>
                                  }
                                    </>
                                }
                                </div>
                                <div className={'flex flex-row justify-end items-center'}>
                                  <RingilButton mode={'rect-small'} leftIcon={<SvgCoButtonDelete />} onClick={() => deleteDate(dupl.day)} />
                                </div>
                              </div>
                              {openSections.has(`${locationIndex}-${dupl.day}`) &&
                            <div className={'grid grid-cols-2 text-black gap-y-4'}>
                                  <div>
                                    <COText label={tr("DuplicateExpeditionSidebar.naplanovanyTermin", "Naplánovaný termín")} className={'text-xs'} />
                                  </div>
                                  <div>
                                    <COText label={tr("DuplicateExpeditionSidebar.oteviraciDoba", "Otevírací doba")} className={'text-xs'} />
                                  </div>
                                  <div>
                                    {dupl.deliverySlots?.dayWithInterval.
                                reduce((previousValue, currentValue) => {
                                  const newValue = currentValue.intervals.map((i) => {
                                    return {
                                      localDate: currentValue.localDate,
                                      hours: `${i.sinceHoursString} - ${i.tillHoursString}`,
                                      isCollision: currentValue.isCollision
                                    };
                                  });

                                  const newValues =
                                  newValue.length > 0 ?
                                  newValue :
                                  [
                                  {
                                    localDate: currentValue.localDate,
                                    hours: 'non-stop',
                                    isCollision: currentValue.isCollision
                                  }];


                                  return [...previousValue, ...newValues];
                                }, []).
                                map((ds, dsIndex) => {
                                  return (
                                    <Fragment key={dsIndex}>
                                            <div className={'flex flex-row gap-2'}>
                                              {ds.isCollision ? <SvgAlert /> : <SvgCalendar />}
                                              <div className={clsx({ 'text-complementary-draft': ds.isCollision })}>
                                                {formatLocalDate(ds.localDate)} | {ds.hours}
                                              </div>
                                            </div>
                                          </Fragment>);

                                })}
                                  </div>
                                  <div>
                                    {isNotNullOrUndefined(dupl.openingHours) &&
                                remapOpeningHours(dupl.openingHours, tr).map((ds, dsIndex) => {
                                  return (
                                    <Fragment key={dsIndex}>
                                            <div className={'flex flex-row gap-2'}>
                                              <SvgCalendar />
                                              <div>
                                                {formatLocalDate(ds.localDate)} | {ds.hours}
                                              </div>
                                            </div>
                                          </Fragment>);

                                })}
                                    {isNullOrUndefined(dupl.openingHours) &&
                                <div className={'flex flex-row gap-2 text-content-tertiary items-center'}>
                                        <SvgIconFile fill={'var(--ringil-tertiary-color)'} />
                                        <COText label={tr("DuplicateExpeditionSidebar.neuvedeno", "Neuvedeno")} />
                                      </div>
                                }
                                  </div>
                                </div>
                            }
                            </React.Fragment>);

                      })}
                        {locationIndex === 0 &&
                      <>
                            <COHeadline
                          title={tr("DuplicateExpeditionSidebar.duplikovatVNasledujiciTerminech", "Duplikovat v následující termínech")}
                          variant={'h3'}
                          icon={
                          <SvgRepeatIcon
                            className={clsx({
                              'animate-spin': loading || isDebounced
                            })} />

                          } />

                            <DuplicateDatesButtons
                          startLocalDate={duplicationCheck.firstDay.localDate}
                          computedLocalDates={form.values.copyToLocalDates}
                          onChanged={(v) =>
                          form.useProduce((d) => {
                            d.copyToLocalDates = v;
                          })
                          } />

                          </>
                      }
                      </div>
                    </React.Fragment>);

              })}
              </>
            }
            {duplicationCheck?.isFinishedState === false &&
            <div className={'flex flex-col gap-2'}>
                {hasColision === true &&
              <COInlineMessage variant={'warning'} icon={<SvgGrayInfo />}>
                    Máte konflikt o otevírací dobou.
                  </COInlineMessage>
              }
                {hasConflictingTimeslots === 'TRUE' &&
              <COInlineMessage variant={'error'} icon={<SvgWarningExclamation />}>
                    Některé termíny mají obsazené timesloty. Přeprava bude vytvořena, ale nebude mít rezervaci časového okna.
                  </COInlineMessage>
              }
                {hasConflictingTimeslots === 'FALSE' &&
              <COInlineMessage variant={'success'} icon={<SvgCheckCircle fill={'#B2B2B2'} />}>
                    Timesloty pro všechny uvedené termíny termíny jsou dostupné.
                  </COInlineMessage>
              }
              </div>
            }

            {duplicationCheck?.locations?.length > 0 &&
            <>
                <COHeadline title={tr("DuplicateExpeditionSidebar.dopravce", "Dopravce")} variant={'h3'} />
                <InputSelect
                model={[
                { label: 'Nepřiřazovat', code: DistributionMode.NONE },
                { label: 'Přiřadit vybraného', code: DistributionMode.ASSIGN },
                { label: 'Poptat', code: DistributionMode.SPOT_FIXED }]
                }
                onSelect={(v) => {
                  form.useProduce((d) => {
                    d.distributionMode = v as DistributionMode;
                    if (v === DistributionMode.NONE) {
                      d.distribution = null;
                    } else if (v === DistributionMode.ASSIGN) {
                      d.distribution = {
                        directAssign: {
                          carrierId: duplicationCheck.distribution?.assignedCarrierId,
                          notifyAssignedCarrier: true,
                          price: duplicationCheck.distribution?.assignedPrice || { price: 0, currency: 'CZK' }
                        }
                      };
                    } else if (v === DistributionMode.SPOT_FIXED) {
                      d.distribution = {
                        spotDirect: {
                          price: 0,
                          currency: 'CZK',
                          distributionType: DistributionTypeEnum.SPOT,
                          responseDeadline: dayjs().add(8, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
                          selectedItems: [],
                          responseDeadlineTimezone: loggedUser.companyTimeZone,
                          isPublished: true
                        }
                      };
                    }
                  });
                }}
                selectedItemCode={form.values.distributionMode} />

                {form.values.distributionMode === DistributionMode.ASSIGN &&
              <>
                    <ColWithGap>
                      <AssignCarrierBV />
                      <VerticalFieldAndValue
                    label={tr('AddMoreCarriers.chooseCarriersAndCarrierGroupsToAdd', 'Choose carriers and/or carrier groups to add')}
                    labelMode={'normal'}
                    required={true}
                    errorMessage={findValidationMessage(form.validationErrors, 'distribution.directAssign.carrierId', tr)}>

                        <SelectCarrier
                      value={form.values.distribution.directAssign.carrierId}
                      onSelected={(value) => {
                        form.useProduce((d) => {
                          d.distribution.directAssign.carrierId = value;
                        });
                      }}
                      isDisabled={false}
                      placeholder={tr(`AssignCarrierSidebar.selectCarrier`, `Select a carrier`)} />

                      </VerticalFieldAndValue>
                      <VerticalFieldAndValue
                    label={tr('AddMoreCarriers.price', 'Price')}
                    labelMode={'normal'}
                    required={true}
                    errorMessage={findValidationMessage(form.validationErrors, 'distribution.directAssign.priceAndCurrency', tr)}>

                        <InputPriceWithCurrency
                      price={form.values.distribution.directAssign.price.price}
                      currency={form.values.distribution.directAssign.price.currency}
                      changePrice={(v) =>
                      form.useProduce((d) => {
                        d.distribution.directAssign.price.price = v;
                      })
                      }
                      changeCurrency={(v) =>
                      form.useProduce((d) => {
                        d.distribution.directAssign.price.currency = v;
                      })
                      } />

                      </VerticalFieldAndValue>
                      <InputCheckbox
                    value={form.values.distribution.directAssign.notifyAssignedCarrier}
                    onChange={(_, v) =>
                    form.useProduce((d) => {
                      d.distribution.directAssign.notifyAssignedCarrier = v;
                    })
                    }
                    label={tr("DuplicateExpeditionSidebar.informovatOPrirazeniDopravce", "Informovat o přiřazení dopravce")}
                    note={'Dopravci pošleme e-mail s podrobnostmi'} />

                    </ColWithGap>
                  </>
              }
                {form.values.distributionMode === DistributionMode.SPOT_FIXED &&
              <>
                    <RingilButton label={tr("DuplicateExpeditionSidebar.nacistZPuvodniPrepravy", "Načíst z původní přepravy")} mode={'rect-small'} leftIcon={<SvgRepeatIcon />} onClick={() => loadSpotDirectFromOriginal()} />
                    <ColWithGap>
                      <VerticalFieldAndValue
                    label={tr('RequestCarrier.distributionType', 'Distribution type')}
                    labelMode={'normal'}
                    required={true}
                    helpText={tr('RequestCarrier.distributionTypeHelp', 'Distribution type help')}>

                        <InputSpotDirect
                      selectedItemCode={form.values.distribution.spotDirect.distributionType}
                      onSelect={(v) => {
                        //form.setFieldValue(form.names.distributionType, v);
                        form.useProduce((d) => {
                          d.distribution.spotDirect.distributionType = v as DistributionTypeEnum;
                        });
                      }} />

                      </VerticalFieldAndValue>
                      <VerticalFieldAndValue
                    label={tr(`RequestCarrier.price`, `Price`)}
                    labelMode={'normal'}
                    errorMessage={findValidationMessage(form.validationErrors, 'distribution.spotDirect.price', tr)}>

                        <InputPriceWithCurrency
                      currency={form.values.distribution.spotDirect.currency}
                      price={form.values.distribution.spotDirect.price}
                      changeCurrency={(v) => {
                        form.useProduce((d) => {
                          d.distribution.spotDirect.currency = v;
                        });
                      }}
                      changePrice={(v) => {
                        form.useProduce((d) => {
                          d.distribution.spotDirect.price = v;
                        });
                      }} />

                      </VerticalFieldAndValue>
                      <VerticalFieldAndValue
                    label={tr(`RequestCarrier.whoDoYouWantToAddress`, `Who do you want to address?`)}
                    labelMode={'normal'}
                    required={true}
                    errorMessage={findValidationMessage(form.validationErrors, 'distribution.spotDirect.selectedItems', tr)}>

                        <SelectCarriers
                      value={form.values.distribution.spotDirect.selectedItems}
                      onlyCarriers={false}
                      onSelected={(value) => {
                        form.useProduce((d) => {
                          d.distribution.spotDirect.selectedItems = value;
                        });
                      }}
                      isDisabled={false}
                      placeholder={tr(`RequestCarrier.selectACarrierOrGroup`, `Select a carrier or group`)} />

                      </VerticalFieldAndValue>

                      <VerticalFieldAndValue
                    label={tr(`RequestCarrier.byWhenDoYouNeedAQuotation`, `By when do you need a quotation`)}
                    labelMode={'normal'}
                    required={true}
                    errorMessage={findValidationMessage(form.validationErrors, 'distribution.spotDirect.responseDeadline', tr)}>

                        <InputDateAndTime
                      value={form.values.distribution.spotDirect.responseDeadline}
                      onChange={(v) => {
                        /*form.setFieldValue(form.names.responseDeadline, v);*/
                        form.useProduce((d) => {
                          d.distribution.spotDirect.responseDeadline = v;
                        });
                      }}
                      timeZone={loggedUser.companyTimeZone}
                      onChangeTimeZone={(v) => {}} />

                      </VerticalFieldAndValue>
                    </ColWithGap>
                  </>
              }
              </>
            }
          </div>
        </RingilSidebar>
      </>);

  };

  return { DuplicateExpeditionSidebar, visible, setVisible };
};