import React, { useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import ButtonTag from '@components/framework/tags/ButtonTag.tsx';
import SvgCargotype from '@components/ringil3/icons/Cargotype.tsx';
import { CargoType, InputCargoType } from '@components/framework/input/InputCargoType/InputCargoType.tsx';
import MultipleValues from '@components/ringil3/HelperComponents/MultipleValues.tsx';
import { formatWeight } from '@utils/utils.tsx';
import { CargoTypeCodeEnum } from '@utils/constants.ts';

interface ComponentProps {
  cargoType?: CargoType;
  onChange: (cargoType: CargoType) => void;
}

const CargoTypeTag: React.FC<ComponentProps> = ({ cargoType, onChange }) => {
  const { tr } = useTranslationLgs();
  const [internalValue, setInternalValue] = useState(cargoType);

  const formatValue = (cargoType: CargoType) => {
    if (cargoType == null) return null;

    if (cargoType.cargoTypeCode == CargoTypeCodeEnum.ADR_LQ) {
      return MultipleValues({ values: [tr('CargoType.ADR_LQ', 'ADR_LQ'), formatWeight(cargoType.adrWeight)] });
    }

    return tr(`CargoType.${cargoType.cargoTypeCode}`, `CargoType.${cargoType.cargoTypeCode}`);
  };

  return (
    <>
      <ButtonTag
        label={tr("CargoTypeTag.naklad", "Náklad")}
        value={formatValue(cargoType)}
        icon={<SvgCargotype />}
        onSave={(onSuccess) => {
          onSuccess();
          onChange(internalValue);
        }}
        autoClose>

        <InputCargoType value={internalValue} onChange={(v) => setInternalValue(v)} />
      </ButtonTag>
    </>);

};

export default CargoTypeTag;