import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ExpeditionDetail from '@components/ringil3/Features/Expedition/ExpeditionDetail';
import { useParams } from 'react-router-dom';
import Layout from '@app/layout/Layout.tsx';
import useTranslationLgs from '@hooks/i18n/useTranslation.tsx';
import CreateExpedition from '@app/pages/expedition/create/CreateExpedition.tsx';

interface ComponentProps {}

const CreateExpeditionPage: React.FC<ComponentProps> = () => {
  const { expeditionId } = useParams();
  const { tr } = useTranslationLgs();

  return (
    <>
      <Layout title={tr("create.vytvoreniExpedice", "Vytvoření expedice")}>
        <CreateExpedition />
      </Layout>
    </>);

};

export default withAuthenticationRequired(CreateExpeditionPage);