import React from 'react';
import styled from 'styled-components';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { Card, HorizontalLine } from '@components/ringil3/styles';
import { LocationPreviewV4OutDTO } from '@api/logsteo-api.v2';
import EditableHeading3 from '@components/ringil3/Heading/EditableHeading3';
import { formatLocationNotino, isNotBlank, isNotNullOrUndefined, isNullOrUndefined } from '@utils/utils';
import { printDeliverySlots } from '@components/obsolete/TimeslotWithDefaults/ManageTimeslot';
import LoadingReferenceBadge from '@components/ringil3/Badges/LoadingReferenceBadge';
import RingilOrder from '@components/ringil3/RingilOrder/RingilOrder';
import SvgMappoint from '@components/ringil3/icons/Mappoint';
import SvgCalendar from '@components/ringil3/icons/Calendar';
import SvgContacts from '@components/ringil3/icons/Contacts';
import SvgLoading from '@components/ringil3/icons/Loading';
import SvgUnloading from '@components/ringil3/icons/Unloading';
import COHeadline from '@components/framework/headlines/COHeadline.tsx';
import { useCreateEditExpeditionLocation } from '@components/ringil3/Features/Expedition/Sidebars/EditLocationAndContactSidebar.tsx';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import { useCreateEditManualExpeditionLocation } from '@components/ringil3/Features/Expedition/Sidebars/EditManualLocationAndContactSidebar.tsx';
import { useCreateEditExpeditionTimeSlotsSidebar } from '@components/ringil3/Features/Expedition/Sidebars/EditExpeditionTimeSlotsSidebar.tsx';
import { useCreateEditLoadingOrderSidebar } from '@components/ringil3/Features/Expedition/Sidebars/EditLoadingOrdersSidebar.tsx';
import { Order } from '@components/framework/orders/common.tsx';
import COText from '@app/pages/COText/COText.tsx';
import AddButton from '@components/framework/buttons/AddButton.tsx';

interface ComponentProps {
  location: LocationPreviewV4OutDTO;
  applicationId: string;
  expeditionId: string;
  allUnloadingLocations: {id: string;name: string;}[];
  onReload: () => void;
  isLastLocation: boolean;
}

const ExpeditionLocation: React.FC<ComponentProps> = ({ location, applicationId, expeditionId, allUnloadingLocations, onReload, isLastLocation }) => {
  const { tr } = useTranslationLgs();
  const { visible: visibleEditLocation, setVisible: setVisibleEditLocation, EditExpeditionLocationSidebar } = useCreateEditExpeditionLocation();
  const { visible: visibleEditManualLocation, setVisible: setVisibleEditManualLocation, EditExpeditionManualLocationSidebar } = useCreateEditManualExpeditionLocation();
  const { visible: visibleEditTimeSlot, setVisible: setVisibleEditSlotLocation, EditExpeditionTimeSlotsSidebar } = useCreateEditExpeditionTimeSlotsSidebar();
  const { visible: visibleEditOrders, setVisible: setVisibleEditOrders, EditLoadingOrderSidebar } = useCreateEditLoadingOrderSidebar();

  const canLoadNew = (location: LocationPreviewV4OutDTO) => {
    if (isLastLocation) return false;
    return isNullOrUndefined(location.loadedOrders) || location.loadedOrders.length === 0;
  };

  return (
    <ExpeditionLocationInner>
      <Card>
        <Grid>
          <Col>
            <EditableHeading3 editable={false} title={location.name} icon={<SvgMappoint />}></EditableHeading3>
            <div>{formatLocationNotino(location.postalCode, location.city, location.country, location.streetNr)}</div>
            {isNotBlank(location?.loadingReference) && <LoadingReferenceBadge loadingReference={location.loadingReference} />}
          </Col>
          <Col>
            <EditableHeading3
              editable={true}
              title={tr("ExpeditionLocation.kontakty", "Kontakty")}
              icon={<SvgContacts />}
              onEditClick={() => {
                if (location.companyLocationId) {
                  setVisibleEditLocation(true);
                } else {
                  setVisibleEditManualLocation(true);
                }
              }}>
            </EditableHeading3>
            {isNotNullOrUndefined(location.selectedPerson) &&
            <div>
                <div>{location.selectedPerson.contactName}</div>
                <div>{location.selectedPerson.contactPhone}</div>
                <div>{location.selectedPerson.contactEmail}</div>
              </div>
            }
            {location.additionalContacts.length > 0 &&
            <AdditionalContacts>
                <COHeadline variant={'h5'} title={tr("ExpeditionLocation.notifikovatDalsiOsoby", "Notifikovat dalsi osoby")}></COHeadline>
                {location.additionalContacts.map((p, pIndex) => {
                return (
                  <Contact key={pIndex}>
                      <div>{p.contactName}</div>
                      <div>{p.contactPhone}</div>
                      <div>{p.contactEmail}</div>
                    </Contact>);

              })}
              </AdditionalContacts>
            }
          </Col>
        </Grid>
        <div className={'py-3'}>
          <HorizontalLine variant={'tertiary'} />
        </div>
        <div>
          <Col>
            <EditableHeading3
              editable={true}
              title={tr(`ExpeditionLocation.ocekavanaManipulace`, `Ocekavana manipulace`)}
              icon={<SvgCalendar />}
              onEditClick={() => setVisibleEditSlotLocation(true)}>
            </EditableHeading3>
            <div>{printDeliverySlots(location.deliverySlot, tr)}</div>
          </Col>
        </div>
      </Card>
      {(location?.loadedOrders?.length > 0 || location?.unloadedOrders?.length > 0) &&
      <Card>
          <Col>
            {location.loadedOrders.length > 0 &&
          <>
                <EditableHeading3 editable={true} title={tr("ExpeditionLocation.nakladka", "Nakladka")} icon={<SvgLoading />} onEditClick={() => setVisibleEditOrders(true)}></EditableHeading3>
                <COBadge variant="filled" type={'loading'}>
                  <COText label={tr("ExpeditionLocation.nakladka", "Nakládka")} />
                </COBadge>
                <div className={'flex flex-col gap-4'}>
                  {location.loadedOrders.map((order, orderIndex) => {
                return <RingilOrder key={orderIndex} type={'loading'} order={order} />;
              })}
                </div>
              </>
          }
            {location.loadedOrders.length > 0 && location.unloadedOrders.length > 0 && <HorizontalLine variant={'quaternary'} />}

            {location.unloadedOrders.length > 0 &&
          <>
                <EditableHeading3 editable={false} title={tr("ExpeditionLocation.vykladka", "Vykladka")} icon={<SvgUnloading />}></EditableHeading3>
                <COBadge variant="filled" type={'unloading'}>
                  <COText label={tr("ExpeditionLocation.vykladka", "Vykládka")} />
                </COBadge>
                <div className={'flex flex-col gap-4'}>
                  {location.unloadedOrders.map((order, orderIndex) => {
                return <RingilOrder key={orderIndex} type={'unloading'} order={order} />;
              })}
                </div>
              </>
          }
          </Col>
        </Card>
      }

      {canLoadNew(location) &&
      <div className={'flex flex-col gap-4'}>
          <div className={'flex flex-col gap-2'}>
            <AddButton
            title={tr("ExpeditionLocation.nakladka", "Nakladka")}
            fullWidth
            onClick={() => {
              setVisibleEditOrders(true);
            }}
            type={'ROUNDED'} />

          </div>
        </div>
      }

      {/**/}
      {visibleEditLocation === true &&
      <EditExpeditionLocationSidebar
        applicationId={applicationId}
        expeditionId={expeditionId}
        companyLocationId={location.companyLocationId}
        locationId={location.id}
        location={location}
        onContinue={() => {
          setVisibleEditLocation(false);
        }} />

      }
      {visibleEditManualLocation === true &&
      <EditExpeditionManualLocationSidebar
        applicationId={applicationId}
        expeditionId={expeditionId}
        locationId={location.id}
        location={location}
        onContinue={() => {
          setVisibleEditLocation(false);
        }} />

      }
      {visibleEditTimeSlot === true &&
      <EditExpeditionTimeSlotsSidebar
        localityName={'sdfsdf'}
        applicationId={applicationId}
        expeditionId={expeditionId}
        locationId={location.id}
        companyLocationId={location.companyLocationId}
        timeslots={location.deliverySlot}
        onContinue={() => {
          setVisibleEditSlotLocation(false);
        }} />

      }
      {visibleEditOrders &&
      <EditLoadingOrderSidebar
        onContinue={() => {
          onReload();
        }}
        allUnloadingLocations={allUnloadingLocations}
        applicationId={applicationId}
        expeditionId={expeditionId}
        localityName={location.name}
        locationId={location.id}
        orders={location.loadedOrders.map(
          (t) =>
          ({
            orderName: t.orderName,
            items: t.items.map((t) => ({
              cargoTemplateCode: t.cargoTemplate?.code,
              stackable: t.stackable,
              amount: t.amount,
              height: t.height,
              itemNote: t.itemNote,
              length: t.length,
              weight: t.weight,
              width: t.width
            })),
            id: t.id,
            orderAmount: t.orderAmount,
            orderDescription: t.orderDescription,
            orderValue: t.orderValue,
            orderNote: t.orderNote,
            unloadingLocationId: t.unloadingLocationId
          }) as Order
        )} />

      }
    </ExpeditionLocationInner>);

};

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const AdditionalContacts = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const ExpeditionLocationInner = styled.div`
  /*  margin: 1rem 0;
    gap: 1rem;
    display: flex;*/
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  display: grid;
  margin: 1rem 0;
  gap: 1rem;
`;

export default ExpeditionLocation;