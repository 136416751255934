import * as yup from 'yup';
import { generateUUID } from '@utils/utils.tsx';
import { PriceRequest } from '@api/logsteo-api.v2.tsx';

export interface Order {
  id?: string;
  orderName: string;
  items: OrderItem[];
  orderNote?: string;
  orderValue?: PriceRequest;
  orderAmount: number;
  orderDescription: string;
  unloadingLocationId?: string;
  orderTotalWeight?: number;
  orderDiscrepancyNote?: string;
}

export interface OrderItem {
  id?: string;
  amount: number;
  cargoTemplateCode: string;
  stackable: boolean;
  itemNote?: string;
  height?: number;
  width?: number;
  length?: number;
  weight?: number;
  discrepancyAmount?: number;
}

export const emptyOrder = (addItem: boolean, generateOrderId: boolean = false): Order => {
  if (generateOrderId) {
    return {
      id: generateUUID(),
      orderName: '',
      items: addItem ? generateOrderItem() : [],
      orderAmount: null,
      orderDescription: null,
    };
  } else {
    return {
      orderName: '',
      items: addItem ? generateOrderItem() : [],
      orderAmount: null,
      orderDescription: null,
    };
  }
};

export const generateOrderItem = () => {
  return [{ amount: null, cargoTemplateCode: null } as OrderItem];
};

export const orderSchema = yup.array().of(
  yup.object().shape({
    orderName: yup.string().required().min(1),
    items: yup.array().of(
      yup.object().shape({
        cargoTemplateCode: yup.string().required(),
        amount: yup.number().required(),
      }),
    ),
  }),
);
