import React, { useContext, useEffect, useState } from 'react';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { ApiContext } from '@api/api';
import {
  CompanyLocationExceptionPreviewOutDTO,
  CompanyLocationRampWithOpeningHoursOutDTO,
  CompanyLocationsDetailOutDTO,
  DayEnum,
  ExceptionTimeIntervalOutDTO,
  LocationListPreviewOutDTO,
  OpeningHoursDayDTO,
  OpeningHoursDTO,
  OpeningModeEnum } from
'@api/logsteo-api.v2';
import { BlueIcon, Heading, HeadingRow, Note, RingilHeading } from '@components/styles.tsx';
import styled from 'styled-components';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { dateFormatOnlyDateShort, mapFromAPIDateTime } from '@utils/date';
import { dumpVars, getTime, isNullOrUndefined } from '@utils/utils';
import { Chip } from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
import useNavigation from '@hooks/useNavigation.tsx';
import { BadgeRow } from '@components/obsolete/Badge/Badge.tsx';
import VisibilityBadge from '@app/pages/customer/LocalityList/component/VisibilityBadge.tsx';
import HorizontalFieldValue from '@components/obsolete/HorizontalFieldValue.tsx';
import ConfirmDialog from '@components/obsolete/ConfirmDialog/ConfirmDialog.tsx';
import AddEditLocationSidebar from '@app/pages/customer/LocalityList/sidebars/AddEditLocationSidebar.tsx';
import AddEditRamp from '@app/pages/customer/LocalityList/sidebars/AddEditRamp.tsx';
import EditOpeningHours from '@app/pages/customer/LocalityList/sidebars/EditOpeningHours.tsx';
import COBadge from '@components/ringil3/COBadge/COBadge.tsx';
import COText from '@app/pages/COText/COText.tsx';

interface ComponentProps {
  localityId: string;
}

interface I18NLabels {
  i18n: string;
  value: string;
}

const LocalityDetail: React.FC<ComponentProps> = ({ localityId }) => {
  const { tr } = useTranslationLgs();
  const { getCompanyLocationById, getExceptionsForCompanyLocation, deleteRampForLocation } = useContext(ApiContext);
  const [locationDetail, setLocationDetail] = useState<CompanyLocationsDetailOutDTO>();
  const [localityExceptions, setLocalityExceptions] = useState<CompanyLocationExceptionPreviewOutDTO[]>();
  const [visibleEditLocalityId, setVisibleEditLocalityId] = useState(undefined);
  const [visibleAddRamp, setVisibleAddRamp] = useState(false);
  const [ramp2Delete, setRamp2Delete] = useState(undefined);
  const [editRampId, setEditRampId] = useState(undefined);
  const [visibleEditOpeningHours, setVisibleEditOpeningHours] = useState(false);

  const navigation = useNavigation();

  useEffect(() => {
    load();
  }, [localityId]);

  const load = () => {
    getCompanyLocationById(localityId, (data) => {
      setLocationDetail(data);
    });
    getExceptionsForCompanyLocation(localityId, (data) => {
      setLocalityExceptions(data);
    });
  };
  const renderOpeningTime = (isClosed: boolean, openingTime?: ExceptionTimeIntervalOutDTO[]) => {
    if (isClosed) return tr('ExceptionList.closed', 'Closed');
    return openingTime.map((item, index) => `${getTime(mapFromAPIDateTime(item.since))} - ${getTime(mapFromAPIDateTime(item.till))}`).join(', ');
  };

  const renderLocalities = (localities: LocationListPreviewOutDTO[]) => {
    if (localities.length == 0) return tr('ExceptionList.allLocations', 'All locations');
    return localities.map((item, index) => <div key={index}>{item.localityName}</div>);
  };

  const renderRamps = (localities: LocationListPreviewOutDTO[]) => {
    if (localities.length == 0) return tr('ExceptionList.allRamps', 'All ramps');
    return localities.map((item, index) =>
    <RampList key={index}>
        {item.rampsNames.length == 0 ? tr('ExceptionList.allRamps', 'All ramps') : item.rampsNames.map((ramp, rIndex) => `${ramp}`).join(', ')}
      </RampList>
    );
  };

  const renderTimes = (intervals: OpeningHoursDTO[], isAllDay: boolean) => {
    if (isNullOrUndefined(intervals)) return <div>{tr(`LocalityDetail.closed`, `Closed`)}</div>;
    if (isAllDay) return <div>{tr(`LocalityDetail.allDay`, `non-stop`)}</div>;
    return intervals.map((t, index) =>
    <div key={index}>
        {t.since} - {t.till}
      </div>
    );
  };

  const renderOpeningHoursForDay = (day: OpeningHoursDayDTO) => {
    if (day == null) return <div></div>;
    if (day.openingMode === OpeningModeEnum.CLOSED) return <div>{tr(`LocalityDetail.closed`, `Closed`)}</div>;
    if (day.openingMode === OpeningModeEnum.OPEN) return <div>{tr(`LocalityDetail.allDay`, `non-stop`)}</div>;
    return day.openingHours.map((t, index) =>
    <div key={index}>
        {t.since} - {t.till}
      </div>
    );
  };

  // confirmID of deleting ramp
  const [confirmDeletingOfRamp, setConfirmDeletingOfRamp] = useState(undefined);

  interface ActionNeeded {
    action: string;
    className: string;
    data: {count: number;localizedMessage: string;};
  }

  const deleteRamp = (rampId: string) => {
    deleteRampForLocation(
      localityId,
      rampId,
      false,
      false,
      () => {
        load();
      },
      null,
      {
        onAdditionalAction: (data: ActionNeeded) => {
          if (data.action === 'FORCE_NEEDED') setConfirmDeletingOfRamp(rampId);
        }
      }
    );
  };

  const softDeleteRamp = (rampId: string) => {
    deleteRampForLocation(
      localityId,
      rampId,
      false,
      true,
      () => {
        load();
      },
      null,
      {
        onAdditionalAction: (data: ActionNeeded) => {
          if (data.action === 'FORCE_NEEDED') setConfirmDeletingOfRamp(rampId);
        }
      }
    );
  };

  const forceDeleteRamp = (rampId: string) => {
    deleteRampForLocation(localityId, rampId, true, false, () => {
      load();
    });
  };

  const renderContacts = (a: I18NLabels[]) => {
    const b = a.
    filter((t) => !isNullOrUndefined(t.value) && t.value !== '').
    map((t) => ({
      name: isNullOrUndefined(t.i18n) ? null : tr(`LocalityDetail.i18n.${t.i18n}`, `LocalityDetail.i18n.${t.i18n}`),
      value: t.value
    })).
    map((t) => {
      const s = isNullOrUndefined(t.name) ? t.value : `${t.name}: ${t.value}`;
      return s;
    }).
    join(' | ');

    return b;
  };

  return (
    <>
      {locationDetail &&
      <>
          <HeadingRow>
            <RingilHeading>{locationDetail.name}</RingilHeading>

            <Gapped>
              <Button label={tr(`LocalityDetail.newRamp`, `New ramp`)} icon={'pi pi-plus'} onClick={(e) => setVisibleAddRamp(true)} />
              <Button
              label={tr(`LocalityDetail.updateLocality`, `Update locality`)}
              onClick={(e) => {
                setVisibleEditLocalityId(locationDetail.id);
              }} />

            </Gapped>
          </HeadingRow>
          <BadgeRow>
            <VisibilityBadge acl={locationDetail.acl} />
          </BadgeRow>
          <AddressRow>
            <Adress>
              {locationDetail.streetNr}, {locationDetail.postalCode}, {locationDetail.city}, {locationDetail.country}
            </Adress>
            <Contacts>
              {renderContacts([
            {
              i18n: null,
              value: locationDetail.contactName
            },
            { i18n: 'contactEmail', value: locationDetail.contactEmail },
            {
              i18n: 'contactPhone',
              value: locationDetail.contactPhone
            }] as
            I18NLabels[])}
            </Contacts>
          </AddressRow>
          <div>
            {tr(`LocalityDetail.concurrentlyActiveEventsCount`, `concurrentlyActiveEventsCount: {{concurrentlyActiveEventsCount}}`, {
            concurrentlyActiveEventsCount: locationDetail.concurrentlyActiveEventsCount
          })}
          </div>
          <HorizontalFieldValue label={tr(`LocalityDetail.locationId`, `Location Id`)} value={locationDetail.id} />

          <TableHeading>
            <Heading>{tr(`LocalityDetail.contacts2`, `Contacts`)}</Heading>
          </TableHeading>
          <TableWrapper>
            {/*
            // @ts-ignore*/}
            <DataTable value={locationDetail.contacts} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
              <Column header={tr(`LocalityDetail.contactName`, `Contact name`)} field={'contactName'} />
              <Column header={tr(`LocalityDetail.contactEmail`, `Contact email`)} field={'contactEmail'} />
              <Column header={tr(`LocalityDetail.contactPhone`, `Contact phone`)} field={'contactPhone'} />
            </DataTable>
          </TableWrapper>
          <TableHeading>
            <Heading>{tr(`LocalityDetail.openingHours`, `Opening hours`)}</Heading>
            <div>
              <Button
              label={tr(`LocalityDetail.updateOpeningHours`, `Update opening hours`)}
              className={'p-button-outlined'}
              onClick={(e) => setVisibleEditOpeningHours(true)} />

            </div>
          </TableHeading>

          <TableWrapper>
            <div className={'p-datatable p-component p-datatable-responsive-stack'}>
              <div className="p-datatable-wrapper">
                <table className="p-datatable-table" role="table">
                  <thead className="p-datatable-thead">
                    <tr role="row">
                      {locationDetail.openingHourDefinitions.openingHoursDays.map((ohd, ohIndex) => {
                      return (
                        <th className="" role="columnheader" key={ohIndex}>
                            <div className="p-column-header-content">
                              <span className="p-column-title">{tr(`TimeIntervalComponent.days${ohd.day}`, ohd.day)}</span>
                            </div>
                          </th>);

                    })}
                    </tr>
                  </thead>
                  <tbody className="p-datatable-tbody">
                    {!(locationDetail.openingHourDefinitions?.openingHoursDays.length > 0) ?
                  <tr className="p-datatable-emptymessage" role="row">
                        <td colSpan={7} role="cell">
                          No available options
                        </td>
                      </tr> :

                  <tr>
                        {locationDetail.openingHourDefinitions.openingHoursDays.map((ohd, ohIndex) => {
                      return (
                        <td role={'cell'} key={ohIndex}>
                              {renderOpeningHoursForDay(ohd)}
                            </td>);

                    })}
                      </tr>
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </TableWrapper>

          <TableHeading>
            <Heading>{tr(`LocalityDetail.exceptionsInNext30Days`, `Exceptions in next 30 days`)}</Heading>
            <div>
              <Button
              label={tr(`LocalityDetail.manageExceptions`, `Manage exceptions >`)}
              className={'p-button-outlined'}
              onClick={(e) => navigation.navigate(navigation.urlFunctions.createExceptionList())} />

            </div>
          </TableHeading>
          <TableWrapper>
            {/*
            // @ts-ignore*/}
            <DataTable value={localityExceptions} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
              <Column
              style={{ width: '9rem' }}
              header={tr(`LocalityDetail.dateFrom`, `Date from`)}
              body={(row: CompanyLocationExceptionPreviewOutDTO) =>
              <div>
                    {row.dates.map((t, index) =>
                <div key={index}>{dateFormatOnlyDateShort(mapFromAPIDateTime(t))}</div>
                )}
                  </div>
              }>
            </Column>
              <Column
              header={tr(`LocalityDetail.exception`, `Exception`)}
              body={(row: CompanyLocationExceptionPreviewOutDTO) =>
              <div>
                    <div>{renderOpeningTime(row.closed, row.openingHours)}</div>
                    <div>{row.exceptionName}</div>
                  </div>
              }>
            </Column>
              <Column
              header={tr(`LocalityDetail.locality`, `Locality`)}
              body={(row: CompanyLocationExceptionPreviewOutDTO) => <div>{renderLocalities(row.localities)}</div>}>
            </Column>
              <Column
              header={tr(`LocalityDetail.ramps`, `Ramps`)}
              body={(row: CompanyLocationExceptionPreviewOutDTO) => <div>{renderRamps(row.localities)}</div>}>
            </Column>
              <Column
              header={tr(`LocalityDetail.createdBy`, `Created by`)}
              body={(row: CompanyLocationExceptionPreviewOutDTO) =>
              <div>
                    <div>{row.createdBy}</div>
                    <div>{dateFormatOnlyDateShort(mapFromAPIDateTime(row.createdAt))}</div>
                  </div>
              }>
            </Column>
            </DataTable>
          </TableWrapper>
          <Heading>{tr(`LocalityDetail.ramps`, `Ramps`)}</Heading>
          {/*
          // @ts-ignore*/}
          <DataTable value={locationDetail.ramps} emptyMessage={tr(`global.noRecordFound`, `Nebyly nalezeny žádné záznamy`)}>
            <Column
            header={tr(`LocalityDetail.ramp`, `Ramp`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>
                  <div className={'flex flex-row gap-2'}>
                    {row.name}
                    {row.isSoftDeleted &&
                <COBadge variant={'filled'} type={'neutral'}>
                        <COText label={tr("LocalityDetail.smazano", "Smazáno")} />
                      </COBadge>
                }
                  </div>

                  {row.isPrivate && <Chip label={tr(`LocalityDetail.privateRamp`, `Private`)}></Chip>}
                  {row.isShowOnRampDashboard && <Chip label={tr(`LocalityDetail.showOnPanel`, `on panel`)}></Chip>}
                  <Note>{row.rampId}</Note>
                </div>
            }
            filter={true}
            filterElement={() => <InputText />}>
          </Column>
            <Column
            header={tr(`LocalityDetail.day.mo`, `Mo`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>{renderOpeningHoursForDay(row.openingHourDefinitions.openingHoursDays.find((t) => t.day === DayEnum.MONDAY))}</div>
            }>
          </Column>
            <Column
            header={tr(`LocalityDetail.day.tu`, `Tu`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>{renderOpeningHoursForDay(row.openingHourDefinitions.openingHoursDays.find((t) => t.day === DayEnum.TUESDAY))}</div>
            }>
          </Column>
            <Column
            header={tr(`LocalityDetail.day.we`, `We`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>{renderOpeningHoursForDay(row.openingHourDefinitions.openingHoursDays.find((t) => t.day === DayEnum.WEDNESDAY))}</div>
            }>
          </Column>
            <Column
            header={tr(`LocalityDetail.day.th`, `Th`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>{renderOpeningHoursForDay(row.openingHourDefinitions.openingHoursDays.find((t) => t.day === DayEnum.THURSDAY))}</div>
            }>
          </Column>
            <Column
            header={tr(`LocalityDetail.day.fr`, `Fr`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>{renderOpeningHoursForDay(row.openingHourDefinitions.openingHoursDays.find((t) => t.day === DayEnum.FRIDAY))}</div>
            }>
          </Column>
            <Column
            header={tr(`LocalityDetail.day.sa`, `Sa`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>{renderOpeningHoursForDay(row.openingHourDefinitions.openingHoursDays.find((t) => t.day === DayEnum.SATURDAY))}</div>
            }>
          </Column>
            <Column
            header={tr(`LocalityDetail.day.su`, `Su`)}
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>{renderOpeningHoursForDay(row.openingHourDefinitions.openingHoursDays.find((t) => t.day === DayEnum.SUNDAY))}</div>
            }>
          </Column>
            <Column
            body={(row: CompanyLocationRampWithOpeningHoursOutDTO) =>
            <div>
                  <Link>
                    <BlueIcon className={'pi pi-pencil'} onClick={(e) => setEditRampId(row.rampId)} />
                  </Link>
                  <Link>
                    <BlueIcon className={'pi pi-trash'} onClick={(e) => setRamp2Delete(row.rampId)} />
                  </Link>
                  {!row.isSoftDeleted &&
              <Link>
                      <BlueIcon className={'pi pi-eye-slash'} onClick={(e) => softDeleteRamp(row.rampId)} />
                    </Link>
              }
                </div>
            }>
          </Column>
          </DataTable>
        </>
      }
      <ConfirmDialog
        visible={confirmDeletingOfRamp}
        dialogTitle={tr('LocalityDetail.confirmTheAction', 'Confirm the action')}
        dialogMessage={tr(
          'LocalityDetail.selectedRampContainsReservationsDoYouWantToDeleteTheRamp',
          'Selected ramp contains reservations. Do you want to delete the ramp?'
        )}
        onConfirm={() => {
          forceDeleteRamp(confirmDeletingOfRamp);
          setConfirmDeletingOfRamp(undefined);
        }}
        onCancel={() => {
          setConfirmDeletingOfRamp(undefined);
        }}
        onAcceptButtonText={tr(`LocalityDetail.confirm`, `Confirm`)}
        onCancelButtonText={tr(`LocalityDetail.back`, `Back`)} />

      <AddEditLocationSidebar
        visible={visibleEditLocalityId}
        onHide={() => setVisibleEditLocalityId(undefined)}
        onCompleted={() => {
          setVisibleEditLocalityId(undefined);
          load();
        }}
        locationId={visibleEditLocalityId} />

      <AddEditRamp
        visible={visibleAddRamp}
        onHide={() => setVisibleAddRamp(false)}
        onCompleted={() => {
          load();
          setVisibleAddRamp(false);
        }}
        locationId={localityId} />

      <AddEditRamp
        visible={editRampId}
        onHide={() => setEditRampId(undefined)}
        onCompleted={() => {
          load();
          setEditRampId(undefined);
        }}
        locationId={localityId}
        editRampId={editRampId} />

      <ConfirmDialog
        visible={ramp2Delete}
        dialogTitle={tr('LocalityDetail.confirmDeleteOperation', 'Confirm delete operation')}
        dialogMessage={tr('LocalityDetail.doYouWantDeleteTheRamp', 'Do you want delete the ramp?')}
        onCancel={() => setRamp2Delete(undefined)}
        onConfirm={() => {
          deleteRamp(ramp2Delete);
          setRamp2Delete(undefined);
        }}
        onAcceptButtonText={tr(`LocalityDetail.confirm`, `Confirm`)}
        onCancelButtonText={tr(`LocalityDetail.back`, `Back`)} />

      <EditOpeningHours
        visible={visibleEditOpeningHours}
        onHide={() => setVisibleEditOpeningHours(false)}
        onCompleted={() => {
          load();
          setVisibleEditOpeningHours(false);
        }}
        locationId={localityId} />

    </>);

};

const RampList = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
`;

const TableHeading = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;
const TableWrapper = styled.div`
  display: flex;

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Adress = styled.div`
  display: flex;
`;
const Contacts = styled.div`
  display: flex;
`;
const AddressRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Gapped = styled.div`
  display: flex;
  gap: 1rem;
`;

const Link = styled.a`
  cursor: pointer;
`;

export default LocalityDetail;