import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputSwitch } from 'primereact/inputswitch';
import { SectionHeading } from '@components/styles.tsx';

interface ComponentProps {
  buttons?: any;
  label: string;
  showEnable?: boolean;
  enabled?: boolean;
  onVisibilityChanged?: (visible: boolean) => void;
}

const Section: React.FC<PropsWithChildren<ComponentProps>> = ({ label, buttons, children, showEnable = false, enabled = false, onVisibilityChanged = v => {} }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(enabled);
    onVisibilityChanged(enabled);
  }, [enabled]);

  const showChildren = showEnable == false || show;
  return (
    <SectionInner>
      <Grid>
        <SectionHeading>{label}</SectionHeading>
        {buttons && (
          <div>
            <Buttons>{buttons}</Buttons>
          </div>
        )}
        {showEnable && (
          <InputSwitch
            checked={show}
            onChange={v => {
              onVisibilityChanged(v.value);
              setShow(v.value);
            }}
          />
        )}
      </Grid>
      <div className={'flex flex-col gap-4'}>{showChildren && <>{children}</>}</div>
    </SectionInner>
  );
};

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GridContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
`;

const SectionInner = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  background-color: #fafafa;
  margin: 0.5rem 0;
  gap: 0.5rem;
`;

export default Section;
