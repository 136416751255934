import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';

import {
  CarrierQuoteStateEnum,
  CustomerQuoteStateEnum,
  DistributionTypeEnum,
  LocationTimeslotQuoteDemandOutDTO,
  MakeOfferInDTO,
  MakeOfferLocationSlotInDTO,
  MyQuoteInfoOutDTO,
  QuoteDetailOutDTO,
  TimeSlotQuoteDemandOutDTO,
} from '@api/logsteo-api.v2';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { DemandDetailView } from '../demand-detail/types';
import { dateFormatDateTime, dateFormatOnlyDateShort, mapFromAPIDateTime, mapFromAPIToTime, mapToAPIDateTime, setTime } from '@utils/date';
import { first, formatPrice, formatTons, formatWeight, isNullOrUndefined, translateQuantityCode, weightAndDimensions } from '@utils/utils';
import ChangeQuoteComponent from '../demand-detail/ChangeQuote/ChangeQuoteComponent';
import MyQuoteSummaryComponent from '../demand-detail/MyQuoteSummary/MyQuoteSummaryComponent';
import EditSlotsComponent, { LocationTimeSlots } from '../demand-detail/EditSlots/EditSlotsComponent';
import { mapToSlot } from '../demand-detail/RouteDescription/RouteDescriptionComponent';
import { useImmer } from 'use-immer';
import ReconfirmQuote from '../common/ReconfirmQuote';
import { ApiContext } from '@api/api';
import styled from 'styled-components';
import useBEValidation from '@hooks/useBEValidation/useBEValidation';
import { useTranslation } from 'react-i18next';
import CreateSpotComponent, { CreateSpotState } from '@app/pages/carrier/demand-detail/CreateSpot/CreateSpotComponent.tsx';
import DemandStateSpan from '@components/obsolete/StateSpan/DemandStateSpan.tsx';
import AlertInfo from '@components/obsolete/Alert/AlertInfo.tsx';
import NavTitle from '@components/obsolete/NavTitle/NavTitle.tsx';
import ListInfo from '@components/obsolete/ListInfo/ListInfo.tsx';
import ListInfoItem from '@components/obsolete/ListInfo/ListInfoItem.tsx';
import PanelWithIcon from '@components/obsolete/PanelWithIcon/PanelWithIcon.tsx';
import DisplayLocation from '@components/obsolete/DisplayLocation/DisplayLocation.tsx';
import TimeList from '@components/obsolete/TimeList/TimeList.tsx';
import TimeListItem from '@components/obsolete/TimeList/TimeListItem.tsx';
import Label from '@components/obsolete/Form/Label/Label.tsx';
import LoadInfoList from '@components/obsolete/LoadDetails/LoadInfoList/LoadInfoList.tsx';
import TimeslotsProgressTitle from '@components/obsolete/TimeslotsProgress/TimeslotsProgressTitle.tsx';
import useNavigation from '@hooks/useNavigation.tsx';

interface ComponentProps {
  action: string;
  applicationId: string;
  quoteDetail: QuoteDetailOutDTO;
  code: string;
  locationDisplayPattern: string;
}

export const QuickActionSpotDetail: React.FC<ComponentProps> = ({ quoteDetail, action, applicationId, code, locationDisplayPattern }) => {
  const { t } = useTranslation('common');
  const { tr } = useTranslationLgs();
  const { caCancelDemand, caMakeQuote, caMakeAnotherNewQuote, caReconfirmedExpedition } = useContext(ApiContext);
  const { BackendValidationComponent, setBeValidationErrors } = useBEValidation();
  const nav = useNavigation();

  const [visibleSlotEditDialog, setVisibleSlotEditDialog] = useState(false);

  const [state, setState] = useImmer<QuoteDetailOutDTO>(quoteDetail);

  const disabled = state.isExpeditionCanceled || state.isManualExpedition;
  const editSlotsFirstTime = state.distributionType === DistributionTypeEnum.SPOT && state.carrierQuoteState === CarrierQuoteStateEnum.NEW;

  const quoteState = state.distributionType === DistributionTypeEnum.SPOT ? state.carrierQuoteState : state.customerQuoteState;
  const createSpot =
    state.distributionType === DistributionTypeEnum.SPOT &&
    (quoteState === CarrierQuoteStateEnum.NEW || (quoteState === CarrierQuoteStateEnum.REJECTED_BY_CARRIER && !state.isAssignedToAnotherCarrier));

  const rejectSpot = (customerId: string, applicationId: string, quoteId: string) => {
    caCancelDemand(
      customerId,
      applicationId,
      quoteId,
      () => {
        window.location.reload();
      },
      code,
      { onValidationFailed: e => setBeValidationErrors(e) },
    );
  };

  const createQuote = (customerId: string, applicationId: string, quote: MyQuoteInfoOutDTO, timeslots: LocationTimeslotQuoteDemandOutDTO[]) => {
    const body: MakeOfferInDTO = {
      price: quote.myQuotePrice,
      currency: quote.myQuoteCurrency,
      validTill: quote.myQuoteValidTill,
      timeslots: timeslots.map(locationQueueSlot => {
        return {
          locationId: locationQueueSlot.locationId,
          useCustomerSlot: locationQueueSlot.timeslots.findIndex(a => a.isCarrier) < 0,
          differentTimeslots: locationQueueSlot.timeslots
            .filter(a => a.isCarrier)
            .map(s => {
              return {
                timeslotSince: s.sinceInUTC,
                timeslotTill: s.tillInUTC,
              };
            }),
        } as MakeOfferLocationSlotInDTO;
      }),
    };

    caMakeAnotherNewQuote(
      customerId,
      applicationId,
      body,
      () => {
        window.location.reload();
      },
      code,
      { onValidationFailed: e => setBeValidationErrors(e) },
    );
  };

  const changeTimeSlots = (data: LocationTimeSlots[]) => {
    setState(draft => {
      draft.timeslots.forEach(location => {
        const newLocation = data.find(l => l.id === location.locationId);
        const carrierSlots = newLocation.useCustomSlots ? [newLocation.carrierSlot] || [] : [];
        const customerSlots = newLocation.customerSlots;

        location.timeslots = [
          customerSlots.map(slot => {
            return {
              id: '',
              isCarrier: false,
              isSelected: carrierSlots.length == 0,
              isNotSpecifiedTime: slot.isWholeDay,
              sinceInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.sinceTime)),
              tillInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.tillTime)),
            } as TimeSlotQuoteDemandOutDTO;
          }),
          carrierSlots.map(slot => {
            return {
              id: '',
              isCarrier: true,
              isSelected: true,
              sinceInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.sinceTime)),
              tillInUTC: mapToAPIDateTime(setTime(slot.selectedDate, slot.tillTime)),
            } as TimeSlotQuoteDemandOutDTO;
          }),
        ].flat(1);
      });
    });
  };

  const makeQuote = (customerId: string, applicationId: string, quoteId: string, data: CreateSpotState, locationTimeSlots: LocationTimeslotQuoteDemandOutDTO[]) => {
    const body: MakeOfferInDTO = {
      price: data.price,
      currency: data.currency,
      validTill: mapToAPIDateTime(data.selectedData),
      timeslots: locationTimeSlots.map(locationQueueSlot => {
        return {
          locationId: locationQueueSlot.locationId,
          useCustomerSlot: locationQueueSlot.timeslots.findIndex(a => a.isCarrier) < 0,
          differentTimeslots: locationQueueSlot.timeslots
            .filter(a => a.isCarrier)
            .map(s => {
              return {
                timeslotSince: s.sinceInUTC,
                timeslotTill: s.tillInUTC,
              };
            }),
        } as MakeOfferLocationSlotInDTO;
      }),
    };

    caMakeQuote(
      customerId,
      applicationId,
      quoteId,
      body,
      () => {
        window.location.reload();
      },
      code,
      { onValidationFailed: e => setBeValidationErrors(e) },
    );
  };

  const reconfirm = (companyId: string, applicationId: string) => {
    caReconfirmedExpedition(
      companyId,
      applicationId,
      () => {
        window.location.reload();
      },
      code,
      { onValidationFailed: e => setBeValidationErrors(e) },
    );
  };

  const resolveExpeditionStatus = (state: DemandDetailView) => {
    return (
      <>
        <DemandStateSpan demandState={state.customerQuoteState} isManual={state.isManualExpedition} isCanceled={quoteDetail.isExpeditionCanceled} />

        {!disabled && (
          <>
            {(state.carrierQuoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM || !isNullOrUndefined(state.reconfirmedAt)) &&
              !state.isAssignedToAnotherCarrier && (
                <div className="p-mb-3">
                  <ReconfirmQuote
                    onReconfirm={() => reconfirm(state.companyId, state.applicationId)}
                    reconfirmedAt={state.reconfirmedAt}
                    isReconfirmedByCarrier={state.isReconfirmedByCarrier}
                    disabled={state.isExpeditionCanceled || state.isManualExpedition}
                  />
                </div>
              )}
            <div>
              {t('DemandDetailComponent.validTill', 'Platná do ')}
              <strong>{dateFormatDateTime(mapFromAPIDateTime(state.distributionValidTill))}</strong>
            </div>
          </>
        )}
      </>
    );
  };

  const mapToTimeLocationTimeSlots = (location: LocationTimeslotQuoteDemandOutDTO[]): LocationTimeSlots[] => {
    return location.map(loc => {
      return {
        name: loc.locationName,
        city: loc.city,
        country: loc.country,
        zipCode: loc.zipCode,
        id: loc.locationId,
        useCustomSlots: loc.timeslots.some(ts => ts.isCarrier),
        customerSlots: loc.timeslots.filter(ts => ts.isCarrier === false).map(item => mapToSlot(item)),
        carrierSlot: first(loc.timeslots.filter(ts => ts.isCarrier === true).map(item => mapToSlot(item))),
      };
    });
  };

  return (
    <>
      <BackendValidationComponent />
      {(quoteState == CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER ||
        quoteState == CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER ||
        quoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM) &&
        !disabled && (
          <>
            <AlertInfo iconName="check" backgroundWrapper="cyan">
              <p>{t('DemandDetailComponent.youWon', 'Customer assigned you as a carrier to execute this shipment.')}</p>
            </AlertInfo>
            <AlertInfo backgroundWrapper="transparent">
              <div className="p-mr-3">
                <p>{t('DemandDetailComponent.findDetailInShipments', 'You can find detail in all shipments list or here')}</p>
              </div>
              <Button
                label={t('DemandDetailComponent.goToShipmentDetail', 'Go to shipment details')}
                className="p-mt-1 p-mb-1"
                onClick={e => {
                  nav.navigate(nav.urlFunctions.createCarrierShipmentDetail(quoteDetail.companyId, quoteDetail.applicationId));
                }}
              />
            </AlertInfo>
          </>
        )}
      {quoteState === CarrierQuoteStateEnum.OFFERED_PRICE && !state.myQuoteInfo.myQuoteIsExpired && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>
            {t(
              `DemandDetailComponent.yourPriceQuoteHasBeenSentCustomerWillNotEitherAcceptOrRejectIt`,
              `Your price quote has been sent. Customer will not either accept or reject it.`,
            )}
          </p>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.OFFERED_PRICE && state.myQuoteInfo.myQuoteIsExpired && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>{t(`DemandDetailComponent.youOfferIsNotValid`, `Vaše nabídka vypršela. Můžete ji prodloužit, pokud je poptávka stále platná.`)}</p>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER && !state.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="red" iconName="access_time">
          <>
            <p>{t(`DemandDetailComponent.customerRejectedYourPrice`, `Customer rejected your price.`)}</p>
            <br />
            <p>{state.myQuoteInfo?.rejectedMessageByCustomer}</p>
          </>
        </AlertInfo>
      )}
      {quoteState == CarrierQuoteStateEnum.REJECTED_BY_CARRIER && !state.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="red" iconName="access_time">
          <>
            <p>{tr(`DemandDetailComponent.demandWasRejectedByYourCompany`, `Demand was rejected by your company`)}</p>
          </>
        </AlertInfo>
      )}
      {state.isAssignedToAnotherCarrier && !disabled && (
        <AlertInfo backgroundWrapper="orange2" iconName="access_time">
          <p>{t(`DemandDetailComponent.assignedToAnotherCarrier`, `Nabídka byla přiřazena jinému dopravci`)}</p>
        </AlertInfo>
      )}
      <div className="container-wrapper-lg">
        <div className="p-grid">
          <div className="p-col-12 p-lg-6">
            <NavTitle wrapper="WITHOUT-COL_PADDING-BOOTOM-4">
              <h2 className="h2-title p-m-0">
                {t('demandDetail.pageTitle', {
                  applicationId: state.applicationId,
                })}
                <span></span>
              </h2>
              <p>{resolveExpeditionStatus(state)}</p>
            </NavTitle>

            <ListInfo>
              <ListInfoItem title={t(`DemandDetailComponent.distance`, `Distance`)}>
                <p>
                  <small>{state.distance ? state.distance : '-'} km</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title={t(`DemandDetailComponent.expeditionType`, `Expedition type`)}>
                <p>
                  <small>{t(`global.expeditionType${state.expeditionType}`)}</small>
                </p>
              </ListInfoItem>
              {state.truckType && (
                <ListInfoItem title={t(`DemandDetailComponent.truckType`, `Truck type`)}>
                  <p>
                    <small>{state.truckType}</small>
                  </p>
                </ListInfoItem>
              )}
              <ListInfoItem title={t(`DemandDetailComponent.maximumWeight`, `Maximum weight`)}>
                <p>
                  <small>{state.maxWeight.toLocaleString()} kg</small>
                </p>
              </ListInfoItem>
              <ListInfoItem title={t(`DemandDetailComponent.cargoType`, `Cargo type`)}>
                <p>
                  <small>{state.cargoType}</small>
                </p>
              </ListInfoItem>
              {!isNullOrUndefined(state.carrierNote) && (
                <ListInfoItem title={t(`DemandDetailComponent.noteToCarriers`, `Note to carriers`)}>
                  <p>
                    <small>“{state.carrierNote}“</small>
                  </p>
                </ListInfoItem>
              )}
              {state.distributionType === DistributionTypeEnum.SPOT && (
                <ListInfoItem title={t(`DemandDetailComponent.expectedPrice`, `Expected price`)}>
                  <p>
                    <small>{formatPrice(state.price, state.currency)}</small>
                  </p>
                </ListInfoItem>
              )}
            </ListInfo>
          </div>
          {!state.isDistributionExpired &&
            state.distributionType === DistributionTypeEnum.SPOT &&
            (quoteState === CarrierQuoteStateEnum.OFFERED_PRICE ||
              quoteState === CarrierQuoteStateEnum.REJECTED_BY_CUSTOMER ||
              quoteState === CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER ||
              quoteState === CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM) &&
            !isNullOrUndefined(state.myQuoteInfo.myQuotePrice) && (
              <ChangeQuoteComponent
                onReject={() => rejectSpot(state.companyId, state.applicationId, state.quoteId)}
                enableActions={
                  !state.isManualExpedition &&
                  !state.isExpeditionCanceled &&
                  !state.isDistributionExpired &&
                  !state.isAssignedToAnotherCarrier &&
                  quoteState !== CarrierQuoteStateEnum.ACCEPTED_BY_CUSTOMER &&
                  quoteState !== CarrierQuoteStateEnum.ACCEPTED_NEED_RECONFIRM
                }
                state={state}
                code={code}
                createQuote={createQuote}
              />
            )}
          {state.distributionType === DistributionTypeEnum.FIXED_PRICE && state.customerQuoteState !== CustomerQuoteStateEnum.NEW && (
            <MyQuoteSummaryComponent
              distributionType={DistributionTypeEnum[state.distributionType]}
              enableReject={false}
              onReject={() => {}}
              enableChangePrice={false}
              enableChangeTimeslots={false}
              price={state.price}
              currency={state.currency}
              quoteValidTill={mapFromAPIDateTime(state.distributionValidTill)}
              timeslots={state.timeslots}
              code={code}
            />
          )}
          {createSpot && !state.isDistributionExpired && (
            <CreateSpotComponent
              demandValidTill={mapFromAPIDateTime(state.distributionValidTill)}
              onReject={() => rejectSpot(state.companyId, state.applicationId, state.quoteId)}
              onAccept={data => makeQuote(state.companyId, state.applicationId, state.quoteId, data, state.timeslots)}
              enableAction={!disabled}
              code={code}
            />
          )}
        </div>
        <div className="p-grid p-mt-4">
          <div className="p-col-12 p-lg-5">
            {state.timeslots.map((location, locationIndex) => {
              return (
                <div className="timeslots-wrapper" key={locationIndex}>
                  <PanelWithIcon numberIcon={`${locationIndex + 1}`} wrapper="SM">
                    <p>
                      <DisplayLocation location={location} locationDisplayPattern={locationDisplayPattern} />
                    </p>
                  </PanelWithIcon>
                  <div className="time-shell">
                    <TimeList>
                      {location.timeslots.map((timeslot, timeslotIndex) => {
                        return (
                          <TimeListItem
                            key={timeslotIndex}
                            date={dateFormatOnlyDateShort(mapFromAPIDateTime(timeslot.sinceInUTC))}
                            isWholeDay={timeslot.isNotSpecifiedTime}
                            time={`${mapFromAPIToTime(timeslot.sinceInUTC)} - ${mapFromAPIToTime(timeslot.tillInUTC)}`}
                            selected={timeslot.isSelected}
                          />
                        );
                      })}
                      {editSlotsFirstTime && !state.isDistributionExpired && !state.isAssignedToAnotherCarrier && (
                        <Button
                          className="p-button-outlined"
                          label={t(`RouteDescriptionComponent.iNeedADifferentTimeslot`, `Chci jiné čas. okno`)}
                          onClick={() => setVisibleSlotEditDialog(true)}
                          disabled={disabled}
                        />
                      )}
                    </TimeList>
                  </div>
                </div>
              );
            })}
            <div className="shipment-requirements-wrapper">
              <Label title={t('RouteDescriptionComponent.manipulationType', 'Manipulace')} />
              <p>
                <i className="p-mr-2 pi pi-check"></i>
                <span>{state.loadingTypes && state.loadingTypes.map(type => t(`RouteDescriptionComponent.manipulatioType.${type}`)).join(', ')}</span>
              </p>
              <p>
                {state.requirements && (
                  <>
                    <strong>{t('RouteDescriptionComponent.requirements', 'Požadavky')}:</strong>
                    {state.requirements.join(', ')}
                  </>
                )}
              </p>
            </div>
            {state.items && state.items.length > 0 && (
              <div className="load-info-wrapper load-info-wrapper--sm  p-mt-4 p-mb-4 p-d-flex p-flex-wrap">
                {state.items.map((cargo, cargoIndex) => {
                  return (
                    <LoadInfoList
                      key={cargoIndex}
                      title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                      description={formatTons(cargo.weight)}
                      stackable={cargo.stackable}
                      itemNote={cargo.itemNote}
                    />
                  );
                })}
              </div>
            )}
            <div className="p-mt-5 list-routes">
              {state.locations.map((location, locationIndex) => {
                return (
                  <React.Fragment key={locationIndex}>
                    <TimeslotsProgressTitle
                      number={`${locationIndex + 1}`}
                      address={<DisplayLocation location={location} locationDisplayPattern={locationDisplayPattern} />}
                    />

                    {location.loadedOrders && location.loadedOrders.length > 0 && (
                      <div className="p-d-flex">
                        <PanelWithIcon srcIcon="/images/icon_pickup.png">
                          <Label title={t('RouteDescriptionComponent.pickUp', 'Nakládka')} />

                          {location.loadedOrders && location.loadedOrders.length > 0 && (
                            <>
                              {location.loadedOrders.map((loadedOrder, loadedOrderKey) => {
                                return (
                                  <Col>
                                    {loadedOrder.orderName && (
                                      <Row>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.order`, `Objednavka`)}>
                                          <p>
                                            <small>{loadedOrder.orderName}</small>
                                          </p>
                                        </ListInfoItem>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.weight`, `Hmotnost`)}>
                                          <p>
                                            <small>{formatWeight(loadedOrder.totalWeight)}</small>
                                          </p>
                                        </ListInfoItem>
                                      </Row>
                                    )}
                                    {loadedOrder.items.map((cargo, cargoIndex) => {
                                      return (
                                        <LoadInfoList
                                          key={cargoIndex}
                                          title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                                          description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                                          stackable={cargo.stackable}
                                          itemNote={cargo.itemNote}
                                        />
                                      );
                                    })}
                                  </Col>
                                );
                              })}
                            </>
                          )}
                        </PanelWithIcon>
                      </div>
                    )}
                    {location.unloadedOrders && location.unloadedOrders.length > 0 && (
                      <div className="p-d-flex">
                        <PanelWithIcon srcIcon="/images/icon_dropoff.png">
                          <Label title={t('RouteDescriptionComponent.dropoff', 'Vykládka')} />

                          {location.unloadedOrders && location.unloadedOrders.length > 0 && (
                            <>
                              {location.unloadedOrders.map((loadedOrder, loadedOrderKey) => {
                                return (
                                  <Col>
                                    {loadedOrder.orderName && (
                                      <Row>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.order`, `Objednavka`)}>
                                          <p>
                                            <small>{loadedOrder.orderName}</small>
                                          </p>
                                        </ListInfoItem>
                                        <ListInfoItem title={t(`RouteDescriptionComponent.weight`, `Hmotnost`)}>
                                          <p>
                                            <small>{formatWeight(loadedOrder.totalWeight)}</small>
                                          </p>
                                        </ListInfoItem>
                                      </Row>
                                    )}
                                    {loadedOrder.items.map((cargo, cargoIndex) => {
                                      return (
                                        <LoadInfoList
                                          key={cargoIndex}
                                          title={`${cargo.name} (${cargo.amount} ${translateQuantityCode(cargo.quantityUnit, tr)})`}
                                          description={weightAndDimensions(cargo.height, cargo.length, cargo.width, cargo.weight, t)}
                                          stackable={cargo.stackable}
                                          itemNote={cargo.itemNote}
                                        />
                                      );
                                    })}
                                  </Col>
                                );
                              })}
                            </>
                          )}
                        </PanelWithIcon>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="p-mt-5">
              <h3 className="p-mt-0" style={{ fontWeight: 'normal' }}>
                {t('RouteDescriptionComponent.customer', 'Poptávající')}
              </h3>
              <PanelWithIcon srcIcon="/images/contacts.svg">
                <Label title={state.customer.name} />
                <p>
                  {state.customer.streetNr}, {state.customer.city}, {state.customer.country}
                </p>
                <p>
                  {t('RouteDescriptionComponent.CrnAndTin', 'IČ: 28441345 | DIČ: CZ28441345', {
                    crn: state.customer.crn || '-',
                    tin: state.customer.tin || '-',
                  })}
                </p>
              </PanelWithIcon>
              <PanelWithIcon srcIcon="/images/user.svg">
                <p>{state.responsiblePerson.fullName}</p>
                <p className="text-gray5">
                  <small>{t('RouteDescriptionComponent.responsiblePerson', 'odpovědná osoba')}</small>
                </p>
              </PanelWithIcon>
            </div>
          </div>
        </div>
      </div>
      <EditSlotsComponent
        visible={visibleSlotEditDialog}
        onHide={() => {
          setVisibleSlotEditDialog(false);
        }}
        locationsWithSlots={mapToTimeLocationTimeSlots(state.timeslots)}
        onConfirm={(data: LocationTimeSlots[]) => {
          changeTimeSlots(data);
          setVisibleSlotEditDialog(false);
        }}
      />
    </>
  );
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
